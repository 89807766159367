import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact'
import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { AuthContext } from '../../AuthContext/AuthContext'
import { GA_ID, IS_LOADING, LOADED, openHomeDateConverter, openHomeDateConverterMinimal, NZ } from '../../Constants'
import { cRequest } from '../../Data/CustomRequest'
import ListingRequest, {
  CURRENT_LISTING_REQUEST,
  FIND_LISTING_REQUEST,
  LATEST_LISTING_REQUEST,
  OTHER_HOMES_LISTING_REQUEST,
  SOLD_LISTING_REQUEST,
  OTHER_HOMES_LISTING_REQUEST_AUSTRALIA
} from '../../Data/ListingRequest'
import ContactUsModal from '../ContactComponents/ContactUsModal'
import OtherHomes from '../OtherHomesComponents/OtherHomes'
import CardCarousel, { CARD_STYLE_VERTICAL } from '../CarouselComponents/CardCarouselComponent/CardCarousel'
import WatchlistButton from '../WatchlistComponent/WatchlistButton'
import './style.css'

class SelectedListingPage extends Component {
  authCtx = null

  constructor(props) {
    super(props)
    this.state = {
      id: props.id,
      data: null,
      consultants: [],
      openHomes: [],
      loading: IS_LOADING,
      error: false,
      lRequestor: new ListingRequest(FIND_LISTING_REQUEST),
      oRequestor: new ListingRequest(OTHER_HOMES_LISTING_REQUEST),
      top: 0,
      height: window.innerHeight,
      images: [],
      hasVideo: false,
      slideIndex: 0,
      showEnquire: false,
      enquireSubject: '',
      processedData: {}
    }
    this.applyScrollLock(true)
  }

  applyScrollLock = (locked = false) => {
    if (locked) {
      document.body.className = 'no-scroll'
      if (window.iNoBounce) {
        window.iNoBounce.enable()
      }
    } else {
      document.body.className = ''
      if (window.iNoBounce) {
        window.iNoBounce.disable()
      }
    }
  }

  componentDidMount() {
    const ReactPixel = require('react-facebook-pixel')
    const options = { autoConfig: true, debug: false }
    ReactPixel.default.init('379566864000726', undefined, options)
    ReactPixel.default.fbq('track', 'PageView')
    ReactGA.initialize(GA_ID)
    this.checkPathParam()

    this.setTop(0)
    this.listenToOrientation(true)

    this.authCtx.addSubscriber(this)
    this.state.lRequestor.setAuth(this.authCtx)
    this.state.lRequestor.fetchOne(this.state.id, this)

    this.state.oRequestor.setAuth(this.authCtx)
    window.addEventListener('popstate', this.navigatedBack)
  }

  componentWillUnmount() {
    this.applyScrollLock()
    this.authCtx.removeSubscriber(this)
    window.removeEventListener('popstate', this.navigatedBack)
  }

  navigatedBack = () => {
    this.close()
  }

  checkPathParam = () => {
    let params = window.location.search

    if (params && params.includes('listing')) {
      params = params.replace('?', '')
      let paramArr = params.split('&')
      let listingParam = paramArr.find((p) => p.includes('listing'))
      paramArr = listingParam.split('=')
      if (paramArr && paramArr.length === 2) {
        this.setState({
          id: paramArr[1]
        })
      }
    }
  }

  reload = () => {
    this.state.lRequestor.fetchOne(this.state.id, this)
    this.state.oRequestor.fetch(this.state.id, this)
  }

  listenToOrientation(listen = false) {
    const setTop = this.setTop
    if (listen) {
      window.addEventListener('orientationchange', () => {
        setTop()
      })
    } else {
      window.removeEventListener('orientationchange', () => {
        setTop()
      })
    }
  }

  updateLoading = (loadState) => {
    setTimeout(() => {
      this.setState({
        loading: loadState
      })
    }, 800)
  }

  fetchConsultants = async (data) => {
    let consultants = data.consultants
    if (!data.consultants || data.consultants.length === 0) {
      let response = await cRequest('/consultants')
      if (response && response.status === 200) {
        consultants = await response.json()
      }
    }
    let sortedConsultants = consultants.sort((a, b) => (a.pageOrder > b.pageOrder ? 1 : -1))
    this.setState({
      consultants: sortedConsultants
    })
  }

  checkOpenHomes = (data) => {
    if (data && data.OpenHome) {
      let ohTimes = openHomeDateConverter(data.OpenHome)
      this.setState({
        openHomes: ohTimes
      })
    }
  }

  fetchSuccess = (data) => {
    if (!data) {
      this.setState({
        error: true
      })
    } else {
      const ReactPixel = require('react-facebook-pixel')
      const options = { autoConfig: true, debug: false }
      ReactPixel.default.init('379566864000726', undefined, options)
      ReactPixel.default.fbq('track', 'PageView')
      ReactGA.initialize(GA_ID)
      ReactGA.pageview(`/listings?status=current&listing=${data.id}`)
      this.setState({
        data: data
      })
      this.setImageArray()
      this.fetchConsultants(data)
      this.checkOpenHomes(data)
    }
    this.updateLoading(LOADED)
  }

  fetchFailed = (error) => {
    this.setState({
      error: true
    })
    this.updateLoading(LOADED)
  }

  setImageArray = () => {
    let imageArr = []
    if (this.state.data) {
      let url = this.state.data.MainImage.url
      let thumbUrl = this.getImageThumb(this.state.data.MainImage)
      imageArr.push({ original: url, thumbnail: thumbUrl })
      for (let i = 0; i < this.state.data.Images.length; i++) {
        url = this.state.data.Images[i].url
        thumbUrl = this.getImageThumb(this.state.data.Images[i])
        imageArr.push({ original: url, thumbnail: thumbUrl })
      }
    }

    let hasVideo = false
    if (this.state.data && this.state.data.video && this.state.data.video.url) {
      hasVideo = true
      let videoSlide = {
        thumbnail: require('../../assets/img/virtual-tour.jpeg'),
        embedUrl: this.state.data.video.url,
        description: 'Virtual Tour Video',
        renderItem: this.renderVideo.bind(this)
      }
      imageArr.splice(1, 0, videoSlide)
    }

    this.setState({
      hasVideo: hasVideo,
      images: imageArr
    })
  }

  toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url])
    this.setState({
      showVideo: this.state.showVideo
    })

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false })
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false })
      }
    }
  }

  renderVideo(item) {
    return (
      <div>
        <div className='video-wrapper'>
          <a className='close-video' onClick={this.toggleShowVideo.bind(this, item.embedUrl)}></a>
          <iframe className='video-iframe' src={item.embedUrl} frameBorder='0' allowFullScreen></iframe>
        </div>
      </div>
    )
  }

  showFullscreen = () => {
    if (this.state.hasVideo) {
      return this.state.slideIndex !== 1
    } else {
      return true
    }
  }

  onSlide = (index) => {
    this.setState({
      slideIndex: index
    })
  }

  getImageThumb = (image) => {
    if (image.formats && image.formats.small && image.formats.small.url) {
      return image.formats.small.url
    } else {
      return image.url
    }
  }

  close = () => {
    if (this.loading) return
    this.applyScrollLock()
    this.props.hideListing(null, this.state.id)
  }

  setTop = (timeout = 250) => {
    setTimeout(() => {
      const top = window.scrollY
      const height = window.innerHeight
      if (top !== this.state.top) {
        this.setState({
          top: top,
          height: height
        })
      }
    }, timeout)
  }

  renderLeftNav = (onClick, disabled) => {
    return (
      <div className='image-gallery-custom-left-nav' disabled={disabled} onClick={onClick}>
        <MDBIcon icon='chevron-left' className='image-gallery-custom-left-nav-icon' />
      </div>
    )
  }

  renderRightNav = (onClick, disabled) => {
    return (
      <div className='image-gallery-custom-right-nav' disabled={disabled} onClick={onClick}>
        <MDBIcon icon='chevron-right' className='image-gallery-custom-right-nav-icon' />
      </div>
    )
  }

  renderPlayPauseButton = (onClick, isPlaying) => {
    return (
      <div
        // type='button'
        className={'image-gallery-custom-play-button'}
        onClick={onClick}
      >
        {isPlaying ? (
          <MDBIcon icon='stop' className='image-gallery-custom-play-icon' />
        ) : (
          <MDBIcon icon='play' className='image-gallery-custom-play-icon' />
        )}
      </div>
    )
  }

  renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <div
        // type='button'
        className={'image-gallery-custom-fullscreen-button'}
        onClick={onClick}
      >
        {isFullscreen ? (
          <MDBIcon icon='compress' className='image-gallery-custom-fullscreen-icon' />
        ) : (
          <MDBIcon icon='expand' className='image-gallery-custom-fullscreen-icon' />
        )}
      </div>
    )
  }

  renderItem = (data) => {
    return <div className='image-gallery-custom-render-item' style={{ backgroundImage: `url(${data.original})` }}></div>
  }

  toggleShowEnquire = (show = false, subject = '') => {
    this.setState({
      showEnquire: show,
      enquireSubject: subject
    })
  }

  updateListingData = (watchlistItem, watchListed) => {
    if (watchlistItem && watchlistItem.listing) {
      let listing = this.state.data
      listing.watchListed = !listing.watchListed
      this.setState({
        data: listing
      })
      this.authCtx.updateSubscribers(this)
    }
  }

  getAskingPrice = () => {
    if (
      !this.state.data ||
      !this.state.data.askingPrice ||
      this.state.data.askingPrice === 0 ||
      this.state.data.askingPrice.startsWith('0')
    )
      return null

    if (this.state.data.saleType) {
      switch (this.state.data.saleType.PriceTypeCode) {
        case 'asking_price':
        case 'enquiries_over':
        case 'expressions_of_interest_over':
        case 'negotiable_from':
        case 'offers_over':
        case 'fixed_price':
        case 'buyer_budget_from':
        case 'buyer_budget_over':
        case 'buyer_interest_from':
        case 'buyer_enquiry_over':
          break
        default:
          return null
      }
    }

    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(
      this.state.data.askingPrice
    )
  }

  getMetaDescription = () => {
    var plainString = this.state.data.Blurb.replace(/(<[^>]+>)/g, '')
    var stringDescription = plainString.replace('&nbsp;', ' ')
    var metaDescription = stringDescription.substring(0, 160)
    return metaDescription
  }

  toggleViewListing = (e, id, status, show = false) => {
    if (e !== null) e.preventDefault()

    this.setState({
      loading: IS_LOADING,
      selectedListingId: id,
      showSelectedListing: show
    })

    if (show) {
      this.props.history.push(`?status=${status}&listing=${id}`)
      this.state.lRequestor.fetchOne(id, this)
    } else {
      this.props.history.push('')
    }
  }

  render() {
    this.authCtx = this.context.auth
    this.setTop()
    const latestRequest = new ListingRequest(LATEST_LISTING_REQUEST)
    latestRequest.setAuth(this.authCtx)
    const currentRequest = new ListingRequest(CURRENT_LISTING_REQUEST)
    currentRequest.setAuth(this.authCtx)
    const soldRequest = new ListingRequest(SOLD_LISTING_REQUEST)
    soldRequest.setAuth(this.authCtx)

    const otherRequest = new ListingRequest(this.props.country === NZ ? OTHER_HOMES_LISTING_REQUEST : OTHER_HOMES_LISTING_REQUEST_AUSTRALIA)
    otherRequest.setAuth(this.authCtx)
    const developerId = this.state.data && this.state.data.developer ? this.state.data.developer.id : null
    if (developerId) {
      let params = []
      params.push(['developer_id=', `${developerId}`])
      otherRequest.buildParams(params)
    }

    let email = ''
    if (this.authCtx) {
      const user = this.authCtx.getUser()
      if (user) {
        email = user.email
      }
    }
    const consultants = this.state.consultants
      ? this.state.consultants.map((c, i) => {
          let image = c.Photo && c.Photo.length > 0 ? c.Photo[0].url : ''

          return (
            <MDBCol md='4' key={i} className='mx-auto text-center'>
              <img src={image} className='rounded text-center mx-auto size20' alt={`${c.firstname} ${c.lastname}`} />
              <p className='text-gold mt-4 text-uppercase h5-responsive'>
                {c.firstname} {c.lastname}
              </p>
              <p>
                <i className='fa fa-envelope mr-3 white-text' />{' '}
                <a
                  className='white-text text-uppercase'
                  style={{ letterSpacing: '2px' }}
                  href={
                    `mailto:${c.email}?subject=Listing Enquiry for ` +
                    this.state.data.Address +
                    '&body=Hi, I am interested in ' +
                    this.state.data.Address +
                    '.'
                  }
                >
                  {c.email}
                </a>
              </p>
              <p>
                <i className='fa fa-phone mr-3 white-text' />{' '}
                <a className='white-text' href={`tel:${c.mobilephone}`} style={{ letterSpacing: '2px' }}>
                  {c.mobilephone}
                </a>
              </p>
              <p></p>
            </MDBCol>
          )
        })
      : null

    const metaHelmet = this.state.data ? (
      <Helmet>
        {/*Primary meta tags */}
        <title>
          {'For Sale ' +
            this.state.data.Address +
            ', ' +
            this.state.data.suburb.Name +
            ', ' +
            this.state.data.city.Name +
            ' | ' +
            this.state.data.Title}{' '}
        </title>
        <meta name='description' content={this.getMetaDescription()} />
        <meta name='author' content='Executive Real Estate | Luxury Property for Sale' />
        <link rel='canonical' href={window.location.href} />

        {/*Open Graph meta tags */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content={window.location.href} />
        <meta
          property='og:title'
          content={
            'For Sale ' +
            this.state.data.Address +
            ', ' +
            this.state.data.suburb.Name +
            ', ' +
            this.state.data.city.Name +
            ' | ' +
            this.state.data.Title
          }
        />
        <meta property='og:description' content={this.getMetaDescription()} data-react-helmet='true' />
        <meta property='og:image' content={this.state.data.MainImage.url} />

        {/*Twitter meta tags */}
        <meta property='twitter:card' content='summary_large_image'></meta>
        <meta property='twitter:url' content={window.location.href}></meta>
        <meta
          property='twitter:title'
          content={
            'For Sale ' +
            this.state.data.Address +
            ', ' +
            this.state.data.suburb.Name +
            ', ' +
            this.state.data.city.Name +
            ' | ' +
            this.state.data.Title
          }
        />
        <meta property='twitter:description' content={this.getMetaDescription()} />
        <meta property='twitter:image' content={this.state.data.MainImage.url} />
      </Helmet>
    ) : null

    const schools =
      this.state.data && this.state.data.schools.length > 0 ? (
        <MDBCol md='6'>
          <div className='text-gold text-uppercase text-left mt-4 mb-4 font-weight-md' style={{ fontSize: '13px' }}>
            Zoned for schools
          </div>
          {this.state.data.schools.map((s, i) => (
            <MDBCol key={i} className='pl-lg-0'>
              <p className='white-text h6-responsive text-left' style={{ fontSize: '13px' }}>
                {s.Name}
              </p>
            </MDBCol>
          ))}
        </MDBCol>
      ) : null

    const openHomes =
      this.state.openHomes && this.state.openHomes.length > 0 ? (
        <>
          <div className='text-gold text-uppercase open-home-banner text-left mt-4 mb-4 font-weight-md' style={{ fontSize: '14px' }}>
            <MDBIcon icon='home' className='text-gold mr-3 ml-2' />
            Open Homes
          </div>
          {this.state.openHomes.map((oh, i) => (
            <p key={i} className='white-text h6-responsive text-left' style={{ fontSize: '14px' }}>
              {oh}
            </p>
          ))}
          <div className='display-none-sm'>
            <hr className='accent-2 mb-4 d-inline-block mx-auto' style={{ width: '97%', height: '1px', backgroundColor: '#cda177' }} />
          </div>
        </>
      ) : null

    const completion =
      this.state.data && this.state.data.completionDate ? (
        <div className='text-gold text-uppercase mt-2 font-weight-md' style={{ fontSize: '14px' }}>
          Due for completion {this.state.data.completionDate}
        </div>
      ) : null

    const openhomes_next = this.state.data && this.state.data.OpenHome ? openHomeDateConverterMinimal(this.state.data.OpenHome) : null

    return (
      <>
        {metaHelmet}
        <div className='top-bar'></div>
        <ContactUsModal
          toEmail={this.state.consultants && this.state.consultants.length === 1 ? this.state.consultants[0].email : null}
          subject={this.state.enquireSubject}
          isOpen={this.state.showEnquire}
          toggleOpen={this.toggleShowEnquire}
        />
        <div className='selected-listing-container'>
          <div className='selected-listing-inner-container'>
            <div className='selected-listing-inner-content'>
              <MDBRow style={{ height: '10px' }}>
                <MDBCol>{this.state.loading ? <div className='bar2'></div> : null}</MDBCol>
              </MDBRow>

              {this.state.loading ? null : (
                <>
                  <div
                    className='text-right display-none-lg pointer mb-2'
                    style={{ letterSpacing: '1px', textTransform: 'uppercase', fontSize: '13px' }}
                    onClick={() => this.close()}
                  >
                    <MDBIcon icon='chevron-left' className='white-text text-left mr-1 mb-2' /> Back to listings
                  </div>
                  <MDBRow>
                    <MDBCol md='8'>
                      {this.state.data.Title ? (
                        <div className='h2-responsive text-uppercase text-left text-gold'>{this.state.data.Title}</div>
                      ) : null}
                      <div className='selected-listing-address'>
                        {this.state.data.Address + ', ' + this.state.data.suburb.Name + ', ' + this.state.data.city.Name}
                      </div>
                    </MDBCol>
                    <MDBCol md='4' className='text-lg-right text-center display-none-sm'>
                      <MDBBtn outline color='primary' onClick={() => this.close()}>
                        Back to listings
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: '20px' }}>
                    <MDBCol size='12'>
                      {openhomes_next && openhomes_next.length > 0 ? (
                        <div className='text-left open-home-img pl-lg-4 pl-3'>
                          <MDBIcon icon='home' className='white-text mr-lg-3 mr-2' /> Next open home {openhomes_next[0]}
                        </div>
                      ) : null}
                      <ImageGallery
                        items={this.state.images}
                        showIndex={true}
                        useBrowserFullscreen={false}
                        showPlayButton={this.showFullscreen()}
                        showFullscreenButton={this.showFullscreen()}
                        onBeforeSlide={this.onSlide}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className='pb-3'>
                    <MDBCol sm='12' md='5' lg='3'>
                      <div>
                        <div className='amenity-container-body mx-auto mt-lg-3'>
                          <div className='lc-ammenities-container'>
                            {openHomes}
                            <MDBCol md='12' className='mb-4 text-left'>
                              <MDBRow>
                                {this.state.data.Bedrooms > 0 ? (
                                  <div className='ammenity'>
                                    {this.state.data.Bedrooms}
                                    <MDBIcon className='ammenity-icon' icon='bed' />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.data.Bathrooms > 0 ? (
                                  <div className='ammenity'>
                                    {this.state.data.Bathrooms}
                                    <MDBIcon className='ammenity-icon' icon='bath' />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.data.Living > 0 ? (
                                  <div className='ammenity'>
                                    {this.state.data.Living}
                                    <MDBIcon className='ammenity-icon' icon='couch' />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.data.Kitchen > 0 ? (
                                  <div className='ammenity'>
                                    {this.state.data.Kitchen}
                                    <MDBIcon className='ammenity-icon' icon='utensils' />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.data.Office > 0 ? (
                                  <div className='ammenity'>
                                    {this.state.data.Office}
                                    <MDBIcon className='ammenity-icon' icon='desktop' />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.data.Garage > 0 ? (
                                  <div className='ammenity'>
                                    {this.state.data.Garage}
                                    <MDBIcon className='ammenity-icon' icon='car' />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </MDBRow>
                            </MDBCol>
                            {!this.getAskingPrice() ? (
                              <div className='mt-4 ml-0'>
                                {this.state.data.saleType ? (
                                  <div className='selected-listing-sub-title'>{this.state.data.saleType.Name}</div>
                                ) : null}
                              </div>
                            ) : (
                              <div className='mt-4 ml-0'>
                                <div className='selected-listing-sub-title'>
                                  {this.state.data.saleType.Name} {this.getAskingPrice()}
                                </div>
                              </div>
                            )}

                            <MDBCol md='12'>
                              {this.state.data.FloorSqm > 0 ? (
                                <h6 className='selected-listing-sub-title-sm mt-4 text-left'>
                                  Floor Area &nbsp; {this.state.data.FloorSqm}&#13217;
                                </h6>
                              ) : null}
                            </MDBCol>
                            <MDBCol md='12'>
                              {this.state.data.LandSqm > 0 ? (
                                <h6 className='selected-listing-sub-title-sm mt-4 text-left'>
                                  Land Area &nbsp; {this.state.data.LandSqm}&#13217;
                                </h6>
                              ) : null}
                            </MDBCol>
                            <div className='display-none-sm text-left mt-4'>
                              {this.state.data.developer !== null && this.state.data.developer.Logo !== null ? (
                                <img src={this.state.data.developer.Logo.url} alt='developer logo' className='dev-logo-listing text-left' />
                              ) : null}
                              {this.state.data.developer !== null ? (
                                <div className='selected-listing-dev mt-3 text-uppercase'>
                                  <span className='text-gold text-left'>
                                    Property developer
                                    <br />
                                  </span>
                                  {this.state.data.developer.Name}
                                </div>
                              ) : null}
                            </div>

                            <MDBRow>
                              <MDBCol sm='9' md='7' className='mt-1 mb-2 text-left'>
                                {this.state.data.Display_as_Sold && !this.state.data.watchListed ? null : (
                                  <WatchlistButton
                                    id={this.state.data.id}
                                    watchListed={this.state.data.watchListed}
                                    updateListing={this.updateListingData}
                                  />
                                )}

                                <button
                                  className={'lc-view-btn ml-lg-1 w-100'}
                                  onClick={() => this.toggleShowEnquire(true, 'Listing Enquiry for ' + this.state.data.Address)}
                                >
                                  <i className='fas fa-envelope mr-3'></i>
                                  <span>Enquire</span>
                                </button>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </div>
                      </div>
                    </MDBCol>

                    <MDBCol sm='12' md='7' lg='9'>
                      <MDBRow className='mt-2 pb-3'>
                        <MDBCol md='6'>
                          <div
                            className='text-gold h6-responsive text-uppercase text-left mt-2'
                            style={{ fontWeight: '400', letterSpacing: '0.5px' }}
                          >
                            About {this.state.data.Address}
                          </div>
                        </MDBCol>
                        <MDBCol md='6'>
                          <div className='text-lg-right text-left'>{completion}</div>
                        </MDBCol>
                        {/* <hr className="accent-2 mb-4 d-inline-block mx-auto" style={{ width: "97%", height: '1px', backgroundColor: '#cda177' }} /> */}
                        <hr
                          className='accent-2 mb-3 d-inline-block mx-auto'
                          style={{ width: '97%', height: '1px', backgroundColor: '#cda177' }}
                        />
                        <MDBCol md='12'>
                          <div className='selected-listing-blurb'>{ReactHtmlParser(this.state.data.Blurb)}</div>
                          {schools}
                          <div className='selected-listing-no mt-3 mb-3 text-left'>Listing: {this.state.data.ListingNo}</div>
                          {/* <hr className="grey accent-2 mb-4 d-inline-block mx-auto" style={{ width: "95%", height: '1px', opacity: '0.4' }} /> */}
                        </MDBCol>
                        <div className='display-none-lg text-center mb-4 mt-1 mx-auto'>
                          {this.state.data.developer !== null && this.state.data.developer.Logo !== null ? (
                            <img src={this.state.data.developer.Logo.url} alt='developer logo' className='dev-logo-listing text-center' />
                          ) : null}
                          {this.state.data.developer !== null ? (
                            <div className='selected-listing-dev mt-3 text-uppercase'>
                              <span className='text-gold'>
                                Property developer
                                <br />
                              </span>
                              {this.state.data.developer.Name}
                            </div>
                          ) : null}
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <div className='listing-border-bottom'>
                    <MDBContainer>
                      <div
                        className='h6-respsonive mt-lg-5 mt-4 text-center text-lg-left'
                        style={{ letterSpacing: '2px', fontSize: '13px' }}
                      >
                        <MDBRow>{consultants}</MDBRow>
                      </div>
                      <div className='text-lg-center text-left text-gold h4-responsive mt-4 mb-4'>
                        Interested in this property? Get in touch with us today.
                      </div>
                    </MDBContainer>
                    {this.state.data.developer && (
                      <CardCarousel
                        title={`Other homes for sale by ${this.state.data.developer.Name}`}
                        currentSeletionId={this.state.data.id}
                        reversed={false}
                        cardStyle={CARD_STYLE_VERTICAL}
                        authCtx={this.authCtx}
                        skip={0}
                        take={10}
                        requestor={otherRequest}
                        viewListing={this.toggleViewListing}
                        exclusions={[this.state.data.id]}
                        padding={0}
                      />
                    )}
                    <MDBCol sm='12' className='mb-5'>
                      <div className='mt-4 mb-5 text-center'>
                        <MDBBtn outline color='primary' onClick={() => this.close()}>
                          Back to listings
                        </MDBBtn>
                      </div>
                    </MDBCol>
                  </div>
                  <div className='listing-footer-container'>
                    <div className='footer-copyright font-text text-center'>
                      <div className='white-text text-uppercase pt-3'>
                        &copy; {new Date().getFullYear()} Copyright Executive Real Estate | People &amp; Property Real Estate Licensed REAA
                        2008
                      </div>
                      <p className='text-uppercase grey-text'>
                        <a href='http://xodesoftware.com/' rel='noopener noreferrer' alt='Designed by xodesoftware.com' target='_blank'>
                          <div className='grey-text'>Web design build by Xode</div>
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

SelectedListingPage.contextType = AuthContext
export default withRouter(SelectedListingPage)
