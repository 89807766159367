export const priceRange = [
    { "name": "100K", "value": 100000 },
    { "name": "150K", "value": 150000 },
    { "name": "200K", "value": 200000 },
    { "name": "250K", "value": 250000 },
    { "name": "300K", "value": 300000 },
    { "name": "350K", "value": 350000 },
    { "name": "400K", "value": 400000 },
    { "name": "450K", "value": 450000 },
    { "name": "500K", "value": 500000 },
    { "name": "550K", "value": 550000 },
    { "name": "600K", "value": 600000 },
    { "name": "650K", "value": 650000 },
    { "name": "700K", "value": 700000 },
    { "name": "750K", "value": 750000 },
    { "name": "800K", "value": 800000 },
    { "name": "850K", "value": 850000 },
    { "name": "900K", "value": 900000 },
    { "name": "950K", "value": 950000 },
    { "name": "1M", "value": 1000000 },
    { "name": "1.1M", "value": 1100000 },
    { "name": "1.2M", "value": 1200000 },
    { "name": "1.3M", "value": 130000 },
    { "name": "1.4M", "value": 1400000 },
    { "name": "1.5M", "value": 1500000 },
    { "name": "1.6M", "value": 1600000 },
    { "name": "1.7M", "value": 1700000 },
    { "name": "1.8M", "value": 1800000 },
    { "name": "1.9M", "value": 1900000 },
    { "name": "2M", "value": 2000000 },
    { "name": "2.5M", "value": 2500000 },
    { "name": "2.75M", "value": 2750000 },
    { "name": "3M", "value": 3000000 },
    { "name": "3.5M", "value": 3500000 },
    { "name": "4M", "value": 4000000 },
    { "name": "5M", "value": 5000000 },
    { "name": "6M", "value": 6000000 },
    { "name": "7M", "value": 7000000 },
    { "name": "8M", "value": 8000000 },
    { "name": "9M", "value": 9000000 },
    { "name": "10M", "value": 10000000 }
]

export default priceRange;