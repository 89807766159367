import React from 'react';
import { MDBAnimation, MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import Spinner from '../SpinnerComponent/Spinner';
import { IS_LOADING, LOADED } from '../../Constants';
import { CONTACT_MESSAGE_IDENTIFIER, STRAPI_URL } from '../../AuthContext/AuthHandler';
import './style.css';
import TagManager from 'react-gtm-module';

const tagManagerArgs2 = {
    dataLayer: {
        event: 'UserSubscribe',
        page: 'home'
    },
    dataLayerName: 'EventData'
}

export default class NewsletterRegisterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            loading: false,
            value: {}
        }
    }

    setLoading = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    sendMessage = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        // test form for validity
        let inputs = event.nativeEvent.target;
        for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            if (input != null && !input.validity.valid) {
                return;
            }
        }

        this.resetError();
        let values = this.state.value;
        values.identifier = CONTACT_MESSAGE_IDENTIFIER;

        this.setLoading(IS_LOADING);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.value)
        };

        fetch('http://localhost:8081' + '/email-register', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data && data.success) {
                    this.setState({
                        message: data.message
                    });
                    this.resetForm();
                } else {
                    if (data && data.success === false)
                        this.messageError(data.message);
                    else
                        this.messageError();
                }
            })
            .catch(error => {
                this.messageError();
            })
            .finally(() => this.setLoading(LOADED));
    };

    resetError = () => {
        this.setState({
            message: '',
            error: false
        });
    }

    messageError = (msg = 'Oops something went wrong! Please try again.') => {
        this.setState({
            message: msg,
            error: true
        });
    }

    resetForm = () => {
        document.getElementById("subscribe-form").classList.remove("was-validated");
        this.setState({
            value: {
                identifier: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            }
        });
    }

    changeHandler = event => {
        let values = this.state.value;
        values[event.target.name] = event.target.value;
        this.setState({
            value: values
        });
    };

    render() {
        const inputStyle = {
            color: 'whitesmoke'
        };

        return (
            <div className="pr-lg-5 pl-lg-5 pr-2 pl-2 mx-auto" style={{ maxWidth: '1250px', color: 'white' }}>
                <div className="display-none-lg text-lg-left text-center mt-4">
                    <div className="mt-lg-4 mb-4 h1-responsive">Subscribe.</div>
                    <h6 className="mb-4" style={{ fontSize: "15px" }}>Subscribe to our newsletter to stay up to date with our latest news in developments and property listings.</h6>
                </div>
                <MDBRow className="mx-auto mt-1 mb-5">
                    <MDBCol md="7" className="vertical-align">

                        <form id="subscribe-form" className="needs-validation" style={{ minWidth: '320px' }} onSubmit={this.sendMessage} noValidate>

                            {
                                this.state.message && this.state.message.length > 0 ? (
                                    <MDBRow>
                                        <MDBCol style={{ minHeight: '45px', padding: '0px' }}>

                                            <MDBAnimation type="bounceIn" duration="500ms">
                                                <div className="mb-4 successful-subscribe" style={{ padding: '15px 5px 0px 5px', fontWeight: 500, fontSize: '20px', color: (this.state.error ? 'black' : 'black') }}>
                                                    {this.state.message}
                                                </div>
                                            </MDBAnimation>

                                        </MDBCol>
                                    </MDBRow>
                                ) : null
                            }

                            <MDBRow style={{ paddingTop: '0px' }}>

                                <MDBCol sm="12" md="6" lg={this.props.noPad ? '12' : '6'} style={{ paddingTop: '0px' }}>
                                    <div>
                                        <MDBInput outline className="custom-input" type="text" label="First Name" size="md" id="firstName" name="firstName" onChange={this.changeHandler} value={this.state.value.firstName} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg={this.props.noPad ? '12' : '6'} style={{ paddingTop: '0px' }}>
                                    <div>
                                        <MDBInput outline className="custom-input" type="text" label="Last Name" size="md" id="lastName" name="lastName" onChange={this.changeHandler} value={this.state.value.lastName} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg={this.props.noPad ? '12' : '6'} style={{ paddingTop: '0px' }}>
                                    <div>
                                        <MDBInput outline className="custom-input" type="email" label="Email" id="emailsub" name="email" size="md" onChange={this.changeHandler} value={this.state.value.email} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg={this.props.noPad ? '12' : '6'}>
                                    <div>
                                        <MDBInput outline className="custom-input" type="tel" label="Phone" id="phonesub" name="phone" size="md" pattern="^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$" onChange={this.changeHandler} value={this.state.value.phone} style={inputStyle} disabled={this.state.loading} />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                                {/* </MDBRow>

                            <MDBRow className="text-center"> */}

                                {/* <MDBCol sm="12" md="6" lg={this.props.noPad ? '12' : '6'} className="vertical-align">
                                    
                                </MDBCol> */}

                            </MDBRow>

                        </form>

                    </MDBCol>

                    <MDBCol md="5" className="vertical-align text-lg-left text-center pl-lg-5">
                        <MDBCol>
                            <div className="display-none-sm">
                                <div className="mt-lg-4 mb-4 h1-responsive font-weight-md">Subscribe.</div>
                                <h6 className="mb-4" style={{ fontSize: "15px" }}>Be the first to know about our latest news in developments and homes for sale.</h6>
                            </div>
                            <MDBBtn outline color="white" size="lg" type="submit" className="ml-lg-0" form="subscribe-form" onClick={() => {TagManager.dataLayer(tagManagerArgs2);}}>
                                <Spinner loading={this.state.loading} text={'Subscribe'} />
                            </MDBBtn>
                        </MDBCol>
                    </MDBCol>

                </MDBRow>

            </div>
        );
    }
}