import React from 'react';

const NewsletterRegisterContainer = ({ children }) => {
    return (
        <div className="pt-4 pb-2" style={{ borderTop: '4px solid white', borderBottom: '4px solid white', backgroundColor: '#c89769', color: 'white' }}>
            {children}
        </div>
    )
}

export default NewsletterRegisterContainer;