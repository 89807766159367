import React, { Component } from 'react';
import './App.css';
import AuthHandler from '../src/AuthContext/AuthHandler';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Call it once in your app. At the root of your app is the best place

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
    toast.configure();
  }

  componentDidMount() {
    var d = document.getElementById('intro-loader');
    setTimeout(() => {
      d.className += " fade-loader";
      setTimeout(() => {
        var body = document.body;
        d.className += " hide-loader";
        body.className = '';
      }, 500);
    }, 1200);
  }

  render() {
    return (
      <div className='App' style={{overflow:'hidden'}}>
        <AuthHandler />
      </div>
    )
  }
}

export default App;


