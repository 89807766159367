import React, { useContext } from 'react';
import { MDBModalBody, MDBContainer, MDBModal, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import ContactUsForm from './ContactUsForm';
import './style.css';
import { AuthContext } from '../../AuthContext/AuthContext';

function ContactUsModal(props) {

    const ctx = useContext(AuthContext);
    const authCtx = ctx.auth;
    let email = '';
    if(authCtx) {
        const user = authCtx.getUser();
        if(user) {
            email = user.email;
        }
    }

    return (
        <MDBContainer>
            <MDBModal centered isOpen={props.isOpen} toggle={props.toggleOpen}>
                <MDBModalBody style={{ backgroundColor: 'rgb(0,0,0)' }}>
                    <MDBIcon icon="times" size="2x" className="cu-close-icon pointer" onClick={() => props.toggleOpen()} />
                    <MDBRow>
                        <MDBCol size="12">
                            <div className="equiry-title">Interested? Send us a message.</div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12">
                            <div className="enquiry-form-container">
                                <ContactUsForm toEmail={props.toEmail} subject={props.subject} email={email} noPad={true} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer >
    )
}

export default ContactUsModal;