import React, { Component } from 'react';
import './style.css';
import { MDBPagination, MDBPageItem, MDBPageNav, MDBIcon } from 'mdbreact';

const MIN_PAGES_DISPLAYED = 6;
const START_PAGE = 1;

class Pagination extends Component {

    // currentPage: int
    // pageCount: int
    // browseToPage(pageNum)

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    getPages() {
        let pages = [];
        let halfMin = Math.floor(MIN_PAGES_DISPLAYED / 2);
        let startIndex = this.props.currentPage - halfMin;
        let endIndex = this.props.pageCount;



        if (startIndex < START_PAGE) {
            startIndex = START_PAGE;
        }

        if (endIndex > startIndex + MIN_PAGES_DISPLAYED) {
            endIndex = startIndex + MIN_PAGES_DISPLAYED;
        }

        if (endIndex - startIndex < MIN_PAGES_DISPLAYED) {
            startIndex = endIndex - MIN_PAGES_DISPLAYED;
            startIndex = startIndex < START_PAGE ? START_PAGE : startIndex;
        }

        for (let i = startIndex; i <= endIndex; i++) {
            pages.push((
                <MDBPageItem key={i} active={i === this.props.currentPage} onClick={() => this.props.browseToPage(i)}>
                    <MDBPageNav>
                        {i}
                    </MDBPageNav>
                </MDBPageItem>
            ));
        }
        return pages;
    }

    changePage = (page) => {
        if (page >= START_PAGE && page <= this.props.pageCount) {
            this.props.browseToPage(page);
        }
    }

    render() {
        const pages = this.getPages();
        const previous = this.props.currentPage - 1;
        const next = this.props.currentPage + 1;

        const leftStyle = { marginRight: '10px' };
        const rightStyle = { merginLeft: '10px' };

        return (
            <div className="pagination-container">
                <MDBPagination>
                    <MDBPageItem disabled={this.props.currentPage === START_PAGE} onClick={() => this.changePage(START_PAGE)} style={leftStyle}>
                        <MDBPageNav aria-label="First">
                            <span aria-hidden="true">First</span>
                        </MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem disabled={this.props.currentPage === START_PAGE} onClick={() => this.changePage(previous)} style={leftStyle}>
                        <MDBPageNav aria-label="Previous">
                            <span aria-hidden="true"><MDBIcon icon="angle-left" /></span>
                        </MDBPageNav>
                    </MDBPageItem>
                    {pages}
                    <MDBPageItem disabled={this.props.currentPage === this.props.pageCount} onClick={() => this.changePage(next)} style={rightStyle}>
                        <MDBPageNav aria-label="Next">
                            <span aria-hidden="true"><MDBIcon icon="angle-right" /></span>
                        </MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem disabled={this.props.currentPage === this.props.pageCount} onClick={() => this.changePage(this.props.pageCount)} style={rightStyle}>
                        <MDBPageNav aria-label="Last">
                            <span aria-hidden="true">Last</span>
                        </MDBPageNav>
                    </MDBPageItem>
                </MDBPagination>
            </div>
        );
    }
}

export default Pagination;