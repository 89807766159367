import { STRAPI_URL, GET, POST, PUT, DONT_PROMPT_LOGIN } from '../AuthContext/AuthHandler';

// New Zealand
export const LISTINGS_ENTITY = '/listings';
export const LISTINGS_ENTITY_COUNT = '/listings/count';

export const FIND_LISTING_REQUEST = 'find_listing';

export const SEARCH_CURRENT_LISTING_REQUEST = 'search_current_listings';
export const SEARCH_CURRENT_LISTING_REQUEST_QUERY = '?Display_as_Sold=false&Go_Live_Date_lte=' + getDateStr();

export const SEARCH_SOLD_LISTING_REQUEST = 'search_sold_listings';
export const SEARCH_SOLD_LISTING_REQUEST_QUERY = '?Display_as_Sold=true&Go_Live_Date_lte=' + getDateStr();

export const SEARCH_COMINGSOON_LISTING_REQUEST = 'search_comingsoon_listings';
export const SEARCH_COMINGSOON_LISTING_REQUEST_QUERY = '?comingSoon=true&Go_Live_Date_lte=' + getDateStr();

export const LATEST_LISTING_REQUEST = 'latest_listings';
export const LATEST_LISTING_REQUEST_QUERY = '?Display_as_Sold=false&Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

export const CURRENT_LISTING_REQUEST = 'current_listings';
export const CURRENT_LISTING_REQUEST_QUERY = '?Display_as_Sold=false&Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

export const SOLD_LISTING_REQUEST = 'sold_listings';
export const SOLD_LISTING_REQUEST_QUERY = '?Display_as_Sold=true&Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

export const COMINGSOON_LISTING_REQUEST = 'comingsoon_listings';
export const COMINGSOON_LISTING_REQUEST_QUERY = '?comingSoon=true&Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

export const OTHER_HOMES_LISTING_REQUEST = 'otherhomes_listings';
export const OTHER_HOMES_LISTING_REQUEST_QUERY = '?Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

export const LISTING_DETAILS_REQUEST = 'listing_details';


// Australia
// export const LISTINGS_ENTITY = '/listings';
// export const LISTINGS_ENTITY_COUNT = '/listings/count';

// export const FIND_LISTING_REQUEST = 'find_listing';

export const SEARCH_CURRENT_LISTING_REQUEST_AUSTRALIA = 'search_current_listings&country.Name=Australia';
export const SEARCH_CURRENT_LISTING_REQUEST_AUSTRALIA_QUERY = '?Display_as_Sold=false&Go_Live_Date_lte='  + getDateStr() + '&country.Name=Australia';

// export const SEARCH_SOLD_LISTING_REQUEST = 'search_sold_listings';
// export const SEARCH_SOLD_LISTING_REQUEST_QUERY = '?Display_as_Sold=true&Go_Live_Date_lte=' + getDateStr();

// export const SEARCH_COMINGSOON_LISTING_REQUEST = 'search_comingsoon_listings';
// export const SEARCH_COMINGSOON_LISTING_REQUEST_QUERY = '?comingSoon=true&Go_Live_Date_lte=' + getDateStr();

export const LATEST_LISTING_REQUEST_AUSTRALIA = 'latest_listings_australia';
export const LATEST_LISTING_REQUEST_AUSTRALIA_QUERY = '?Display_as_Sold=false&Go_Live_Date_lte='  + getDateStr() + '&_sort=Go_Live_Date:DESC&country.Name=Australia';

export const CURRENT_LISTING_REQUEST_AUSTRALIA = 'current_listings_australia';
export const CURRENT_LISTING_REQUEST_AUSTRALIA_QUERY = '?Display_as_Sold=false&Go_Live_Date_lte='  + getDateStr() + '&_sort=Go_Live_Date:DESC&country.Name=Australia';

// export const SOLD_LISTING_REQUEST = 'sold_listings';
// export const SOLD_LISTING_REQUEST_QUERY = '?Display_as_Sold=true&Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

// export const COMINGSOON_LISTING_REQUEST = 'comingsoon_listings';
// export const COMINGSOON_LISTING_REQUEST_QUERY = '?comingSoon=true&Go_Live_Date_lte=' + getDateStr() + '&_sort=Go_Live_Date:DESC';

export const OTHER_HOMES_LISTING_REQUEST_AUSTRALIA = 'otherhomes_listings_australia';
export const OTHER_HOMES_LISTING_REQUEST_QUERY_AUSTRALIA = '?Go_Live_Date_lte='  + getDateStr() + '&_sort=Go_Live_Date:DESC&country.Name=Australia';


// export const LISTING_DETAILS_REQUEST = 'listing_details';


function getDateStr() {
    let date = new Date();
    let day = padDate(date.getDate().toString(), 2);
    let month = padDate((date.getMonth() + 1).toString(), 2);
    let year = date.getFullYear();

    return (year + '-' + month + '-' + day);
}

function padDate(str, length) {
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}

export default class ListingRequest {

    query = '';
    countQuery = '';
    datStr = '';
    type = '';
    authCtx = null;
    developerId = null;

    constructor(listingType) {

        this.type = listingType;

        this.buildUrl();
    }

    setAuth(ctx) {
        this.authCtx = ctx;
    }

    setDeveloperId(id) {
        this.developerId = id;
        // console.log("From set" + this.developerId)
    }

    getDeveloperId() {
        // console.log("My ID" + this.developerId)
    }


    async getHeaders(meth = GET, data = null) {

        let opts = {
            method: meth,
            headers: {}
        };

        await this.authCtx.getTokenRequest(DONT_PROMPT_LOGIN)
            .then((jwt) => {
                opts.headers = {
                    'authorization': 'Bearer ' + jwt
                };
            }).catch((err) => {
            })

        if (data !== null && (meth === PUT || meth === POST)) {
            opts.body = JSON.stringify(data);
        }

        return opts;
    }

    buildUrl() {
        if (this.type === SEARCH_CURRENT_LISTING_REQUEST) {
            this.query = SEARCH_CURRENT_LISTING_REQUEST_QUERY;
        }

        if (this.type === SEARCH_SOLD_LISTING_REQUEST) {
            this.query = SEARCH_SOLD_LISTING_REQUEST_QUERY;
        }

        if (this.type === SEARCH_COMINGSOON_LISTING_REQUEST) {
            this.query = SEARCH_COMINGSOON_LISTING_REQUEST_QUERY;
        }

        if (this.type === LATEST_LISTING_REQUEST) {
            this.query = LATEST_LISTING_REQUEST_QUERY;
        }

        if (this.type === CURRENT_LISTING_REQUEST) {
            this.query = CURRENT_LISTING_REQUEST_QUERY;
        }

        if (this.type === SOLD_LISTING_REQUEST) {
            this.query = SOLD_LISTING_REQUEST_QUERY;
        }

        if (this.type === COMINGSOON_LISTING_REQUEST) {
            this.query = COMINGSOON_LISTING_REQUEST_QUERY;
        }
        if (this.type === OTHER_HOMES_LISTING_REQUEST) {
            this.query = OTHER_HOMES_LISTING_REQUEST_QUERY;
        }

        if (this.type === SEARCH_CURRENT_LISTING_REQUEST_AUSTRALIA) {
            this.query = SEARCH_CURRENT_LISTING_REQUEST_AUSTRALIA_QUERY;
        }

        if (this.type === LATEST_LISTING_REQUEST_AUSTRALIA) {
            this.query = LATEST_LISTING_REQUEST_AUSTRALIA_QUERY;
        }

        if (this.type === CURRENT_LISTING_REQUEST_AUSTRALIA) {
            this.query = CURRENT_LISTING_REQUEST_AUSTRALIA_QUERY;
        }

        if (this.type === OTHER_HOMES_LISTING_REQUEST_AUSTRALIA) {
            this.query = OTHER_HOMES_LISTING_REQUEST_QUERY_AUSTRALIA;
        }
    }

    padDate(str, length) {
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }

    buildParams(params) {
        this.buildUrl();

        // build param string
        // append to end of urlPath
        for (let i = 0; i < params.length; i++) {
            let param = params[i];
            this.query += '&' + param[0] + param[1];
            if (!param[0].includes('start') && !param[0].includes('limit')) {
                this.countQuery += '&' + param[0] + param[1];
            }
        }
    }

    async fetch(callbackObj, skip, take) {
        let headers = await this.getHeaders(GET, null);
        let dataUrl = STRAPI_URL + LISTINGS_ENTITY + this.query + `&_start=${skip}&_limit=${take}`;
        let countUrl = STRAPI_URL + LISTINGS_ENTITY_COUNT + this.query;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => {
                if (callbackObj.fetchSuccess)
                    callbackObj.fetchSuccess(data)
            })
            .catch(error => {
                if (callbackObj.fetchFailed)
                    callbackObj.fetchFailed(error)
            });

        if (callbackObj.fetchCountSuccess && callbackObj.fetchCountFailed) {
            fetch(countUrl)
                .then(response => response.json())
                .then(data => { callbackObj.fetchCountSuccess(data) })
                .catch(error => { callbackObj.fetchCountFailed(error) });
        }
    }

    async fetchOne(id, callbackObj) {
        let headers = await this.getHeaders(GET, null);

        let dataUrl = STRAPI_URL + LISTINGS_ENTITY + '/' + id;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => { callbackObj.fetchSuccess(data) })
            .catch(error => { callbackObj.fetchFailed(error) });
    }
}