import React from 'react';
import { AuthContext } from '../../AuthContext/AuthContext';
import { MDBCol, MDBRow, MDBContainer, MDBInput } from "mdbreact";
import './style.css';
import AccountRequest from '../../Data/UserSettingRequest';
import { IS_LOADING, LOADED } from '../../Constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { GA_ID } from '../../Constants';


class UserSetting extends React.Component {
    authCtx = null;
    userSearchId = 0;

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: IS_LOADING,
            error: false,
            aRequestor: new AccountRequest(),
            value: {}
        }
    }
    componentDidMount() {
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/account');
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });

        const user = this.authCtx.getUser();
        if (!user) {
            this.authCtx.redirectOnLogout();
        }
        this.state.aRequestor.setAuth(this.authCtx);
        this.state.aRequestor.fetch(this, user.id);
    }

    fetchSuccess(data) {
        if (data) {
            this.setState({
                data: data
            });
        }
        this.updateLoading(LOADED);
    }

    fetchError(error) {
        if (error) {
            this.setState({
                error: true
            })
        }
        this.updateLoading(LOADED);
    }

    updateSuccess(data) {
        if (data && data.id) {
            this.setState({
                data: data
            });
            const state = data.ReceiveNewsletter ? 'ON' : 'OFF';
            toast.success(`Newsletters are now  ${state}`, {
                position: toast.POSITION.TOP_CENTER
            });
            this.updateLoading(LOADED);
        } else {
            this.updateError(data);
        }
    }

    updateError(error) {
        if (error) {
            this.setState({
                error: true
            })
        }
        toast.warn("Error updating newsletters! Please try again.", {
            position: toast.POSITION.TOP_CENTER,
            toastId: 2
        });
        this.updateLoading(LOADED);
    }

    updateLoading = (loadState) => {
        setTimeout(() => {
            this.setState({
                loading: loadState
            });
        }, 800);
    }

    changeHandler = (event) => {
        let values = this.state.data;
        values[event.target.name] = event.target.checked;
        this.setState({
            data: values
        });

        if (this.userSearchId > 0)
            window.clearTimeout(this.userSearchId);

        this.userSearchId = window.setTimeout(() => {
            this.state.aRequestor.update(this, this.state.data);
        }, 400);
    }

    render() {
        this.authCtx = this.context.auth;
        const content = this.state.data ? (
            <>
                <Helmet>
                    {/*Primary meta tags */}
                    <title>Account Settings | Executive Real Estate</title>
                    <meta name="description" content="User account settings at Executive Real Estate." />
                    <meta name="author" content="Account Settings | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Account Settings | Executive Real Estate" />
                    <meta property="og:description" content="User account settings at Executive Real Estate." />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="title:title" content="Account Settings | Executive Real Estate" />
                    <meta property="twitter:description" content="User account settings at Executive Real Estate." />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />

                </Helmet>
                <h1 className="account-details-title">Account Details</h1>
                <MDBInput label="Username" value={this.state.data.user.username} />
                <MDBInput label="Email" value={this.state.data.user.email} />
                <div className='custom-control custom-switch'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id='receive-newsletter'
                        readOnly
                        checked={this.state.data.ReceiveNewsletter}
                        name="ReceiveNewsletter"
                        onChange={(e) => this.changeHandler(e)}
                    />
                    <label className='custom-control-label' htmlFor='receive-newsletter'>
                        Receive Newsletter
                    </label>
                </div>

            </>
        ) : null;

        return (
            <div className="account-details-container">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol className="offset-md-1 offset-lg-2" sm="12" md="10" offset-lg="2" lg="8">
                            <div className="account-details-content">
                                {
                                    this.state.loading ?
                                        <div className="bar2"></div>
                                        : content
                                }
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }
};

UserSetting.contextType = AuthContext;
export default UserSetting;