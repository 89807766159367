import React from 'react';
import { MDBAnimation, MDBCol, MDBRow, MDBIcon, MDBContainer, MDBBtn, MDBTypography, MDBBox } from "mdbreact";
import Contact from '../ContactComponents/ContactUs'
import AboutUsExpand from './AboutUsExpand';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { GA_ID } from '../../Constants';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'project',
        page: 'aboutus'
    },
    dataLayerName: 'PageDataLayer'
}


class AboutUsPage extends React.Component {

    constructor(props) {
        super(props);
    }



    componentDidMount() {
        TagManager.dataLayer(tagManagerArgs);
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/careers');

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("about-us");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }
    render() {

        return (
            <>
                <Helmet>

                    {/*Primary meta tags */}
                    <title>Careers | Executive Real Estate</title>
                    <meta name="description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate." />
                    <meta name="author" content="Our story | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="About Us | Executive Real Estate" />
                    <meta property="og:description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate." />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="twitter:title" content="About Us | Executive Real Estate" />
                    <meta property="twitter:description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate." />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />

                </Helmet>
                <div className="bg-img overflow-hidden">
                    <div className="bg-about page-header dark-filter clear-filter header-filter">
                        <div className="container">
                            <div style={{ height: '60vh' }} className="centered1">
                                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                                    <div className="title">
                                        <MDBAnimation reveal type="slideInRight" className="fast">
                                            <h1 className="heading-h1-lg heading-h1-sm">Careers</h1>
                                        </MDBAnimation>
                                        {/* <MDBAnimation reveal type="fadeIn" className="slow"> */}
                                        <h3 className="heading-h2-lg heading-h2-sm">Join the ERE <b className="text-gold">team</b></h3>
                                        {/* </MDBAnimation> */}
                                        {/* <div className="pt-4" onClick={() => this.scrollToGetStarted()}>
                                            <MDBAnimation type="flipInX" className="slow">
                                                <MDBIcon icon="arrow-down" className="white-text pr-3" />
                                            </MDBAnimation>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4" style={{ backgroundColor: 'black' }}>
                        <MDBContainer className="mt-6">
                            <MDBCol md="9" className="mx-auto mb-4">
                                <div className="mt-4">
                                    <h1 className="mb-4 mt-5">Are you interested in joining a high performing team that make a difference?</h1>
                                </div>
                                <h2 className="text-gold pb-5">We would love to hear from you. Get in touch today by emailing <a className="white-text" href="mailto:careers@ere.nz" >careers@ere.nz</a>, or give us a call directly.</h2>
                                <MDBBtn outline color="primary" size="lg" className="mb-5" onClick={() => this.props.history.push('/contactus')}>Contact us</MDBBtn>
                            </MDBCol>
                        </MDBContainer>
                    </div>
                    <Contact />
                </div >
            </>
        )
    }
}

export default withRouter(AboutUsPage);
