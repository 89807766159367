import React, { useEffect, useState } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { STRAPI_URL } from "../../AuthContext/AuthHandler";
import { AU, NZ } from "../../Constants";

const SearchSuburb = (props) => {
  const country = props.country ?? NZ
  const [suburbs, setSuburbs] = useState([]);

  useEffect(() => {
    fetch(STRAPI_URL + '/suburbs?_sort=Name:ASC&_limit=-1')
      .then(response => response.json())
      .then(data => { setSuburbs(data) })
      .catch(error => { setSuburbs([]) });
  }, []);

  const countryFilter = (suburb) => {
    if (country === NZ) {
      return suburb?.listings[0]?.country === null || suburb?.listings[0]?.country === 1
    } else if (country === AU) {
      return suburb?.listings[0]?.country === 2
    }
  }

  return (
    <MDBDropdown size="sm">
      <MDBDropdownToggle caret color="primary">
      {!props.selectedSuburb || props.selectedSuburb === '' ? 'Select Suburb' : props.selectedSuburb}
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem className="dropdown-link" onClick={() => props.suburbSelected('')}>All</MDBDropdownItem>
        {
          suburbs.filter((s) => countryFilter(s)).map((s) => (<MDBDropdownItem className="dropdown-link" key={s.id} onClick={() => props.suburbSelected(s.Name)}>{s.Name}</MDBDropdownItem>))
        }
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

export default SearchSuburb;