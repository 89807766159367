import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBBtn } from 'mdbreact'
import ContactUsForm from './ContactUsForm'
import { AuthContext } from '../../AuthContext/AuthContext'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { GA_ID, NZ } from '../../Constants'
import { withRouter } from 'react-router'
import NewsletterRegisterForm from '../NewsletterRegister/NewsletterRegisterForm'
import NewsletterRegisterContainer from '../NewsletterRegister/NewsletterFormContainer'

class ContactUsPage extends React.Component {
  authCtx = null

  componentDidMount() {
    const ReactPixel = require('react-facebook-pixel');
    const options = {autoConfig: true, debug: false};
    ReactPixel.default.init('379566864000726', undefined, options);
    ReactPixel.default.fbq('track', 'PageView');
    ReactGA.initialize(GA_ID)
    ReactGA.pageview('/contactus')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById('contact-us')
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: 'smooth'
    })
  }

  scrollToSubscribe() {
    var testDiv = document.getElementById('subscribe')
    window.scroll({
      top: testDiv.offsetTop - 90,
      behavior: 'smooth'
    })
  }

  render() {
    this.authCtx = this.context.auth

    let email = ''
    if (this.authCtx) {
      const user = this.authCtx.getUser()
      if (user) {
        email = user.email
      }
    }

    return (
      <>
        <Helmet>
          {/*Primary meta tags */}
          <title>Contact Us | Executive Real Estate</title>
          <meta name='description' content='Contact us to find out more about our luxury properties for sale.' />
          <meta name='author' content='Contact Us | Executive Real Estate' />
          <link rel='canonical' href={window.location.href} />

          {/*Open Graph meta tags */}
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:title' content='Contact Us | Executive Real Estate' />
          <meta property='og:description' content='Contact us to find out more about our luxury properties for sale.' />
          <meta property='og:image' content='https://www.ere.nz/ere-home.png' />

          {/*Twitter meta tags */}
          <meta property='twitter:card' content='summary_large_image'></meta>
          <meta property='twitter:url' content={window.location.href}></meta>
          <meta property='title:title' content='Contact Us | Executive Real Estate' />
          <meta property='twitter:description' content='Contact us to find out more about our luxury properties for sale.' />
          <meta property='twitter:image' content='https://www.ere.nz/ere-home.png' />
        </Helmet>
        <div className='background-darker margin-auto'>
          <div className='bg-contactus page-header dark-filter clear-filter header-filter'>
            <div className='container'>
              <div style={{ height: '60vh' }} className='centered1'>
                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                  <div className='title'>
                    <MDBAnimation reveal type='slideInRight' className='fast'>
                      <h1 className='heading-h1-lg heading-h1-sm-contact'>We'd love to hear from you</h1>
                    </MDBAnimation>
                    <MDBAnimation reveal type='fadeIn' className='slow'>
                      <h3 className='heading-h3-lg heading-h3-sm-contact mb-4'>
                        Whether you have a question about one of our properties, the buying process, or anything else, our team is ready to
                        answer all your questions.
                      </h3>
                    </MDBAnimation>
                    {/* <MDBAnimation type="flipInX" className="slow"> */}
                    {/* <MDBRow className="pt-4 mx-auto"> */}
                    <MDBBtn color='primary' className='pointer' onClick={() => this.props.history.push('/property-sales-team')}>
                      Meet our sales team
                    </MDBBtn>
                    <MDBBtn color='primary' outline className='pointer' onClick={() => this.scrollToSubscribe('subscribe')}>
                      Subscribe
                    </MDBBtn>
                    {/* </MDBRow> */}
                    {/* </MDBAnimation> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id='contact-us'>
            <MDBContainer className='pb-5 mt-4 divider-margin'>
              <MDBAnimation reveal type='fadeIn' className='slow'>
                <MDBRow>
                  <MDBCol sm='12' md='12' lg='7'>
                    <h1 className='heading-margin h1-responsive heading-margin text-left pt-4 pb-4'>Send Us a Message</h1>
                    <h4 className='text-left h4-responsive mx-auto mb-5'>
                      Need additional information or have a question or comment? Fill out the form below and we will be in contact with you.
                    </h4>
                    {this.context.country === NZ && (
                      <MDBRow>
                        <MDBCol className='text-left'>
                          <p style={{ display: 'flex' }}>
                            <i className='fa fa-map-marker-alt fa-lg mr-2 text-gold mt-1' />
                            <span>
                              PO Box 5648
                              <br /> Papanui
                              <br /> Christchurch 8542
                              <br />
                              <br />
                              Office located upstairs in Merivale Mall
                            </span>
                          </p>
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow className='text-left'>
                      <MDBCol md='6'>
                        <p>
                          <i className='fa fa-phone mt-4 fa-lg mb-2 text-gold mr-2' />{' '}
                          <a className='white-text' href='tel:0800373000'>
                            0800 373 000 (0800 ERE 000)
                          </a>
                        </p>
                      </MDBCol>
                      <MDBCol md='6'>
                        <p>
                          <i className='fas fa-envelope mt-4 fa-lg mb-2 text-gold mr-2'></i>
                          <a className='white-text' href='mailto:info@ere.nz'>
                            info@ere.nz
                          </a>
                        </p>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow md='12'>
                      <ContactUsForm email={email} />
                    </MDBRow>
                  </MDBCol>
                  <MDBCol sm='12' md='12' lg='5' className='padding-all'>
                    <div className='mapouter margin-auto map centered2'>
                      <div className='gmap_canvas'>
                        {this.context.country === NZ ? (
                          <iframe
                            className='rounded'
                            title='Executive Real Estate Christchurch Map'
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5786.610579976736!2d172.61080013115367!3d-43.51682608624467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a526353497f%3A0x500ef868479c850!2sMerivale%2C%20Christchurch%208014!5e0!3m2!1sen!2snz!4v1600770784131!5m2!1sen!2snz'
                            width='100%'
                            height='100%'
                            frameBorder='0'
                            style={{ border: '0' }}
                            allowFullScreen=''
                            aria-hidden='false'
                            tabIndex='0'
                          ></iframe>
                        ) : (
                          <iframe
                            className='rounded'
                            title='Executive Real Estate Brisbane Map'
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d453481.3003936522!2d152.7130097296629!3d-27.381253323551135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91579aac93d233%3A0x402a35af3deaf40!2sBrisbane%20QLD%2C%20Australia!5e0!3m2!1sen!2snz!4v1630642550296!5m2!1sen!2snz'
                            width='100%'
                            height='100%'
                            frameBorder='0'
                            style={{ border: '0' }}
                            allowFullScreen=''
                            aria-hidden='false'
                            tabIndex='0'
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBAnimation>
            </MDBContainer>
            <div id='subscribe'>
              <NewsletterRegisterContainer>
                <NewsletterRegisterForm />
              </NewsletterRegisterContainer>
            </div>
            <div className='bg-dark pt-3 pb-2 white-text'>
              <MDBContainer className='pt-5'>
                <MDBCol md='10' lg='10' xl='10'>
                  <h2 className='h2-responsive text-left mb-4'>Do you have land to sell? We are looking for land to purchase.</h2>
                  <h5 className='h5-responsive text-left mb-5'>
                    Get in touch with us to discuss selling.
                    <br />
                    <br />
                    <MDBBtn
                      className='font-weight-md'
                      outline
                      color='white'
                      onClick={() => this.props.history.push('/property-sales-team')}
                    >
                      Get in Touch
                    </MDBBtn>
                  </h5>
                </MDBCol>
              </MDBContainer>
            </div>
          </div>
        </div>
      </>
    )
  }
}

ContactUsPage.contextType = AuthContext
export default withRouter(ContactUsPage)
