import React from 'react';
import { MDBAnimation, MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import Spinner from '../SpinnerComponent/Spinner';
import { IS_LOADING, LOADED } from '../../Constants';
import { CONTACT_MESSAGE_IDENTIFIER, STRAPI_URL } from '../../AuthContext/AuthHandler';
export default class ContactUsForm extends React.Component {

    constructor(props) {
        super(props);

        let startValue = {
            subject: props.subject ? props.subject : '',
            email: props.email ? props.email : '',
            toEmail: props.toEmail ? props.toEmail : ''
        };

        this.state = {
            message: '',
            loading: false,
            value: startValue
        }
    }

    setLoading = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    sendMessage = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        // test form for validity
        let inputs = event.nativeEvent.target;
        for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            if (input != null && !input.validity.valid) {
                return;
            }
        }

        this.resetError();
        let values = this.state.value;
        values.identifier = CONTACT_MESSAGE_IDENTIFIER;

        this.setLoading(IS_LOADING);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.value)
        };

        fetch(STRAPI_URL + '/contact', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data === 200) {
                    this.setState({
                        message: 'Thanks for your message, we\'ll be in touch soon!'
                    });
                    this.resetForm();
                } else {
                    this.messageError();
                }
            })
            .catch(error => {
                this.messageError();
            })
            .finally(() => this.setLoading(LOADED));
    };

    resetError = () => {
        this.setState({
            message: '',
            error: false
        });
    }

    messageError = () => {
        this.setState({
            message: 'Oops something went wrong! Please try again.',
            error: true
        });
    }

    resetForm = () => {
        document.getElementById("contact-form").classList.remove("was-validated");
        this.setState({
            value: {
                identifier: '',
                name: '',
                phone: '',
                email: '',
                subject: '',
                message: ''
            }
        });
    }

    changeHandler = event => {
        let values = this.state.value;
        values[event.target.name] = event.target.value;
        this.setState({
            value: values
        });
    };

    render() {
        const inputStyle = {
            color: 'whitesmoke'
        };

        return (
            <MDBContainer className={this.props.noPad ? '' : 'pb-lg'}>

                <MDBRow>

                    <MDBCol size="12">

                        <form id="contact-form" className="needs-validation" style={{ minWidth: '320px' }} onSubmit={this.sendMessage} noValidate>

                            {
                                this.state.message && this.state.message.length > 0 ? (
                                    <MDBRow>
                                        <MDBCol style={{ minHeight: '45px', padding: '0px' }}>

                                            <MDBAnimation type="bounceIn" duration="500ms">
                                                <div class = "message-sent" style={{ padding: '15px 5px 0px 5px', fontWeight: 500, fontSize: '20px', color: (this.state.error ? '#f44336' : '#8bc34a') }}>
                                                    {this.state.message}
                                                </div>
                                            </MDBAnimation>

                                        </MDBCol>
                                    </MDBRow>
                                ) : null
                            }

                            <MDBRow style={{ paddingTop: '0px' }}>

                                <MDBCol sm="12" md="12" lg={this.props.noPad ? '12' : '6'} style={{ paddingTop: '0px' }}>
                                    <div>
                                        <MDBInput type="text" label="Your Name" size="lg" id="name" name="name" onChange={this.changeHandler} value={this.state.value.name} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="12" lg={this.props.noPad ? '12' : '6'}>
                                    <div>
                                        <MDBInput type="tel" label="Your Phone Number" id="phone" name="phone" size="lg" onChange={this.changeHandler} value={this.state.value.phone} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="12" lg="12">
                                    <div>
                                        <MDBInput type="email" label="Your Email" id="email" name="email" size="lg" onChange={this.changeHandler} value={this.state.value.email} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol size="12">
                                    <div>
                                        <MDBInput type="text" label="Subject" size="lg" id="subject" name="subject" onChange={this.changeHandler} value={this.state.value.subject} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol size="12">
                                    <div>
                                        <MDBInput type="textarea" label="Message" size="lg" id="message" name="message" onChange={this.changeHandler} value={this.state.value.message} style={inputStyle} disabled={this.state.loading} required />
                                        <div className="invalid-feedback">
                                            Required.
                                                </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow style={{ textAlign: 'left' }}>

                                <MDBCol size="12">
                                    <MDBBtn outline color="white" type="submit">
                                        <Spinner loading={this.state.loading} text={'Send Message'} />
                                    </MDBBtn>
                                </MDBCol>

                            </MDBRow>

                        </form>

                    </MDBCol>

                </MDBRow>

            </MDBContainer>
        );
    }
}