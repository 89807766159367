import React from 'react'
import { MDBMask, MDBIcon, MDBAnimation, MDBBtn, MDBNavLink, MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import { Parallax } from 'react-parallax'
// import AboutUsHome from './AboutComponents/AboutUsHome';
import PremiumCarousel from '../Components/CarouselComponents/PremiumCarouselComponent/PremiumCarousel'
// import Search from '../Components/SearchComponents/Search';
import Contact from '../Components/ContactComponents/ContactUs'
import CardCarousel, { CARD_STYLE_VERTICAL } from '../Components/CarouselComponents/CardCarouselComponent/CardCarousel'
import ListingRequest, {
  LATEST_LISTING_REQUEST,
  LATEST_LISTING_REQUEST_AUSTRALIA,
  CURRENT_LISTING_REQUEST,
  SOLD_LISTING_REQUEST
} from '../Data/ListingRequest'
import { AuthContext } from '../AuthContext/AuthContext'
import SelectedListingPage from '../Components/SelectedListingPage/SelectedListingPage'
import { withRouter } from 'react-router-dom'
import LandBanner from '../Components/ListingsPageComponents/LandBanner'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { GA_ID } from '../Constants'
import NewsletterRegisterForm from './NewsletterRegister/NewsletterRegisterForm'
import NewsletterRegisterContainer from './NewsletterRegister/NewsletterFormContainer'
import TagManager from 'react-gtm-module'
import NavSearch from './SearchComponents/NavSearch'
import { STRAPI_URL, GET } from '../AuthContext/AuthHandler'
import DataRequestor, { ENTITY_BLOG_POSTS } from '../Data/DataRequestor'
import BlogCard from './Blog/BlogCard'
import { Logger } from '../Data/Logger'

const tagManagerArgs = {
  gtmId: 'GTM-WT6SLQB'
}

class HomePage extends React.Component {
  authCtx = null
  logger = new Logger()

  constructor(props) {
    super(props)
    this.state = {
      requestor: new DataRequestor(),
      selectedListingId: 0,
      showSelectedListing: false,
      blogs: [],
      limit: 3
    }
  }

  componentDidMount() {
    this.fetchBlogs()
    TagManager.dataLayer(tagManagerArgs)
    const ReactPixel = require('react-facebook-pixel')
    const options = { autoConfig: true, debug: false }
    ReactPixel.default.init('379566864000726', undefined, options)
    ReactPixel.default.fbq('track', 'PageView')
    ReactGA.initialize(GA_ID)
    ReactGA.pageview('/')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.checkPathParam()
  }

  fetchBlogs() {
    let url =
      STRAPI_URL +
      ENTITY_BLOG_POSTS +
      `?_start=${this.state.blogs.length}&_limit=${
        this.state.limit
      }&publishdate_lte=${this.state.requestor.getDateStr()}&_sort=publishdate:DESC`
    this.state.requestor.fetch(this, url, GET)
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        blogs: data
      })
      this.logger.log(`blogs fetch success`)
      this.logger.log(data)
    }
  }

  fetchFailed(error) {
    this.logger.log(error)
  }

  checkPathParam = () => {
    let params = window.location.search
    if (params && params.includes('listing')) {
      params = params.replace('?', '')
      let paramArr = params.split('&')
      let listingParam = paramArr.find((p) => p.includes('listing'))
      paramArr = listingParam.split('=')
      if (paramArr && paramArr.length === 2) {
        this.props.history.push(`/listings?listing=${paramArr[1]}`)
        // this.setState({
        //   selectedListingId: paramArr[1],
        //   showSelectedListing: true
        // })
      }
    }
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById('get-started')
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: 'smooth'
    })
  }

  scrollToSubscribe() {
    var testDiv = document.getElementById('subscribe')
    window.scroll({
      top: testDiv.offsetTop - 90,
      behavior: 'smooth'
    })
  }

  toggleViewListing = (e, id, status, show = false) => {
    if (e !== null) e.preventDefault()
    if (show) {
      this.props.history.push(`/listings?status=${status}&listing=${id}`)
    } else {
      this.props.history.push('')
    }
    this.setState({
      selectedListingId: id,
      showSelectedListing: show
    })
  }

  render() {
    this.authCtx = this.context.auth
    const bgImg = require('../assets/img/blackmamba.png')
    // const parallaxLogo = require('../assets/logos/ere_loader.png');

    const latestRequest = new ListingRequest(LATEST_LISTING_REQUEST)
    latestRequest.setAuth(this.authCtx)
    const latestRequestAu = new ListingRequest(LATEST_LISTING_REQUEST_AUSTRALIA)
    latestRequestAu.setAuth(this.authCtx)
    const currentRequest = new ListingRequest(CURRENT_LISTING_REQUEST)
    currentRequest.setAuth(this.authCtx)
    const soldRequest = new ListingRequest(SOLD_LISTING_REQUEST)
    soldRequest.setAuth(this.authCtx)

    let email = ''
    if (this.authCtx) {
      const user = this.authCtx.getUser()
      if (user) {
        email = user.email
      }
    }

    const selectedListing = this.state.showSelectedListing ? (
      <SelectedListingPage id={this.state.selectedListingId} hideListing={this.toggleViewListing} />
    ) : null
    const cards =
      this.state.blogs.length > 0
        ? this.state.blogs.map((l, i) => {
            return (
              <MDBCol key={l.id} sm='12' md='6' lg='4' className='mb-5 mx-auto'>
                <BlogCard key={l.id} {...l} />
              </MDBCol>
            )
          })
        : null

    return (
      <>
        <Helmet>
          {/*Primary meta tags */}
          <title>Executive Real Estate | The Home of Luxury Properties for Sale</title>
          <meta
            name='description'
            content='Find executive property for sale at Executive Real Estate, a group of property developers specialising in high-end luxury properties.'
          />
          <meta name='author' content='Executive Real Estate | Luxury Property for Sale' />
          <link rel='canonical' href={window.location.href} />

          {/*Open Graph meta tags */}
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:title' content='Executive Real Estate | The Home of Luxury Properties for Sale' />
          <meta
            property='og:description'
            content='Find executive property for sale at Executive Real Estate, a group of property developers specialising in high-end luxury properties.'
          />
          <meta property='og:image' content='https://www.ere.nz/ere-home.png' />

          {/*Twitter meta tags */}
          <meta property='twitter:card' content='summary_large_image'></meta>
          <meta property='twitter:url' content={window.location.href}></meta>
          <meta property='twitter:title' content='Executive Real Estate | The Home of Luxury Properties for Sale' />
          <meta
            property='twitter:description'
            content='Find executive property for sale at Executive Real Estate, a group of property developers specialising in high-end luxury properties.'
          />
          <meta property='twitter:image' content='https://www.ere.nz/ere-home.png' />
        </Helmet>
        <div>
          {selectedListing}
          <div style={{ minHeight: '100vh' }}>
            <MDBMask>
              <Parallax
                bgImage={require('../assets/img/luxury1.jpg')}
                bgImageAlt='Luxury Property in New Zealand'
                // strength={200}
                className='clear-filter header-filter bg-gradient-overlay'
              >
                <div style={{ minHeight: '100vh' }} className='centered1 text-left container mx-auto'>
                  <div style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                    {/* <MDBAnimation reveal type="fadeIn" className="slower">
                        <img src={parallaxLogo} alt="logo" className="img-fluid par-logo divider-margin" />
                      </MDBAnimation> */}
                    <MDBCol md='8' className='pl-lg-0'>
                      {/* <div className="display-3 mt-lg-4 white-text font-serif pt-5">We sell executive <b className="text-gold">new homes</b>.</div> */}
                    </MDBCol>
                    <MDBCol md='11' className='pl-lg-0' style={{ paddingTop: 100 }}>
                      <div className='header-home-text mt-3'>
                        Selling luxury <b className='text-gold'>NEW</b> and <b className='text-gold'>EXISTING</b> homes in premium locations
                        across Australia and New Zealand.
                      </div>
                    </MDBCol>
                    <MDBCol md='11' className='pl-lg-0'>
                      <div className='h5 mt-3'>Executive Real Estate Design Centre | Merivale Mall - Upstairs</div>
                    </MDBCol>
                    <div className='divider-margin text-lg-left text-center'>
                      <MDBCol className='mb-4 p-0'>
                        <NavSearch className='nav-search' authCtx={this.authCtx} />
                      </MDBCol>
                      <MDBBtn size='lg' color='default' className='ml-lg-0'>
                        <MDBNavLink style={{ color: 'white' }} to='/listings' data-element-name='Listings' className='gtm-event-button'>
                          View NZ listings
                        </MDBNavLink>
                      </MDBBtn>
                      <MDBBtn size='lg' color='white' className='ml-lg-0'>
                        <MDBNavLink to='/au/listings' data-element-name='Listings' className='gtm-event-button black-text'>
                          <span className='black-text'>View AU listings</span>
                        </MDBNavLink>
                      </MDBBtn>
                      {/* <MDBBtn size="lg" color="white" onClick={() => {
                        this.scrollToSubscribe();
                      }} className="gtm-event-button min-width-sm">
                        Subscribe
                      </MDBBtn> */}
                    </div>
                    <div className='margin-large parallax-down-arrow' onClick={() => this.scrollToGetStarted()}>
                      <MDBAnimation type='bounce' className='slow' infinite>
                        <MDBIcon icon='angle-down' className='black-text pr-3' style={{ fontSize: '20px' }} />
                      </MDBAnimation>
                    </div>
                  </div>
                </div>
              </Parallax>
            </MDBMask>
          </div>

          <div id='get-started' className='bg-img pt-5 pb-5 bg-gradient-overlay-light'>
            <div className='pc-padding pb-5'>
              <div className='text-uppercase text-gold font-weight-md mb-4 mt-5'>Premium homes for sale</div>
              <PremiumCarousel
                isVertical={true}
                speed={600}
                displayThumbs={false}
                authCtx={this.authCtx}
                skip={0}
                take={10}
                requestor={latestRequest}
                viewListing={this.toggleViewListing}
              />
            </div>
            {/* <MDBAnimation reveal type="fadeIn" className="slower">
              <AboutUsHome />
            </MDBAnimation> */}
          </div>

          <div id='subscribe'>
            <NewsletterRegisterContainer>
              <NewsletterRegisterForm />
            </NewsletterRegisterContainer>
          </div>

          <div overlay='stylish-strong' className='dark-overlay'>
            <MDBMask>
              <Parallax
                // blur={2}
                bgImage={require('../assets/img/luxury.png')}
                bgImageAlt='the cat'
                strength={450}
              >
                <div style={{ height: '50vh' }}>
                  <div className='mt-lg-4 pt-4 mb-lg-4 pb-5 centred1'>
                    {/* <h3 className="banner-text-lg mt-4">We specialise in executive new homes in the heart of Christchurch, New Zealand.</h3> */}
                    {/* <h5 className="divider-margin-small banner-text-sm">Find the Property of Your Dreams Today.</h5> */}
                    {/* <MDBRow>
                      <MDBCol className="mb-5 mt-5 mx-auto">
                        <MDBCol md="7" className="mx-auto text-center">
                          <NavSearch className="nav-search" authCtx={this.authCtx} />
                        </MDBCol>
                        <MDBBtn className="text-left mt-5" color="secondary">
                          <MDBNavLink onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })} style={{ color: 'whitesmoke' }} to="/property-sales-team" className="gtm-event-button">
                            Get in touch
                          </MDBNavLink>
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow> */}
                  </div>
                </div>
              </Parallax>
            </MDBMask>
          </div>

          {/* skip={10} */}
          <div className='bg-img' style={{ backgroundImage: 'url(' + bgImg + ')' }}>
            <CardCarousel
              title={'New Zealand Listings'}
              reversed={false}
              cardStyle={CARD_STYLE_VERTICAL}
              authCtx={this.authCtx}
              take={10}
              requestor={currentRequest}
              viewListing={this.toggleViewListing}
            />

            <div className='pt-lg-0 pt-3'>
              <MDBBtn className='text-right' size='lg' color='primary'>
                <MDBNavLink
                  onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
                  style={{ color: 'whitesmoke' }}
                  to='/listings'
                  className='gtm-event-button'
                >
                  New Zealand Listings <MDBIcon icon='angle-right' className='ml-2' />
                </MDBNavLink>
              </MDBBtn>
            </div>

            <CardCarousel
              title={'Australian Listings'}
              reversed={true}
              cardStyle={CARD_STYLE_VERTICAL}
              authCtx={this.authCtx}
              take={10}
              requestor={latestRequestAu}
              viewListing={this.toggleViewListing}
            />

            <div className='pb-5 pt-lg-0 pt-3'>
              <MDBBtn className='text-right mb-5' size='lg' color='primary'>
                <MDBNavLink
                  onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
                  style={{ color: 'whitesmoke' }}
                  to='/au/listings'
                  className='gtm-event-button'
                >
                  Australian Listings <MDBIcon icon='angle-right' className='ml-2' />
                </MDBNavLink>
              </MDBBtn>
            </div>

            {/* <CardCarousel title={"Recently Completed"} reversed={false} cardStyle={CARD_STYLE_VERTICAL} authCtx={this.authCtx} skip={0} take={10} requestor={soldRequest} viewListing={this.toggleViewListing} />
            <div className="divider-bottom">
              <MDBBtn className="text-right" size="lg" color="primary">
                <MDBNavLink onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })} style={{ color: 'whitesmoke' }} to="/sold" className="gtm-event-button">
                  View All Completed <MDBIcon icon="angle-right" className="ml-2" />
                </MDBNavLink>
              </MDBBtn>
            </div> */}
          </div>
          {cards && (
            <MDBContainer fluid>
              <div className='pb-3 pt-5'>
                <div className='h2-responsive text-center text-gold pt-5 pb-5'>Executive News</div>
                <MDBRow>{cards}</MDBRow>
                <div className='pb-5'>
                  <MDBBtn className='text-right mb-5' size='lg' color='primary'>
                    <MDBNavLink
                      onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
                      style={{ color: 'whitesmoke' }}
                      to='/executive-news'
                      className='gtm-event-button'
                    >
                      All news <MDBIcon icon='angle-right' className='ml-2' />
                    </MDBNavLink>
                  </MDBBtn>
                </div>
              </div>
            </MDBContainer>
          )}

          <LandBanner />
          <Contact email={email} />
        </div>
      </>
    )
  }
}

HomePage.contextType = AuthContext
export default withRouter(HomePage)
