import React from 'react';
import './style.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { AuthContext } from '../../AuthContext/AuthContext';
import { LOADED } from '../../Constants';
import WatchlistRequest from '../../Data/WatchlistRequest';
import ListingCard from '../CarouselComponents/CardCarouselComponent/ListingCardComponent/ListingCard';
import SelectedListingPage from '../SelectedListingPage/SelectedListingPage';
import NoResultComponent from '../NoResultComponent/NoResultComponent';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
import WatchlistListingNotFound from './WatchlistListingNotFound';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga'
import { GA_ID } from '../../Constants';


export const LISTING_STATUS_CURRENT = 'current';
export const LISTING_STATUS_SOLD = 'sold';

class WatchlistPage extends React.Component {

    authCtx;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            invalidWatchlistItems: [],
            loading: true,
            wRequestor: new WatchlistRequest(),
            selectedListingId: 0,
            showSelectedListing: false
        };
    }

    componentDidMount() {
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/watchlist');
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });

        const user = this.authCtx.getUser();
        let params = this.checkPathParam(user);
        if (!user) {
            let path = this.getStatusPath(params);
            this.authCtx.redirectOnLogout(path);
        } else {
            this.state.wRequestor.setAuth(this.authCtx);
            this.state.wRequestor.fetch(this);
        }
    }

    getStatusPath = (params) => {
        let path = ''

        if (params === null) {
            return path;
        }
        if (params.status === LISTING_STATUS_CURRENT) {
            path += 'listings';
        } else if (params.status === LISTING_STATUS_SOLD) {
            path += 'sold';
        }
        else {
            return path;
        }
        return `${path}?status=${params.status}&listing=${params.id}`;
    }

    checkPathParam = (user) => {
        let params = window.location.search;
        let id = null;
        let status = null;
        if (params && params.includes('listing')) {
            params = params.replace('?', '');
            let paramArr = params.split('&');
            let listingParam = paramArr.find(p => p.includes('listing'));
            let statusParam = paramArr.find(p => p.includes('status'));
            paramArr = listingParam.split('=');
            if (paramArr && paramArr.length === 2) {
                if (user) {
                    this.setState({
                        selectedListingId: paramArr[1],
                        showSelectedListing: true
                    });
                } else {
                    id = paramArr[1];
                }
                paramArr = statusParam ? statusParam.split('=') : null;
                if (paramArr && paramArr.length === 2) {
                    status = paramArr[1];
                }
            }
        }
        return id ? { status: status, id: id } : null;
    }

    fetchSuccess(data) {
        if (data) {
            this.setState({
                data: data
            });
        }
        this.updateLoading(LOADED);
    }

    fetchFailed(error) {
        if (error) {
            this.setState({
                error: true
            })
        }
        this.updateLoading(LOADED);
    }

    updateLoading = (loadState) => {
        setTimeout(() => {
            this.setState({
                loading: loadState
            });
        }, 800);
    }

    removeListing = (watchlistItem, watchListed) => {
        if (watchlistItem && watchlistItem.listing) {
            let data = this.state.data.filter(l => l.id !== watchlistItem.id);
            setTimeout(() => {
                this.setState({
                    data: data
                });
                const listingNo = watchlistItem.listing.ListingNo;
                toast.success(`Listing ${listingNo} removed from watchlist.`, {
                    position: toast.POSITION.TOP_CENTER
                });
            }, 250);
        }
    }

    removeNotFound = () => {
        let fData = [...this.state.data];
        let notFound = fData.filter(wl => wl.listing === null);
        fData = fData.filter(wl => wl.listing !== null);
        setTimeout(() => {
            this.setState({
                data: fData
            });
        }, 500);

        for (let i = 0; i < notFound.length; i++) {
            let wlItem = notFound[i];
            this.state.wRequestor.delete(this.removeSuccess, wlItem.id);
        }
    }

    removeSuccess(data, watchListed) { }

    toggleViewListing = (id, status, show = false) => {
        if (show) {
            this.props.history.push(`/watchlist?status=${status}&listing=${id}`);
        } else {
            this.props.history.push('/watchlist');
        }
        this.setState({
            selectedListingId: id,
            showSelectedListing: show
        });
    }

    render() {
        this.authCtx = this.context.auth;
        const hasInvalidWatchlisItems = this.state.data.some(wl => wl.listing === null);
        const selectedListing = this.state.showSelectedListing ? (<SelectedListingPage id={this.state.selectedListingId} hideListing={this.toggleViewListing} />) : null;
        const cards = this.state.data.map((l, i) => {
            let listing = l.listing;
            if (listing) {
                listing.watchListed = true;

                return (
                    <div key={l.id} className="pb-4">
                        <ListingCard {...listing} viewListing={this.toggleViewListing} updateListing={this.removeListing} isVertical={false} />
                    </div>
                )
            } else {
                return null;
            }
        });

        const content = this.state.data ? (
            <>
                <h1 className="watchlist-title">My Saved Homes</h1>
                {hasInvalidWatchlisItems ? <WatchlistListingNotFound removeNotFound={this.removeNotFound} /> : null}
                {this.state.data.length > 0 ? cards : <NoResultComponent text={'No Watchlist Items'} />}
            </>
        ) : null;

        return (
            <>
                <Helmet>

                    {/*Primary meta tags */}
                    <title>My Saved Homes | Executive Real Estate</title>
                    <meta name="description" content="Find executive property for sale at Executive Real Estate, a group of property developers specialising in high-end luxury properties." />
                    <meta name="author" content="My Saved Homes | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="My Saved Homes | Executive Real Estate" />
                    <meta property="og:description" content="Find executive property for sale at Executive Real Estate, a group of property developers specialising in high-end luxury properties." />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="title:title" content="My Saved Homes | Executive Real Estate" />
                    <meta property="twitter:description" content="Find executive property for sale at Executive Real Estate, a group of property developers specialising in high-end luxury properties." />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />

                </Helmet>
                {selectedListing}
                <div className="watchlist-container">
                    <MDBContainer className="divider-bottom">
                        <MDBRow>
                            <MDBCol className="offset-md-1 offset-lg-1" sm="12" md="10" lg="10" style={{ padding: '4px' }}>
                                <div className="watchlist-content">
                                    {
                                        this.state.loading ?
                                            <div className="bar2"></div>
                                            : content
                                    }
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </>
        )
    }
}

WatchlistPage.contextType = AuthContext;
export default withRouter(WatchlistPage);