import React from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

export const SORT_MOST_RECENT = 'Newest';
export const SORT_OLDEST = 'Oldest';
export const SORT_LOWEST_PRICE = 'Lowest Price';
export const SORT_HIGHEST_PRICE = 'Highest Price'

const SortResults = (props) => {
  return (
    <MDBDropdown size="sm">
      <MDBDropdownToggle caret color="primary">
        {!props.selectedSort || props.selectedSort === '' ? 'Select Sort By' : props.selectedSort}
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem className="dropdown-link" onClick={() => props.sortSelected(SORT_MOST_RECENT)}>Newest (default)</MDBDropdownItem>
        <MDBDropdownItem className="dropdown-link" onClick={() => props.sortSelected(SORT_LOWEST_PRICE)}>Lowest Price</MDBDropdownItem>
        <MDBDropdownItem className="dropdown-link" onClick={() => props.sortSelected(SORT_HIGHEST_PRICE)}>Highest Price</MDBDropdownItem>
        <MDBDropdownItem className="dropdown-link" onClick={() => props.sortSelected(SORT_OLDEST)}>Oldest</MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

export default SortResults;