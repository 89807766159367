import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import React, { useEffect, useState } from "react";
import { STRAPI_URL } from '../../AuthContext/AuthHandler';

const SearchCategory = (props) => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(STRAPI_URL + '/blog-categories?_sort=Name:ASC')
      .then(response => response.json())
      .then(data => { setCategories(data) })
      .catch(error => { setCategories([]) });
  }, []);

  return (
    <MDBDropdown className="mb-3">
      <MDBDropdownToggle caret color="default" className="dark-text rounded-btn">
        {!props.selectedCategory || props.selectedCategory === '' ? 'Filter by Type' : props.selectedCategory}
      </MDBDropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem className="dropdown-link xode-text" onClick={() => props.categorySelected('')}>All</MDBDropdownItem>
        {
          categories.length > 0 && categories.map((c) => (<MDBDropdownItem key={c.id} className="dropdown-link xode-text" onClick={() => props.categorySelected(c.name)}>{c.name}</MDBDropdownItem>))
        }
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

export default SearchCategory;