import React from "react";
import './BlogCard.css';
import './style.css';
import { DateHelper } from '../../Data/DateHelper';
import ImageHelper, { PREFERRED_FORMAT_MEDIUM } from '../../Data/ImageHelper';
import { MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBIcon } from 'mdbreact';
import { withRouter } from 'react-router';

function BlogCard(props) {

    const urlRef = '/post?id=' + props.id + '&executive-news=' + props.url
    const dateHelper = new DateHelper();

    const imageHelper = new ImageHelper();
    const coverImg = props ? `url("${props.coverphoto.url}")` : null;

    return (
        <div>
            <a href={urlRef} >
                <MDBCard className="blog-hover card-size">
                    {/* <div className=" h5-responsive">Article</div> */}
                    <div className="img-fluid" style={{ backgroundImage: coverImg, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "220px" }}> </div>
                    <MDBCardBody className="blog-rounded">
                        <MDBCardText className="text-left" style={{ minHeight: "115px", minWidth: "115px" }}>
                            <div className="h6-responsive h6-responsive text-gold text-uppercase mb-2 mb-2" style={{ lineHeight: "25px" }}>
                                <MDBRow>
                                    <MDBCol md="6">
                                        {
                                            props.blog_category ? (
                                                <>
                                                    {props.blog_category.name}
                                                </>
                                            ) : null
                                        }
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <div className="text-left text-lg-right">{
                                            props.readtime ? (
                                                <>
                                                    {props.readtime}
                                                </>
                                            ) : null
                                        } min read</div>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className="white-text blog-card-hover  h2-responsive text-left mb-2">{
                                props.title ? (
                                    <>
                                        {props.title}
                                    </>
                                ) : null
                            }
                            </div>
                            <div className="h6-responsive  text-uppercase">
                                <MDBRow>
                                    <MDBCol md="12" className="white-text text-left blog-card-hover">
                                    <hr className="text-left gold-bg d-inline-block mx-auto mb-1" style={{ width: "40%", height: "1px", backgroundColor: '#cda177' }} /><br /><br />
                                        Continue reading <MDBIcon icon="arrow-right pointer white-text ml-2 mt-1" />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </a>
        </div >
    );
}

export default withRouter(BlogCard)



