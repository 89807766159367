import React, { useContext, useState } from 'react';
import { MDBBtn, MDBIcon } from "mdbreact";
import Spinner from '../SpinnerComponent/Spinner';
import { AuthContext } from '../../AuthContext/AuthContext';
import WatchlistRequest from '../../Data/WatchlistRequest';
import { PROMPT_LOGIN } from '../../AuthContext/AuthHandler';

function WatchlistButton(props) {
    const ctx = useContext(AuthContext);
    const requestor = new WatchlistRequest();
    const [loading, setLoading] = useState(false);

    const updateListing = (data, watchListed) => {
        setTimeout(() => {
            setLoading(false);
        }, 250);
        props.updateListing(data, watchListed);
    }

    const updateWatchlist = (id, watchListed) => {
        const authCtx = ctx.auth;
        requestor.setAuth(authCtx);

        authCtx.getTokenRequest(PROMPT_LOGIN).then((token) => {
            setLoading(true);
            if (watchListed === true) {
                requestor.delete(updateListing, id);
            } else {
                const user = authCtx.getUser();
                requestor.post(updateListing, { listingId: id, user: user.id });
            }
        }).catch((error) => {
            // dont log
        });
    }

    return (
        <div
            disabled={loading}
            onClick={() => updateWatchlist(props.id, props.watchListed)}
        >
            <a className="w-100 text-center">
                <div style={{ minWidth: '25px', marginTop: '10px' }} >
                    {
                        loading ? <Spinner loading={loading} text={''} /> :
                            <>
                                {props.watchListed ?
                                    <i className="fas fa-heart mr-3 pt-2 mx-auto"></i>
                                    : <i className="far fa-heart mr-3 pt-2 mx-auto"></i>}
                                <span className="gtm-event-button white-text">
                                    {props.watchListed ? '' : ''}
                                </span>
                            </>

                    }
                </div>
            </a>
        </div>
    );
}

export default WatchlistButton;