import React from 'react';
import { MDBAnimation, MDBCol, MDBRow, MDBIcon, MDBContainer, MDBBtn, MDBTypography, MDBBox } from "mdbreact";
import Contact from '../ContactComponents/ContactUs'
import AboutUsExpand from './AboutUsExpand';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { GA_ID } from '../../Constants';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'project',
        page: 'aboutus'
    },
    dataLayerName: 'PageDataLayer'
}


class AboutUsPage extends React.Component {

    constructor(props) {
        super(props);
    }



    componentDidMount() {
        TagManager.dataLayer(tagManagerArgs);
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/our-story');

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("about-us");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }
    render() {

        const tonyWriteup = `Tony Anderson has built Silverstar Developments from the ground up into a thriving company. His work ethic and keen eye for quality shine through in all the homes he builds. Tony is often traveling overseas, in particular to Australia and Asia, gaining knowledge on the latest trends and building methods which are then implicated in the homes he builds – which translates to a very special home.<br />
        Every development is unique, and he never re uses plans so every home is a one of a kind.`;
        const peteWriteUp = `Pete De Gouw has been building in Canterbury since 1977 and to this day remains passionate about the industry.  Despite keeping a low profile, Home Trends Builders have been awarded a number of Registered Master Builders ‘House of the Year’ awards.
        <br />He attributes the success of his medium-sized building company to his dedication to following any job through from start to finish and delivering a quality product on time as promised.`;
        const coreyWriteup = `Corey Tauwhare an accomplished entrepreneur and director of Brockworth Developments. He brings more than 10 years of residential building and management aspects of real estate development projects along with vast knowledge of administration and team building.
        <br />Project Manager, Corey is responsible for overseeing the daily progress of all projects, including oversight of associated contractors, safety compliance and maintenance.
        <br />A local boy growing up in Bishopdale and attending Burnside high school Corey is active with a range of sports being a world cup winning Touch football player. Corey loves to follow and support his children in their chosen sports.`;
        const rossWriteup = ` Ross Gilray is an accomplished entrepreneur and director of Brockworth. He brings more than 20 years’ experience in the investing, financial, and management aspects of real estate development projects along with vast knowledge of city planning.
        <br />A results-oriented and decisive leader, Ross steady stewardship has quickly led Brockworth Developments to pre-eminence in the Christchurch real estate world.
        <br />Over the course of his esteemed career, Ross has secured over $50 million of funds for a variety of real-estate transactions, and has committed to investing in communities before and after construction.
        <br />A local Christchurch boy and former police officer in Christchurch, Ross is active with a range of sports being a successful world champion rower and an avid supporter of his children’s own sporting endeavours.`;
        const mikeWriteup = `Mike Bryant, Director of Prime Group, has been building homes for approximately 20 years. During this time, Mike has built hundreds of homes throughout Canterbury, with the majority being for Design and Build clients on their own land, as well as building homes to sell. 
        <br />Over the last 5 years Mike has concentrated on developing and building homes to sell in the Fendalton, Merivale, Central City and Saint Albans areas of Christchurch. Prime Group has built up a great team of preferred suppliers and contractors to ensure their work is to the same high standards that Mike expects on every job.
        <br />Mike is heavily involved in every build from liaising with architects, councils, surveyors, engineers, suppliers, contractors as well overseeing the Project Management and running of every job.`;


        const bgImg = require('../../assets/img/blackmamba.png');
        return (
            <>
                <Helmet>

                    {/*Primary meta tags */}
                    <title>Our story | Executive Real Estate</title>
                    <meta name="description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate." />
                    <meta name="author" content="Our story | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="About Us | Executive Real Estate" />
                    <meta property="og:description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate." />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="twitter:title" content="About Us | Executive Real Estate" />
                    <meta property="twitter:description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate." />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />

                </Helmet>
                <div className="bg-img overflow-hidden" style={{ backgroundImage: 'url(' + bgImg + ')' }}>
                    <div className="bg-about page-header dark-filter clear-filter header-filter">
                        <div className="container">
                            <div style={{ height: '60vh' }} className="centered1">
                                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                                    <div className="title">
                                        <MDBAnimation reveal type="slideInRight" className="fast">
                                            <h1 className="heading-h1-lg heading-h1-sm">Our story</h1>
                                        </MDBAnimation>
                                        {/* <MDBAnimation reveal type="fadeIn" className="slow"> */}
                                        <h3 className="heading-h2-lg heading-h2-sm"><b className="text-gold">Executive</b> Real Estate</h3>
                                        {/* </MDBAnimation> */}
                                        {/* <div className="pt-4" onClick={() => this.scrollToGetStarted()}>
                                            <MDBAnimation type="flipInX" className="slow">
                                                <MDBIcon icon="arrow-down" className="white-text pr-3" />
                                            </MDBAnimation>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5" style={{ backgroundColor: 'black' }}>
                        <MDBContainer className="mt-5 mb-5">
                            <div className="mt-4">
                                <h1 className="mb-4 mt-5">Our vision is simple, to build quality luxury homes in established sought after suburbs.</h1>
                            </div>
                            <h2 className="text-gold pb-5">We sell homes designed and built by multi-award winning property developers.</h2>

                            {/* <hr className="white accent-2 pl-4 mb-4 mt-4 d-inline-block mx-auto" style={{ width: "100px", height: "2px" }} /> */}
                        </MDBContainer>
                        <MDBRow style={{ maxWidth: '1600px' }} className='mx-auto'>
                            <MDBCol md="6">
                                <div className="banner-about mb-lg-5 mb-5">

                                </div>
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBContainer className="pl-lg-5 pb-4 pt-2 pr-lg-5 h6-responsive text-left">
                                    <h2 className="text-gold mb-4">Our history</h2>
                                    ERE was founded in 2020 by a collective of prolific developers within the Canterbury real estate industry, alongside Tony Anderson.
                                    <br /><br />
                                    From its inception, ERE’s intention was to bring an aggregated portfolio of new-builds direct to clients. Through ERE’s evolution in real-estate, clear business direction and a focus on delivering a prestigious service, to date, ERE’s objective has remained the same – to remove the middleman and offer top-level properties to clients, directly from developers.
                                </MDBContainer>
                            </MDBCol>
                            <MDBCol md="6">
                                <div className="banner-about-1">

                                </div>
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBContainer className="pl-lg-5 pr-lg-5 pb-4 h6-responsive text-left">
                                    <h2 className="text-gold mb-4 mt-4">Lifestyle by design</h2>
                                    All homes offered by ERE are designed for ease-of-living, with modern functionality and lifestyle habits at the forefront. Our Merivale showroom in Christchurch, New Zealand enables clients to grasp the full package and process - from planning, to scheduled development right through to completed projects. What this offers, is an assuredness that you will find a property at a time and stage that suits you.
                                    <br /><br />
                                    At present, Executive homes are centralised in Merivale, Papanui, Strowan, Fendalton, and St Albans, and recently expanded into Brisbane, Australia. When you see Executive Real Estate’s recognisable gold-seal, you can be assured of the home’s warmth, comfortability and durability. You can find a comprehensive portfolio of homes by viewing our New Zealand listings, our Australian listings – or call us to pop into our showroom.
                                </MDBContainer>
                            </MDBCol>
                        </MDBRow>
                        <div className="pb-5 pt-5 white-text">
                            <MDBContainer className="pt-2 text-center">
                                <MDBCol md="12" lg="12" xl="12">
                                    <div className="pb-3 h3-responsive text-gold mt-3">Get in touch</div>
                                    <MDBCol md="9" className="mx-auto">
                                        <div className="pb-4 h5-responsive mt-3">Love what you hear and want to learn more about us? Get in touch with us at <a className="white-text" href="mailto:info@ere.nz" >info@ere.nz</a>, through our contact form, or contact one of our sales team directly.</div>
                                        <MDBBtn outline color="primary" size="lg" className="mb-5" onClick={() => this.props.history.push('/contactus')}>Contact us</MDBBtn>
                                    </MDBCol>
                                </MDBCol>
                            </MDBContainer>
                        </div>
                    </div>
                    <Contact />
                </div >
            </>
        )
    }
}

export default withRouter(AboutUsPage);
