import React from 'react'
import Navbar from '../Components/NavComponent/Navbar'
import Footer from '../Components/FooterComponents/Footer'
import HomePage from '../Components/Home'
import HomeAustralia from '../Components/HomeAustralia'
import ListingsCurrentPage from '../Components/ListingsPageComponents/ListingsCurrentPage'
import ListingsCurrentAuPage from '../Components/ListingsPageComponents/ListingsAuCurrentPage'
import ListingsSoldPage from '../Components/ListingsPageComponents/ListingsSoldPage'
import SelectedListingPage from '../Components/SelectedListingPage/SelectedListingPage'
//import ListingsComingSoonPage from '../Components/ListingsPageComponents/ComingSoonPage'
import ContactUsPage from '../Components/ContactComponents/ContactUsPage'
import UserAccountPage from '../Components/UserSettingComponent/UserSetting'
import NeighbourhoodPage from '../Components/NeighbourhoodComponents/NeighbourhoodPage'
import AboutUsPage from '../Components/AboutComponents/AboutUsPage'
import Careers from '../Components/AboutComponents/Careers'
import PartnersPage from '../Components/AboutComponents/Partners'
import BlogPage from '../Components/Blog/BlogPage'
import SelectedBlogPage from '../Components/Blog/SelectedBlogPage'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

//trying tag manager - to refactor
import TagManager from 'react-gtm-module'

import { AuthContext } from './AuthContext'
import AuthModal from '../Components/AuthComponent/AuthModal'
import WatchlistPage from '../Components/WatchlistComponent/WatchlistPage'
import { IS_LOADING, LOADED, AU, NZ } from '../Constants'
import NavSearch from '../Components/SearchComponents/NavSearch'
import SalesTeam from '../Components/AboutComponents/SalesTeam'

export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'

export const CONTACT_MESSAGE_IDENTIFIER = '&dFNPkfPd3kXvyf6dktbPSpQ=MNZY?%gWqcQwu3JsaShsYs-AM?f6T8fYJYrAN^c'
export const storage = window.localStorage
export const STRAPI_URL = 'https://admin.ere.nz'
// export const STRAPI_URL = 'http://localhost:8081';
//export const STRAPI_URL = 'http://192.168.1.116:8081';
export const LOGIN_PAGE = 'login_page'
export const LOGOUT_PAGE = 'logout_page'
export const REGISTER_PAGE = 'register_page'
export const REGISTER_COMPLETE_PAGE = 'register_complete_page'
export const CONFIRM_EMAIL_PAGE = 'confirm_email_page'
export const FORGOT_PASSWORD_PAGE = 'forgot_password'
export const FORGOT_PASSWORD_CONFIRM_PAGE = 'forgot_password_confirm'
export const LOGGED_IN = true
export const LOGGED_OUT = false
export const PROMPT_LOGIN = true
export const DONT_PROMPT_LOGIN = false
export const AUTH_URL_PATHS = ['account', 'watchlist']

const JWT_STORE_KEY = 'jwt'
const USER_STORE_KEY = 'user'

//trying gtm - to refactor
const tagManagerArgs = {
  gtmId: 'GTM-NRGPN3C'
}

TagManager.initialize(tagManagerArgs)

export default class AuthHandler extends React.Component {
  debugId = 'Auth_Handler_'
  loginPath = '/auth/local'
  registerPath = '/auth/local/register'
  forgotPasswordPath = '/auth/forgot-password'
  resetPasswordPath = '/auth/reset-password'
  settingsPath = '/usersettings/'
  authSubscribers = []

  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      page: LOGIN_PAGE,
      show: false,
      loading: LOADED,
      debug: false,
      jwt: null,
      user: null,
      params: [],
      country: null
    }
  }

  getCountryCode = () => {
    const auSite = 'executive.re'
    const nzSite = 'ere.nz'
    const domain = window.location.hostname.replace('www.', '')

    switch (domain) {
      case nzSite:
        this.setState({
          country: NZ
        })
        break
      case auSite:
        this.setState({
          country: AU
        })
        break
      default:
        this.setState({
          country: NZ
        })
        break
    }
  }

  addSubscriber = (sub) => {
    this.authSubscribers.push(sub)
  }

  removeSubscriber = (sub) => {
    this.authSubscribers = this.authSubscribers.filter((s) => s !== sub)
  }

  updateSubscribers = (except = null) => {
    for (let i = 0; i < this.authSubscribers.length; i++) {
      let sub = this.authSubscribers[i]
      if (sub && sub !== except && sub.reload) sub.reload()
    }
  }

  applyScrollLock = (locked = false) => {
    // let ele = document.getElementById('root');
    if (locked) {
      document.body.className = 'no-scroll'
      // ele.className = 'no-scroll';
      window.iNoBounce.enable()
    } else {
      document.body.className = ''
      // ele.className = '';
      window.iNoBounce.disable()
    }
  }

  setParams(page, newParams) {
    let params = this.state.params

    let index = params.findIndex((p) => p.page === page)
    if (index !== -1) {
      params[index] = {
        page: page,
        params: newParams
      }
    } else {
      params.push({
        page: page,
        params: newParams
      })
    }

    this.setState({
      params: params
    })
  }

  getParams(page) {
    let params = this.state.params

    let index = params.findIndex((p) => p.page === page)
    if (index !== -1) {
      let p = params[index]
      params.splice(index, 1)
      this.setState({
        params: params
      })

      return p
    } else {
      return null
    }
  }

  componentDidMount() {
    this.initLoggedIn()
    this.getCountryCode()
  }

  initLoggedIn = () => {
    this.getToken()
    window.setTimeout(() => {
      if (this.state.loggedIn) {
        let id = this.state.user ? this.state.user.id : null

        fetch(STRAPI_URL + this.settingsPath + id, {
          headers: {
            Authorization: 'Bearer ' + this.state.jwt
          }
        })
          .then((res) => res.json())
          .then((data) => {
            this.log(data)
            if (data && data.id) {
              this.setLoggedIn(LOGGED_IN)
            } else {
              this.setLoggedIn(LOGGED_OUT)
              this.clearAuth()
            }
          })
          .catch((err) => this.log('error' + err))
      }
    }, 1000)
  }

  toggleShow = (show = false, page = LOGIN_PAGE) => {
    this.setState({
      show: show
    })
    this.applyScrollLock(show)
    if (show === true) {
      this.setState({
        page: page
      })
    }
    this.log('Toggling Auth Modal: ' + this.state.show)
  }

  updateAuthPage = (page = LOGIN_PAGE) => {
    // if there is a auth requerst inprogress
    // dont allow changing of auth pages till
    // the request has completed
    if (this.state.loading) return

    this.setState({
      page: page
    })
  }

  setAuthResponse(res) {
    this.setState({
      jwt: res['jwt'],
      user: res['user']
    })
    this.setStore()
  }

  logout() {
    this.clearAuth()
    this.updateSubscribers()
    this.setLoggedIn(LOGGED_OUT)
    this.log('Logging out...')
    this.redirectOnLogout()
  }

  redirectOnLogout(path = '') {
    for (let i = 0; i < AUTH_URL_PATHS.length; i++) {
      if (window.location.pathname.includes(AUTH_URL_PATHS[i])) {
        window.location.replace(window.location.origin + '/' + path)
        return
      }
    }

    this.toggleShow(true, LOGOUT_PAGE)
  }

  clearAuth() {
    this.setState({
      user: null,
      jwt: null
    })
    storage.clear()
  }

  getTokenRequest = (prompt = false) => {
    let toggleShow = this.toggleShow
    return new Promise(function (resolve, reject) {
      // do a thing, possibly async, then…

      let token = storage.getItem(JWT_STORE_KEY)

      if (token && token !== '') {
        resolve(token)
      } else {
        if (prompt) {
          toggleShow(true, LOGIN_PAGE)
        }
        reject(Error('Token not found.'))
      }
    })
  }

  setToken(jwt) {
    this.setState({
      jwt: jwt
    })
    storage.setItem(JWT_STORE_KEY, jwt)
    this.log('Storing_JWT: ' + jwt)
  }

  setUser(user) {
    this.setState({
      user: user
    })
    storage.setItem(USER_STORE_KEY, JSON.stringify(user))
  }

  getUser() {
    // return this.state.user;
    return JSON.parse(storage.getItem(USER_STORE_KEY))
  }

  getToken = () => {
    this.setState({
      jwt: storage.getItem(JWT_STORE_KEY)
    })

    try {
      let user = storage.getItem(USER_STORE_KEY)
      if (user && user !== '') {
        this.setState({
          user: JSON.parse(user)
        })
        this.setLoggedIn(LOGGED_IN)
      }
    } catch {
      // error parsing user data
      this.setLoggedIn(LOGGED_OUT)
      this.clearAuth()
    }
  }

  log(value) {
    if (this.state.debug) {
      console.log(this.debugId)
      console.log(value)
    }
  }

  setLoading(isLoading) {
    this.setState({
      loading: isLoading
    })
  }

  setLoggedIn(status) {
    this.setState({
      loggedIn: status
    })
  }

  loginRequest(loginObj, cb) {
    this.setLoading(IS_LOADING)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginObj)
    }
    fetch(STRAPI_URL + this.loginPath, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.responseHandler(data, cb)
        this.log('SUCCESS')
        this.log(data)
      })
      .catch((error) => {
        this.log(error)
      })
      .finally(() => this.setLoading(LOADED))
  }

  registerRequest(registerObj, cb) {
    this.setLoading(IS_LOADING)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(registerObj)
    }
    fetch(STRAPI_URL + this.registerPath, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.responseHandler(data, cb)
        this.log('SUCCESS')
        this.log(data)
      })
      .catch((error) => {
        this.log(error)
      })
      .finally(() => this.setLoading(LOADED))
  }

  forgotPasswordRequest(forgotPasswordObj, cb) {
    this.setLoading(IS_LOADING)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(forgotPasswordObj)
    }
    fetch(STRAPI_URL + this.forgotPasswordPath, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.responseHandler(data, cb)
        this.log('SUCCESS')
        this.log(data)
      })
      .catch((error) => {
        this.log(error)
      })
      .finally(() => this.setLoading(LOADED))
  }

  resetPasswordRequest(resetPasswordObj, cb) {
    this.setLoading(IS_LOADING)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(resetPasswordObj)
    }
    fetch(STRAPI_URL + this.resetPasswordPath, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.responseHandler(data, cb)
        this.log('SUCCESS')
        this.log(data)
      })
      .catch((error) => {
        this.log(error)
      })
      .finally(() => this.setLoading(LOADED))
  }

  responseHandler(res, cb) {
    if (this.state.page === LOGIN_PAGE || this.state.page === FORGOT_PASSWORD_CONFIRM_PAGE) {
      let jwt = res['jwt']
      let user = res['user']
      if (user && jwt && jwt !== '') {
        // response is valid
        this.log('RESPONSE IS VALID')
        this.setToken(jwt)
        this.setUser(user)
        this.setLoggedIn(LOGGED_IN)
        this.updateSubscribers()
        cb.onRequestSuccess(res)
      } else {
        // response failed
        this.log('RESPONSE IS INVALID!!!!!')
        cb.onRequestFailed(res)
      }
      return
    }

    if (this.state.page === FORGOT_PASSWORD_CONFIRM_PAGE) {
      let jwt = res['jwt']
      let user = res['user']
      if (user && jwt && jwt !== '') {
        // response is valid
        this.log('RESPONSE IS VALID')
        this.setToken(jwt)
        this.setUser(user)
        this.setLoggedIn(LOGGED_IN)
        cb.onRequestSuccess(res)
      } else {
        // response failed
        this.log('RESPONSE IS INVALID!!!!!')
        cb.onRequestFailed(res)
      }
      return
    }

    if (this.state.page === REGISTER_PAGE) {
      let jwt = res['jwt']
      let user = res['user']
      if (user && jwt && jwt !== '') {
        // response is valid
        this.log('RESPONSE IS VALID')
        cb.onRequestSuccess(res)
      } else {
        // response failed
        this.log('RESPONSE IS INVALID!!!!!')
        cb.onRequestFailed(res)
      }
      return
    }

    if (this.state.page === FORGOT_PASSWORD_PAGE || this.state.page === FORGOT_PASSWORD_CONFIRM_PAGE) {
      if (res.ok && res.ok === true) {
        // response is valid
        this.log('RESPONSE IS VALID')
        cb.onRequestSuccess(res)
      } else {
        // response failed
        this.log('RESPONSE IS INVALID!!!!!')
        cb.onRequestFailed(res)
      }
      return
    }
  }

  render() {
    const listings = <ListingsCurrentPage />
    const listingsAu = <ListingsCurrentAuPage />
    const Home = () => {
      switch (this.state.country) {
        case AU:
          return <Redirect to='/au' />
        case NZ:
          return <HomePage />
        default:
          return <HomePage />
      }
    }
    const navSearch = <NavSearch authCtx={this} listings={listings} />

    return (
      <AuthContext.Provider value={{ auth: this, search: navSearch, country: this.state.country }}>
        <Router>
          <Navbar navSearch={navSearch} />
          <AuthModal />

          <Switch>
            <Route path='/account'>
              <UserAccountPage />
            </Route>

            <Route path='/watchlist'>
              <WatchlistPage />
            </Route>

            <Route path='/neighbourhoodguide'>
              <NeighbourhoodPage />
            </Route>

            <Route path='/sold'>
              <ListingsSoldPage />
            </Route>

            {/* <Route path='/comingsoon'>
                            <ListingsComingSoonPage />
                        </Route> */}

            <Route path='/listings'>{listings}</Route>

            <Route path='/au/listings'>{listingsAu}</Route>

            <Route path='/listing'>
              <SelectedListingPage />
            </Route>

            <Route path='/contactus'>
              <ContactUsPage />
            </Route>

            <Route path='/our-story'>
              <AboutUsPage />
            </Route>


            <Route path='/careers'>
              <Careers />
            </Route>

            <Route path='/property-sales-team'>
              <SalesTeam />
            </Route>

            <Route path='/partners'>
              <PartnersPage />
            </Route>

            <Route path='/au'>
              <HomeAustralia />
            </Route>

            <Route path='/executive-news'>
              <BlogPage />
            </Route>

            <Route path='/post'>
              <SelectedBlogPage />
            </Route>

            <Route path='/'>
              <Home />
            </Route>
          </Switch>

          <Footer />
        </Router>
      </AuthContext.Provider>
    )
  }
}
