import React from 'react';
import { MDBAnimation, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBBtn, MDBView, MDBMask } from "mdbreact";
import Contact from '../ContactComponents/ContactUs'
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { GA_ID } from '../../Constants';


class NeighbourhoodPage extends React.Component {

    componentDidMount() {
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/neighbourhoodguide');
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("get-started");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }

    render() {

        return (
            <>
                <Helmet>
                    {/*Primary meta tags */}
                    <title>Neighbourhood Guide | Executive Real Estate</title>
                    <meta name="description" content="A neighbourhood guide to the best established suburbs Christchurch has to offer." />
                    <meta name="author" content="Neighbourhood Guide | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Neighbourhood Guide | Executive Real Estate" />
                    <meta property="og:description" content="A neighbourhood guide to the best established suburbs Christchurch has to offer." />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="title:title" content="Neighbourhood Guide | Executive Real Estate" />
                    <meta property="twitter:description" content="A neighbourhood guide to the best established suburbs Christchurch has to offer." />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />
    
                </Helmet>
                <div className="background-darker overflow-hidden">
                    <div className="bg-neighbourhood page-header dark-filter clear-filter header-filter">
                        <div className="container">
                            <div style={{ height: '100vh' }} className="centered1">
                                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                                    <div className="title">
                                        <MDBAnimation reveal type="slideInLeft" className="fast">
                                            <h1 className="heading-h1-lg heading-h1-sm">Your Neighbourhood Guide</h1>
                                        </MDBAnimation>
                                        <MDBAnimation reveal type="slideInRight" className="fast">
                                            <h3 className="heading-h3-lg heading-h3-sm">We build luxury homes in the heart of <b className="text-gold">established</b> suburbs with great local food, parks, schools and more.</h3>
                                        </MDBAnimation>
                                        <div className="mt-4" onClick={() => this.scrollToGetStarted()}>
                                            <MDBBtn outline color="primary">Discover</MDBBtn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="get-started">
                        <div className="bg-neighbourhood-social spacer-top-scroll">
                            <div className="divider-margin">
                                <div className="text-center">
                                    <MDBContainer>
                                        <MDBAnimation reveal type="fadeIn" className="slower text-center">
                                            <h1 className="text-center mb-4 heading-h1-lg pt-4 heading-h1-sm">Eat.&nbsp;Play.&nbsp;Live.</h1>
                                        </MDBAnimation>
                                        <hr className="white text-left accent-2 mt-4 d-inline-block mx-auto" style={{ width: "100%", height: "2px" }} />
                                    </MDBContainer>
                                    <div className="divider-margin"><MDBIcon className="text-gold" icon="map-marker-alt" size="2x" /></div>
                                    <MDBRow className="pt-4 mx-auto">
                                        <MDBCol md="2" className="mx-auto">
                                            <h3 className="heading-h3-lg heading-h3-sm">Merivale</h3>
                                            <hr className="white text-left accent-2 mt-4 d-inline-block mx-auto bg-gold" style={{ width: "10%", height: "2px" }} />
                                        </MDBCol>
                                        <MDBCol md="2" className="mx-auto">
                                            <h3 className="heading-h3-lg heading-h3-sm">Saint Albans</h3>
                                            <hr className="white text-left accent-2 mt-4 d-inline-block mx-auto bg-gold" style={{ width: "10%", height: "2px" }} />

                                        </MDBCol>
                                        <MDBCol md="2" className="mx-auto">
                                            <h3 className="heading-h3-lg heading-h3-sm">Fendalton</h3>
                                            <hr className="white text-left accent-2 mt-4 mb-4 d-inline-block mx-auto bg-gold" style={{ width: "10%", height: "2px" }} />
                                        </MDBCol>
                                        <MDBCol md="2" className="mx-auto">
                                            <h3 className="heading-h3-lg heading-h3-sm">Strowan</h3>
                                            <hr className="white text-left accent-2 mt-4 mb-4 d-inline-block mx-auto bg-gold" style={{ width: "10%", height: "2px" }} />
                                        </MDBCol>
                                        <MDBCol md="2" className="mx-auto">
                                            <h3 className="heading-h3-lg heading-h3-sm">Brisbane</h3>
                                            <hr className="white text-left accent-2 mt-4 mb-4 d-inline-block mx-auto bg-gold" style={{ width: "10%", height: "2px" }} />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBContainer>
                                        <div className="pt-4 pb-4">
                                            <p>

                                            </p>
                                        </div>
                                        <br />
                                    </MDBContainer>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="background-darker">
                        <div className="">
                            <MDBRow className="no-padding-margin">

                                <MDBCol sm="12" md="6" lg="4" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/restaurants.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Restaurants</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="http://chillingworthroad.co.nz/" alt="Chillingworth Road Link" rel="noopener noreferrer">Chillingworth Road</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.tripadvisor.co.nz/Restaurant_Review-g255118-d2427072-Reviews-Leinster_Rd_Bistro-Christchurch_Canterbury_Region_South_Island.html" rel="noopener noreferrer" target="_blank" alt="Leinster Rd Bistro Link">Leinster Rd Bistro</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.tripadvisor.co.nz/Restaurant_Review-g255118-d12653385-Reviews-Inati-Christchurch_Canterbury_Region_South_Island.html" rel="noopener noreferrer" target="_blank" alt="Inati">Inati</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.amazonita.co.nz/" target="_blank" rel="noopener noreferrer" alt="Amazonita Link">Amazonita</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://riverside.nz/" target="_blank" rel="noopener noreferrer" alt="Riverside Market Link">Riverside Market</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/school.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Schools</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://www.cbhs.school.nz/" target="_blank" rel="noopener noreferrer" alt="Christchurch Boys High Link">Christchurch Boys' High</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://cghs.school.nz/" target="_blank" rel="noopener noreferrer" alt="Christchurch Girls High Link">Christchurch Girls' High</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://selwynhouse.school.nz/" target="_blank" rel="noopener noreferrer" alt="Selwyn House Link">Selwyn House</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://www.rangiruru.school.nz/" target="_blank" rel="noopener noreferrer" alt="Rangi Ruru Link">Rangi Ruru Girls'</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://www.stac.school.nz/" target="_blank" rel="noopener noreferrer" alt="STAC Link">St Andrews' College</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://christscollege.com/" target="_blank" rel="noopener noreferrer" alt="Christ College Link">Christ's College</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://www.stmargarets.school.nz/" target="_blank" rel="noopener noreferrer" alt="Saint Margaret's College Link">St Margaret's College</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>
                                <MDBCol sm="12" md="6" lg="4" className="no-padding-margin container">
                                    <img
                                        src={require("../../assets/img/kids-bridge.png")}
                                        className="img-fluid img-lighter"
                                        alt=""
                                    />
                                    <div className="centered text-uppercase"><h1><b className="text-gold heading-h3-lg heading-h3-sm"> Explore</b></h1>
                                        <h2 className="text-right text-uppercase">the Area</h2>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/shopping.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Shopping</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://merivalemall.co.nz/" target="_blank" rel="noopener noreferrer" alt="Merivale Mall Link">Merivale Mall</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://findchch.com/places/1573-fendalton-village-shopping-centre" target="_blank" rel="noopener noreferrer" alt="Fendalton Village Link">Fendalton Village Shopping Centre</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.westfield.co.nz/riccarton" target="_blank" rel="noopener noreferrer" alt="Westfield Riccarton Link">Westfield Riccarton</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://thecrossing.co.nz/" target="_blank" rel="noopener noreferrer" alt="The Crossing Link">The Crossing</a></h3>
                                            </div>                                    </MDBMask>
                                    </MDBView>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="4" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/coffeeshop.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Caf&eacute;s & Bars</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://no4bar.co.nz/" target="_blank" rel="noopener noreferrer" alt="No 4 Bar Link">No. 4 Bar and Restaurant</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.meshino.co.nz/" target="_blank" rel="noopener noreferrer" alt="Meshino Link">Meshino</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.aikmans.nz/" target="_blank" rel="noopener noreferrer" alt="Aikmans Link">Aikmans</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.facebook.com/Room205chch/" rel="noopener noreferrer" target="_blank" alt="Room 205 Coffee House Link">Room 205 Coffee House</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="http://www.thepreservatory.co.nz/" rel="noopener noreferrer" target="_blank" alt="The Preservatory Link">The Preservatory</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>
                                <MDBCol sm="12" md="6" lg="4" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/playsand.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Parks</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                {/* <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://ccc.govt.nz/parks-and-gardens/christchurch-botanic-gardens/visit" target="_blank" alt="Christchurch Botanic Gardens Link">Botanic Gardens</a></h3> */}
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://ccc.govt.nz/parks-and-gardens/explore-parks/hagley-park-and-gardens" target="_blank" rel="noopener noreferrer" alt="Christchurch Hagley Park Link">Hagley Park</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.christchurchnz.info/business/margaret-mahy-playground" target="_blank" rel="noopener noreferrer" alt="Margaret Mahy Playground Link">Margaret Mahy Playground</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>
                            </MDBRow>
                        </div>

                    </div>
                    <Contact />
                </div>
            </>
        )
    }
};

export default NeighbourhoodPage;