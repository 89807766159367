import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import ContactUsForm from './ContactUsForm';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../AuthContext/AuthContext'
import { NZ } from '../../Constants'

class ContactUs extends Component {
  render() {
    const country = this.context.country
    return (
      <div className='background-darker' id='contact'>
        <div id='map-container' className='rounded z-depth-1-half map-container bg-30'>
          {country === NZ ? (
            <iframe
              className='map'
              title='Executive Real Estate Christchurch Map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5786.610579976736!2d172.61080013115367!3d-43.51682608624467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a526353497f%3A0x500ef868479c850!2sMerivale%2C%20Christchurch%208014!5e0!3m2!1sen!2snz!4v1600770784131!5m2!1sen!2snz'
              width='100%'
              height='100%'
              frameBorder='0'
              style={{ border: '0' }}
              allowFullScreen=''
              aria-hidden='false'
              tabIndex='0'
            ></iframe>
          ) : (
            <iframe
              className='map'
              title='Executive Real Estate Brisbane Map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d453481.3003936522!2d152.7130097296629!3d-27.381253323551135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91579aac93d233%3A0x402a35af3deaf40!2sBrisbane%20QLD%2C%20Australia!5e0!3m2!1sen!2snz!4v1630642550296!5m2!1sen!2snz'
              width='100%'
              height='100%'
              frameBorder='0'
              style={{ border: '0' }}
              allowFullScreen=''
              aria-hidden='false'
              tabIndex='0'
            ></iframe>
          )}
        </div>
        <MDBContainer className='mt-4 divider-margin'>
          <MDBAnimation reveal type='fadeIn' className='slow'>
            <MDBCol>
              <h1 className='heading-margin h1-responsive heading-margin text-center pt-4'>Have a Question? We have Answers.</h1>
              <h4 className='text-center h4-responsive mx-auto'>
                Please contact our sales team with any questions about selling your home, or the buying or building process with Executive Real Estate
              </h4>
              <MDBRow>
                <MDBCol md='4' className='mx-auto'>
                  <p>
                    <i className='fas fa-phone fa-lg mt-4 mb-2 text-gold mr-2'></i><a className="white-text" href="tel:0800373000">0800 373 000 (0800 ERE 000)</a>
                  </p>
                </MDBCol>
                <MDBCol md='4' className='mx-auto'>
                  <p>
                    <i className='fas fa-envelope mt-4 fa-lg mb-2 text-gold mr-2'></i>
                    <a className='white-text' href='mailto:info@ere.nz'>
                      info@ere.nz
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBAnimation>
        </MDBContainer>
        <ContactUsForm email={this.props.email} />
      </div>
    )
  }
}

ContactUs.contextType = AuthContext
export default ContactUs