import React from "react";
import { primaryColorBorder } from "../../../../Constants";
import { MDBIcon, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import "./PremiumCarouselCard.css";
import WatchlistButton from '../../../WatchlistComponent/WatchlistButton';
// import ReactHtmlParser from 'react-html-parser';
import { LISTING_STATUS_SOLD, LISTING_STATUS_CURRENT } from '../../../WatchlistComponent/WatchlistPage';

export default function PremiumCarouselCard(props) {
  const watchlistBtn = (<WatchlistButton id={props.id} watchListed={props.watchListed} updateListing={props.updateListing} />);
  const status = props.Display_as_Sold ? LISTING_STATUS_SOLD : LISTING_STATUS_CURRENT;
  const url = window.location.pathname + `?status=${status}&listing=${props.id}`;
  // const openhomes = openHomeDateConverterMinimal(props.OpenHome);

  return (

    <div className="pcc-container" style={{ height: props.height + "px" }}>

      <MDBRow>
        <MDBCol>
          {/* {
            openhomes && openhomes.length > 0 ?
              (
                <div className="text-left open-home-card pl-3"><MDBIcon icon="home" className="white-text mr-lg-3 mr-2" />Next open Home {openhomes[0]}</div>
              ) : null
          } */}
          <div className={props.isVertical ? "pcc-horizontal-container" : null}>
            <div className="pcc-title" style={{ borderBottom: primaryColorBorder }}>
              {props.Title}
            </div>
          </div>

        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol sm="12" md="6" lg="6">

          <div className={props.isVertical ? "pcc-horizontal-container" : null}>
            <div className="pcc-body">
              <div className="lc-address-text">{props.Address}, {props.suburb.Name}, {props.city.Name}</div>

              {props.developer !== null ? <div className="pc-saletype-text mb-2">Developed by {props.developer.Name}</div> : null}

            </div>
            {/* Ammenities Start */}
            <div className="text-left">
              <hr className={!props.isVertical ? "display-none" : "grey accent-2 mb-2 d-inline-block mx-auto"} style={{ width: "100%" }} />
              <MDBRow>
                <MDBCol sm="12" className="pt-1">
                  {props.Bedrooms > 0 ? (
                    <span className="lc-ammenity">
                      {props.Bedrooms} <MDBIcon className="lc-ammenity-icon mr-3" icon="bed" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.Bathrooms > 0 ? (
                    <span className="lc-ammenity">
                      {props.Bathrooms} <MDBIcon className="lc-ammenity-icon" icon="bath" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.Living > 0 ? (
                    <span className="lc-ammenity">
                      {props.Living} <MDBIcon className="lc-ammenity-icon" icon="couch" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.Kitchen > 0 ? (
                    <span className="lc-ammenity">
                      {props.Kitchen} <MDBIcon className="lc-ammenity-icon" icon="utensils" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.Office > 0 ? (
                    <span className="lc-ammenity">
                      {props.Office}  <MDBIcon className="lc-ammenity-icon" icon="desktop" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.Garage > 0 ? (
                    <span className="lc-ammenity">
                      {props.Garage} <MDBIcon className="lc-ammenity-icon" icon="car" />
                    </span>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
            </div>
          </div>
        </MDBCol>
        <MDBCol sm="12" md="6" lg="6">
          {/* <div className="pcc-ammenities-container text-center"> */}
            <div style={{ padding: '0px', textAlign: (window.innerWidth <= 768 ? "left" : "right"), marginTop: "15px" }}>
              <MDBRow>
                <MDBCol md="10" className="float-right">
                  <MDBBtn color="primary" className="w-100" href={url} onClick={(e) => {
                    props.viewListing(e, this.props.id, status, true)
                  }}>
                    view
                  </MDBBtn>
                </MDBCol>
                <MDBCol md="2" className="float-right">
                  {props.Display_as_Sold && !props.watchListed ? null : watchlistBtn}
                </MDBCol>
              </MDBRow>

              {/* <a className="lc-view-btn gtm-event-button" href={url} color="white" onClick={(e) => props.viewListing(e, props.id, status, true)}>
                <i className="far fa-eye"></i>
                <span className="margin-left-5">View</span>
              </a>
              {props.Display_as_Sold ? null : watchlistBtn} */}
            </div>
          {/* </div> */}
        </MDBCol>
      </MDBRow>
      {/* <div  style={{ borderTop: primaryColorBorder, marginTop: "10px", marginBottom: "10px"}}></div> */}
    </div>















  );
}
