import React, { Component } from 'react';
import '../../Components/CarouselComponents/CardCarouselComponent/CardCarousel.css';
import Slider from "react-slick";
import ListingCard from '../CarouselComponents/CardCarouselComponent/ListingCardComponent/ListingCard';
import { IS_LOADING, LOADED } from '../../Constants';
import { MDBIcon } from 'mdbreact';
import TagManager from 'react-gtm-module';


export const CARD_STYLE_VERTICAL = 'vertical';
export const CARD_STYLE_HORIZONTAL = 'horizontal';

const tagManagerArgs = {
    gtmId: 'GTM-WT6SLQB',
    dataLayer: {
        pageUrl:""
    }

}

export default class DeveloperCarousel extends Component {

    constructor(props) {
        super(props);

        const cSettings = {
            dots: false,
            infinite: false,
            autoplay: false,
            autoplaySpeed: 4000,
            slidesToShow: 3,
            slidesToScroll: 1,
            // rtl: props.reversed,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        };

        this.state = {
            data: [],
            loading: IS_LOADING,
            error: false,
            settings: cSettings,
            reversed: props.reversed,
            title: props.title,
            cardStyle: props.cardStyle,
            padding: 40,
            slidesShowing: 3
        };
    }

    componentDidMount() {
        TagManager.dataLayer(tagManagerArgs);
        this.props.authCtx.addSubscriber(this);
        window.addEventListener('resize', () => this.updateCarouselPadding());
        this.updateCarouselPadding();
        this.reload();
    }

    componentWillUnmount() {
        this.props.authCtx.removeSubscriber(this);
        window.removeEventListener('resize', () => this.updateCarouselPadding());
    }

    updateCarouselPadding = () => {
        let width = window.innerWidth;
        if (width <= 500) {
            this.setState({
                padding: 0
            });
        } else if (width <= 950) {
            this.setState({
                padding: 20
            });
        } else {
            this.setState({
                padding: 40
            });
        }

        if (width <= 950) {
            this.setState({
                slidesShowing: 1
            });
        } else if (width <= 1400) {
            this.setState({
                slidesShowing: 2
            });
        } else if (width <= 1800) {
            this.setState({
                slidesShowing: 2
            });
        } else {
            this.setState({
                slidesShowing: 2
            });
        }
    }

    reload = () => {
        this.props.requestor.fetch(this, this.props.skip, this.props.take);
    }

    fetchSuccess = (data) => {
        this.setState({
            data: data,
            loading: LOADED
        });
    }

    fetchFailed = (error) => {
        this.setState({
            loading: LOADED,
            error: true
        });
    }

    updateListingData = (watchlistItem, watchListed) => {
        if (watchlistItem && watchlistItem.listing) {
            let listings = this.state.data.slice();
            let index = listings.findIndex(l => l.id === watchlistItem.listing.id);
            if (index !== -1) {
                listings[index].watchListed = watchListed;
                this.setState({
                    data: listings
                });
                this.props.authCtx.updateSubscribers(this);
            }
        }
    }

    buildCarouselCards() {
        const cardStyle = (this.state.cardStyle === CARD_STYLE_VERTICAL ? true : false);
        // const cards = this.state.data.map((l, i) => <ListingCard key={l.id} {...l} viewListing={this.props.viewListing} updateListing={this.updateListingData} isVertical={cardStyle} />);
        const cards = this.state.data.filter(c => c.id !== this.props.currentSeletionId).map((l,i) =><ListingCard key={l.id} {...l} viewListing={this.props.viewListing} updateListing={this.updateListingData} isVertical={cardStyle} />);
        return cards;
    }

    buildCardCarousel() {
        // console.log(this.state.data.id)
        if (this.state.data != null && this.state.data.length !== 0 && this.state.data.length > 0) {
            const cards = this.buildCarouselCards();
            return (
                <div style={{ padding: this.state.padding + 'px' }}>
                    <div className={this.state.reversed ? "cc-header-container-reversed" : "cc-header-container-normal"}>
                        <div className={this.state.reversed ? "cc-reversed-title" : "cc-normal-title"} style={{
                            minWidth:"104%"    
                        }} >
                            {this.state.title}
                        </div>
                    </div>
                    <div className="cc-container">
                        <Slider {...this.state.settings} prevArrow={<LeftNavButton padding={this.state.padding} />} nextArrow={<RightNavButton padding={this.state.padding} slidesShowing={(this.state.slidesShowing)} end={this.state.data.length} />}>
                            {cards}
                        </Slider>
                    </div>
                </div>
            );
        } else {
            return (
                null
            );
        }
    }

    render() {
        // console.log(this.state.data.length)
        if(this.state.data.length !== 1){
            const carousel = this.buildCardCarousel();
            return carousel;
        }else{
            return(
                <>
                </>
            )
          
        }

  
    }
}

function LeftNavButton(props) {
    return <button className="slick-prev" onClick={props.onClick} style={{ color: (props.currentSlide === 0 ? 'rgb(0,0,0)' : '#cda177'), left: '-' + props.padding + 'px' }}><MDBIcon icon="caret-left" /></button>
}

function RightNavButton(props) {
    return <button className="slick-next" onClick={props.onClick} style={{ color: ((props.currentSlide + props.slidesShowing) === props.end ? 'rgb(0,0,0)' : '#cda177'), right: '-' + props.padding + 'px' }}><MDBIcon icon="caret-right" /></button>
}