import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import React from "react";
import { priceRange } from './PriceRange';

class SearchPriceRange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  getPriceName(priceType) {
    var priceRangeArray = priceRange
    var priceRangeResult = priceRangeArray.filter(value => value.value === priceType);
    var priceRangeName = priceRangeResult.map(item => { return item.name })
    return priceRangeName[0];
  }
  priceRangeItem() {
    var priceRangeArray = priceRange
    var nameMin = this.getPriceName(this.props.selectMinPrice);
    var nameMax = this.getPriceName(this.props.selectMaxPrice);
    return (
      <>
        <MDBDropdown size="sm" >
          <MDBDropdownToggle caret color="primary">

            {!this.props.selectMinPrice || this.props.selectMinPrice === '' ? 'Select Min Price' : nameMin}
          </MDBDropdownToggle>
          <MDBDropdownMenu basic style={{ overflowY: 'scroll', maxHeight: '14rem' }}>
            <MDBDropdownItem className="dropdown-link" onClick={() => this.props.minPriceSelected('')}>All</MDBDropdownItem>
            {
              priceRangeArray.map((item) => (<MDBDropdownItem className="dropdown-link" onClick={() => this.props.minPriceSelected(item.value)} key={item.value}>{item.name}</MDBDropdownItem>))
            }
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBDropdown size="sm" >
          <MDBDropdownToggle caret color="primary">
            {!this.props.selectMaxPrice || this.props.selectMaxPrice === '' ? 'Select Max Price' : nameMax}
          </MDBDropdownToggle>
          <MDBDropdownMenu basic style={{ overflowY: 'scroll', maxHeight: '14rem' }}>
            <MDBDropdownItem className="dropdown-link" onClick={() => this.props.maxPriceSelected('')}>All</MDBDropdownItem>
            {
              priceRangeArray.map((item) => (<MDBDropdownItem className="dropdown-link" onClick={() => this.props.maxPriceSelected(item.value)} key={item.value}>{item.name}</MDBDropdownItem>))
            }
          </MDBDropdownMenu>
        </MDBDropdown>

      </>
    )
  }
  render() {
    const dropDown = this.priceRangeItem();
    return dropDown;
  }
} export default SearchPriceRange;

