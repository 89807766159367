export const PAGE_LISTINGS = 'page_listings';
export const PAGE_LISTING = 'page_listing';

export class NavParams {
    params = [];

    setParams(page, params) {
        let index = this.params.findIndex(p => p.page === page);
        if (index === -1) {
            params[index] = {
                page: page,
                params: params
            };
        } else {
            params.push({
                page: page,
                params: params
            });
        }
    }

    getParams(page) {
        let index = this.params.findIndex(p => p.page === page);
        if (index !== -1) {
            let params = this.params[index];
            this.params.splice(index, 1);
            return params;
        } else {
            return null;
        }
    }
}