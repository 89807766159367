import React from 'react';
import './style.css';
import { MDBRow, MDBCol } from 'mdbreact';

export default function NoResult(props) {
    const searchIcon = require('../../assets/icons/search_icon.png');
    const textAppend = props.searchText ? ' Containing' : '';

    return (
        <div className="no-result-container">
            <MDBRow>
                <MDBCol>
                    <img className="no-result-icon" src={searchIcon} alt="Seach binocular icon" />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <div className="no-result-text">{props.text + textAppend}</div>
                    {
                        props.searchText ? (
                            <div className="no-result-text">"{props.searchText}"</div>
                        ) : null
                    }
                </MDBCol>
            </MDBRow>
        </div>
    );
}