import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PAGE_LISTINGS } from "../../Data/NavParams";
import './style.css';

const LISTINGS_PATH = '/listings';

const NavSearch = (props) => {
  const [search, setSearch] = useState('');
  const history = useHistory();

  window.resetNavSearch = () => {
    setSearch('');
  }

  function userSearch(e) {
    e.preventDefault();

    if (window.listingsComponent) {
      window.listingsComponent.searchChanged(e, false, search);
    }

    props.authCtx.setParams(PAGE_LISTINGS, {
      search: search
    });

    if (!window.location.pathname.includes(LISTINGS_PATH) || !window.listingsComponent) {
      history.push(LISTINGS_PATH);
    }

  }

  return (
    <>
      <form onSubmit={(e) => { userSearch(e) }}>
        <div className="input-group form-sm form-2 pl-0 search-width">
          <input className="form-control nav-search minheight-50" type="text" placeholder="Search for your new home..." aria-label="Search" onChange={(e) => { setSearch(e.target.value) }} />
          <div className="input-group-append white-text pointer">
            <span className="input-group-text styled-search" id="basic-text1" onClick={(e) => userSearch(e)}
            ><span className="white-text text-uppercase">Search NZ</span></span>
          </div>
        </div>
      </form>
    </>
  );
}

export default NavSearch;