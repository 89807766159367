import React from 'react';
import WatchlistNotFoundButton from './WatchlistNotFoundButton';
import { MDBRow, MDBCol } from 'mdbreact';

function WatchlistListingNotFound(props) {

    return (
        <div className="watchlist-not-found-container">
            <MDBRow>
                <MDBCol sm="12" md="8" lg="8">
                    <div className="watchlist-not-found-msg">
                        Some listings have been removed and will no longer appear on your watchlist.
                </div>
                </MDBCol>
                <MDBCol sm="12" md="4" lg="4">
                    <div className="watchlist-not-found-btn-container">
                        <WatchlistNotFoundButton removeNotFound={props.removeNotFound} watchlistItems={props.watchlistItems} />
                    </div>
                </MDBCol>
            </MDBRow>
        </div>
    )
}

export default WatchlistListingNotFound;