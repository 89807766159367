import React, { useState } from 'react';
import Spinner from '../SpinnerComponent/Spinner';

function WatchlistNotFoundButton(props) {
    const [loading, setLoading] = useState(false);

    const removeWatchlistItems = (watchlistItems) => {
        setLoading(true);
        props.removeNotFound();
    }

    return (
        <button
            className={"lc-view-btn"}
            style={{ width: '180px', marginTop: '0px' }}
            disabled={loading}
            onClick={() => removeWatchlistItems()}
        >
            {
                loading ? <Spinner loading={loading} text={''} /> :
                    <>
                        {/* <i className={'fas ' + (props.watchListed ? 'fa-minus-circle' : 'fa-plus-circle')}></i> */}
                        <span className="margin-left-5">
                            OK
                        </span>
                    </>
            }
        </button>
    );
}

export default WatchlistNotFoundButton;