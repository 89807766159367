import { STRAPI_URL, GET, POST, PUT, DELETE, PROMPT_LOGIN } from '../AuthContext/AuthHandler';

export const WATCHLIST_ENTITY = '/watchlists';

export const GET_WATCHLIST = 'get_watchlists';
export const GET_WATCHLIST_REQUEST_QUERY = '?_sort=created_at:DESC';

export const ADD_WATCHLIST = 'add_watchlist';
export const ADD_WATCHLIST_REQUEST_QUERY = '';

export const DELETE_WATCHLIST = 'delete_watchlist';
export const DELETE_WATCHLIST_REQUEST_QUERY = '/';

export default class WatchlistRequest {

    query = '';
    type = '';

    setAuth(ctx) {
        this.authCtx = ctx;
    }

    async getHeaders(meth = GET, data = null) {

        let opts = {
            method: meth,
            headers: {}
        };

        await this.authCtx.getTokenRequest(PROMPT_LOGIN)
            .then((jwt) => {
                opts.headers = {
                    'authorization': 'Bearer ' + jwt
                };
            }).catch((err) => {
            })

        if (data !== null && (meth === PUT || meth === POST)) {
            opts.body = JSON.stringify(data);
        }

        return opts;
    }

    buildUrl(type) {
        if (this.type === GET_WATCHLIST)
            this.query = GET_WATCHLIST_REQUEST_QUERY;

        if (this.type === ADD_WATCHLIST)
            this.query = ADD_WATCHLIST_REQUEST_QUERY;

        if (this.type === DELETE_WATCHLIST)
            this.query = DELETE_WATCHLIST_REQUEST_QUERY;
    }

    // delete -- id
    // add -- { listingId, user }
    // get -- start, limit


    buildParams(params) {
        // append to end of urlPath
        for (let i = 0; i < params.length; i++) {
            let param = params[i];
            this.query += '&' + param[0] + param[1];
        }
    }

    async fetch(callbackObj, params = {}) {
        this.buildUrl(GET_WATCHLIST);
        this.buildParams(params);
        let headers = await this.getHeaders(GET, null);

        let dataUrl = STRAPI_URL + WATCHLIST_ENTITY + this.query;
        // let countUrl = STRAPI_URL + WATCHLIST_ENTITY + this.query;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => { callbackObj.fetchSuccess(data) })
            .catch(error => { callbackObj.fetchFailed(error) });

        // if (callbackObj.fetchCountSuccess && callbackObj.fetchCountFailed) {
        //     fetch(countUrl)
        //         .then(response => response.json())
        //         .then(data => { callbackObj.fetchCountSuccess(data) })
        //         .catch(error => { callbackObj.fetchCountFailed(error) });
        // }
    }

    async post(callbackObj, data) {
        this.buildUrl(ADD_WATCHLIST);
        this.buildParams({});
        let headers = await this.getHeaders(POST, data);

        let dataUrl = STRAPI_URL + WATCHLIST_ENTITY + this.query;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => { callbackObj(data, true) })
            .catch(error => {  });
    }

    async delete(callbackObj, id) {
        this.buildUrl(DELETE_WATCHLIST);
        this.buildParams({ id: id });
        let headers = await this.getHeaders(DELETE, null);

        let dataUrl = STRAPI_URL + WATCHLIST_ENTITY + '/' + id;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => { callbackObj(data, false) })
            .catch(error => {  });

    }
}