import React, { Component } from 'react';
import { MDBModalBody, MDBContainer, MDBModal, MDBIcon, MDBBtn, MDBModalFooter } from 'mdbreact';
import { AuthContext } from '../../AuthContext/AuthContext';
import '../AuthComponent/style.css';
import {
    LOGIN_PAGE, LOGOUT_PAGE, REGISTER_PAGE, CONFIRM_EMAIL_PAGE,
    FORGOT_PASSWORD_PAGE, FORGOT_PASSWORD_CONFIRM_PAGE
} from '../../AuthContext/AuthHandler';
import Spinner from '../SpinnerComponent/Spinner';

class AuthModal extends Component {

    authCtx = {};

    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
            page: LOGIN_PAGE,
            complete: false,
            code: '',
            url: '',
            value: {},
            error: '',
            authCtx: null
        }

        this.setResponseError = this.setResponseError.bind(this);
    }

    componentDidMount() {
        // check if url is email confirmation
        if (window.location.pathname.includes('emailconfirmation')) {
            this.setState({
                error: 'Thank you for confirming your email. You can now login.'
            });
            this.authCtx.toggleShow(true, LOGIN_PAGE);
        } else if (window.location.pathname.includes('passwordreset')) {
            const urlParams = new URLSearchParams(window.location.search);
            const resetCode = urlParams.get('code');
            this.setState({
                code: resetCode
            });
            this.authCtx.toggleShow(true, FORGOT_PASSWORD_CONFIRM_PAGE);
        }
    }

    buildPage(page) {
        switch (page) {
            case LOGIN_PAGE:
                return this.buildLogin();
            case LOGOUT_PAGE:
                return this.buildLogout();
            case REGISTER_PAGE:
                return this.buildRegister();
            case CONFIRM_EMAIL_PAGE:
                return this.buildEmailConfirmation();
            case FORGOT_PASSWORD_PAGE:
                return this.buildForgotPassword();
            case FORGOT_PASSWORD_CONFIRM_PAGE:
                return this.buildForgotPasswordConfirm();
            default:
                return this.buildLogin();
        }
    }

    buildLogin() {
        return (
            <div className="form-container">
                <form id="auth-form" className="needs-validation" onSubmit={this.submitHandler} noValidate>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" name="identifier" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <MDBBtn color="primary" type="submit" className="auth-form-btn" disabled={this.authCtx.state.loading}>
                        <Spinner loading={this.authCtx.state.loading} text={'Login'} />
                    </MDBBtn>
                </form>
            </div>
        )
    }

    buildLogout() {
        return (
            <div className="form-container">
                <div className="logout-confirm-container">
                    <div className="logout-confirm-message">
                        You have successfully logged out..
                    </div>
                </div>
            </div>
        )
    }

    buildRegister() {
        const value = this.state.value.passwordConfirmation;
        const passValidMsg = (!value || value.length === 0) ? 'Required.' : 'Passwords don\'t match.';

        const registerBegin = (
            <div className="form-container">
                <form id="auth-form" className="needs-validation" onSubmit={this.submitHandler} noValidate>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" name="username" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Phone</label>
                        <input type="tel" id="phone" name="phone" pattern="^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input type="password" id="confirm-password" name="passwordConfirmation" onChange={this.changeHandler}
                            className="form-control was-validated form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            {passValidMsg}
                        </div>
                    </div>
                    <MDBBtn color="primary" type="submit" className="auth-form-btn" disabled={this.authCtx.state.loading}>
                        <Spinner loading={this.authCtx.state.loading} text={'Register'} />
                    </MDBBtn>
                </form>
            </div>
        );

        const registerComplete = (
            <div className="form-container">
                <div className="registration-confirm-container">
                    <div className="registration-confirm-message">
                        Thank you!
                    </div>
                    <div className="registration-confirm-message">
                        You have successfully registered. A confirmation email has been sent to you, please click the link in the email to confirm your account.
                    </div>
                </div>
            </div>
        );

        const register = this.state.complete ? registerComplete : registerBegin;
        return register;
    }

    buildForgotPassword() {
        const forgotPasswordBegin = (
            <div className="form-container">
                <form id="auth-form" className="needs-validation" onSubmit={this.submitHandler} noValidate>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <MDBBtn color="primary" type="submit" className="auth-form-btn" disabled={this.authCtx.state.loading}>
                        <Spinner loading={this.authCtx.state.loading} text={'Send Reset'} />
                    </MDBBtn>
                </form>
            </div>
        );

        const forgotPasswordComplete = (
            <div className="form-container">
                <div className="pass-reset-complete-container">
                    <div className="pass-reset-complete-message">
                        A password reset email has been sent to you, please click the link in the email to finish re-setting your password.
                    </div>
                </div>
            </div>
        );

        const forgotPassword = this.state.complete ? forgotPasswordComplete : forgotPasswordBegin;
        return forgotPassword;
    }

    buildForgotPasswordConfirm() {
        const value = this.state.value.passwordConfirmation;
        const passValidMsg = (!value || value.length === 0) ? 'Required.' : 'Passwords don\'t match.';

        const passConfirm = (
            <div className="form-container">
                <form id="auth-form" className="needs-validation" onSubmit={this.submitHandler} noValidate>
                    <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <input type="password" id="password" name="password" onChange={this.changeHandler}
                            className="form-control form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            Required.
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input type="password" id="confirm-password" name="passwordConfirmation" onChange={this.changeHandler}
                            className="form-control was-validated form-control-lg" disabled={this.authCtx.state.loading} required />
                        <div className="invalid-feedback">
                            {passValidMsg}
                        </div>
                    </div>
                    <MDBBtn color="primary" type="submit" className="auth-form-btn" disabled={this.authCtx.state.loading}>
                        <Spinner loading={this.authCtx.state.loading} text={'Update Password'} />
                    </MDBBtn>
                </form>
            </div>
        );

        const passConfirmComplete = (
            <div className="form-container">
                <div className="pass-reset-complete-container">
                    <div className="pass-reset-complete-message">
                        Your password has been reset and you have been logged in.
                    </div>
                </div>
            </div>
        );

        return this.state.complete ? passConfirmComplete : passConfirm;
    }

    buildEmailConfirmation() {
        return (
            <div className="form-container">
                <div className="email-confirm-container">
                    <div className="email-confirm-message">
                        Thank you!<br />
                        Your email has now been confirmed.
                    </div>
                </div>
            </div>
        );
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setResponseError(error) {
        if (error && error.length > 0) {
            error = error.toLowerCase().replace('identifier', 'username');
            error = error[0].toUpperCase() + error.substring(1, error.length);
        }
        this.setState({
            error: error
        })
    }

    onRequestSuccess(response) {
        if (this.authCtx.state.page === LOGIN_PAGE) {
            this.authCtx.toggleShow();
        }

        if (this.authCtx.state.page === REGISTER_PAGE ||
            this.authCtx.state.page === FORGOT_PASSWORD_PAGE ||
            this.authCtx.state.page === FORGOT_PASSWORD_CONFIRM_PAGE) {
            this.setState({
                complete: true
            });
        }
    }

    onRequestFailed(response) {
        try {
            if (this.state.page === FORGOT_PASSWORD_CONFIRM_PAGE) {
                this.setResponseError('Your code seems invalid. Try re-setting password for your account.');
                return;
            }
            if (response['message'] != null) {
                this.setResponseError(response.message[0].messages[0].message);
            }
        } catch {
            // set generic message
            this.setResponseError('Opps, something went wrong. Please try again!');
        }
    }

    submitHandler = event => {
        event.preventDefault();
        this.setResponseError('');
        event.target.className += " was-validated";

        // test form for validity
        let inputs = event.nativeEvent.target;
        for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            if (input != null && !input.validity.valid) {
                return;
            }
        }

        // if we got this far, form must be valid
        // continue to submit
        switch (this.authCtx.state.page) {
            case LOGIN_PAGE:
                this.authCtx.loginRequest(this.state.value, this);
                break;
            case REGISTER_PAGE:
                this.authCtx.registerRequest(this.state.value, this);
                break;
            case FORGOT_PASSWORD_PAGE:
                this.authCtx.forgotPasswordRequest(this.state.value, this);
                break;
            case FORGOT_PASSWORD_CONFIRM_PAGE:
                let resetObj = this.state.value;
                resetObj.code = this.state.code;
                this.setState({
                    value: resetObj
                });
                this.authCtx.resetPasswordRequest(this.state.value, this);
                break;
            default:
                this.authCtx.loginRequest(this.state.value, this);
        }
    };

    toggleShow() {
        if (this.authCtx !== undefined && !this.authCtx.state.loading) {
            this.setResponseError('');
            this.authCtx.toggleShow();
        }
    }

    updatePage(page) {
        this.setState({
            error: '',
            value: {}
        });
        document.getElementById("auth-form").reset();
        document.getElementById("auth-form").classList.remove("was-validated");
        this.authCtx.updateAuthPage(page);
    }

    changeHandler = event => {
        if (event.target.name === 'passwordConfirmation') {
            if (this.state.value.password !== event.target.value) {
                event.nativeEvent.target.setCustomValidity('Passwords don\'t match.');
            } else {
                event.nativeEvent.target.setCustomValidity('');
            }
        }

        let values = this.state.value;
        values[event.target.name] = event.target.value;
        this.setState({
            value: values
        });
    };

    buildLinks = (page) => {
        switch (page) {
            case LOGIN_PAGE:
                return this.buildLoginLinks();
            case REGISTER_PAGE:
                return this.buildRegisterLinks();
            case CONFIRM_EMAIL_PAGE:
                return null;
            case FORGOT_PASSWORD_PAGE:
                return this.buildForgotPasswordLinks();
            case FORGOT_PASSWORD_CONFIRM_PAGE:
                return this.buildForgotPasswordConfirmLinks();
            default:
                return null;
        }
    }

    buildLoginLinks() {
        return (
            <div className="auth-link-container">
                <span onClick={() => this.updatePage(FORGOT_PASSWORD_PAGE)} className="auth-page-link">Forgot Password</span>
            &nbsp;or&nbsp;
                <span onClick={() => this.updatePage(REGISTER_PAGE)} className="auth-page-link">Register</span>
            </div>
        );
    }

    buildForgotPasswordLinks() {
        return (
            <div className="auth-link-container">
                <span onClick={() => this.updatePage(LOGIN_PAGE)} className="auth-page-link">Login</span>
            &nbsp;or&nbsp;
                <span onClick={() => this.updatePage(REGISTER_PAGE)} className="auth-page-link">Register</span>
            </div>
        );
    }

    buildForgotPasswordConfirmLinks() {
        return (
            <div className="auth-link-container">
                <span onClick={() => this.updatePage(FORGOT_PASSWORD_PAGE)} className="auth-page-link">Forgot Password</span>
            </div>
        );
    }

    buildRegisterLinks() {
        return (
            <div className="auth-link-container">
                <span onClick={() => this.updatePage(FORGOT_PASSWORD_PAGE)} className="auth-page-link">Forgot Password</span>
            &nbsp;or&nbsp;
                <span onClick={() => this.updatePage(LOGIN_PAGE)} className="auth-page-link">Login</span>
            </div>
        );
    }

    buildFormHeader(page) {
        return (
            <div className="auth-form-title-container">
                <div className="auth-form-title">{this.buildTitle(page)}</div>
            </div>
        );
    }

    buildTitle(page) {
        switch (page) {
            case LOGIN_PAGE:
                return 'Login';
            case FORGOT_PASSWORD_PAGE:
                return 'Forgot Password';
            case REGISTER_PAGE:
                return 'Register';
            default:
                return 'Login';
        }
    }

    render() {
        this.authCtx = this.context.auth;

        const logo = require('../../assets/logos/auth-logo.png');

        const error = (this.state.error !== '' ? <div className="auth-error-text">{this.state.error}</div> : null);
        const page = this.buildPage(this.authCtx.state.page);
        const links = this.buildLinks(this.authCtx.state.page);
        return (
            <MDBContainer>
                <MDBModal centered isOpen={this.authCtx.state.show} toggle={this.toggleShow}>
                    <MDBModalBody style={{ backgroundColor: 'black', textTransform: 'uppercase', letterSpacing: '0.5px', fontSize: '0.81rem', fontWeight: '400' }}>
                        <MDBIcon icon="times" size="2x" className="auth-close-icon" onClick={() => this.toggleShow()} />
                        <div className="login-logo-container">
                            <img src={logo} alt="Executive Real Estate Logo" className="login-logo-img" />
                        </div>
                        {/* {header} */}
                        {this.state.complete ? null : error}
                        {page}

                        <MDBModalFooter>
                            <div className="text-center mx-auto mt-4">
                                {this.state.complete ? null : links}
                            </div>
                            {/* <MDBBtn outline color='white' size='sm' onClick={() => this.toggleShow()}>Close</MDBBtn> */}
                        </MDBModalFooter>
                    </MDBModalBody>
                </MDBModal>
            </MDBContainer>
        )
    }

}
AuthModal.contextType = AuthContext;
export default AuthModal;