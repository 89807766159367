import { STRAPI_URL, GET, POST, PUT, DONT_PROMPT_LOGIN } from '../AuthContext/AuthHandler';

export const ENTITY_BLOG_POSTS = '/blog-posts';
export const ENTITY_BLOG_POSTS_COUNT = '/blog-posts/count';

export default class DataRequestor {

    getDateStr() {
        let date = new Date();
        let day = this.padDate(date.getDate().toString(), 2);
        let month = this.padDate((date.getMonth() + 1).toString(), 2);
        let year = date.getFullYear();
    
        return (year + '-' + month + '-' + day);
    }
    
    padDate(str, length) {
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }

    async getHeaders(meth = GET, data = null) {

        let opts = {
            method: meth,
            headers: {}
        };

        if (data !== null && (meth === PUT || meth === POST)) {
            opts.body = JSON.stringify(data);
        }

        return opts;
    }

    async fetch(callbackObj, url, method, data = null) {
        let headers = await this.getHeaders(method, data);

        fetch(url, headers).then(response => response.json())
            .then(data => { callbackObj.fetchSuccess(data) })
            .catch(error => { callbackObj.fetchFailed(error) });
    }

    async fetchWithCount(callbackObj, url, countUrl, method, data = null) {
        let headers = await this.getHeaders(method, data);

        fetch(url, headers).then(response => response.json())
            .then(data => { callbackObj.fetchSuccess(data) })
            .catch(error => { callbackObj.fetchFailed(error) });

        if (callbackObj.fetchCountSuccess && callbackObj.fetchCountFailed) {
            fetch(countUrl)
                .then(response => response.json())
                .then(data => { callbackObj.fetchCountSuccess(data) })
                .catch(error => { callbackObj.fetchCountFailed(error) });
        }
    }
}