import React from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBNavLink, MDBBtn } from 'mdbreact'
import PrivacyPolicy from '../PrivacyModal'
import './style.css'
import { AuthContext } from '../../AuthContext/AuthContext'
import { LOGIN_PAGE, REGISTER_PAGE } from '../../AuthContext/AuthHandler'
import { withRouter } from 'react-router-dom'

const homeUrl = '/'
const listingsUrl = '/listings'
const listingsUrlAu = '/au/listings'
const soldUrl = '/sold'
const developerUrl = '/partners'
const neighbourhoodUrl = '/neighbourhoodguide'
const aboutUrl = '/our-story'
const newsUrl = '/executive-news'
const careersUrl = '/careers'
const contactUsUrl = '/contactus'
const salesUrl = './property-sales-team'
// const comingUrl = './comingsoon'

class FooterPage extends React.Component {
  authCtx = null

  navigate = (url) => {
    // navigate to top of page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }

  toggleAuthModal = (show, page) => {
    this.authCtx.toggleShow(show, page)
  }

  logout = () => {
    this.authCtx.logout()
  }

  render() {
    this.authCtx = this.context.auth

    return (
      <MDBFooter color='default-color' className='font-small'>
        <div style={{ backgroundColor: '#cda177' }}>
          <MDBContainer fluid className='text-center text-md-left pt-2 pb-2'>
            <MDBRow className='py-4 d-flex align-items-center'>
              <MDBCol md='6' lg='5' className='text-center text-md-left mb-4 mb-md-0'>
                <h6 className='mb-0 white-text h6-responsive' style={{ fontWeight: '400' }}>
                  Stay connected with us on our social networks.
                </h6>
              </MDBCol>
              <MDBCol md='6' lg='7' className='text-center text-md-right' style={{ fontSize: '22px' }}>
                <a className='ins-ic' href='https://www.facebook.com/executive.real.estate1' target='_blank' rel='noopener noreferrer'>
                  <i className='fab fa-instagram white-text mr-4 mr-lg-4'> </i>
                </a>
                <a className='fb-ic ml-0' href='https://www.instagram.com/executive_realestate/' target='_blank' rel='noopener noreferrer'>
                  <i className='fab fa-facebook white-text mr-4 mr-lg-4'> </i>
                </a>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer className='mt-5 mb-4 text-center text-md-left footer-padding-top-bottom' style={{ letterSpacing: '1px' }}>
          <MDBRow className='mt-3'>
            <MDBCol md='3' className='mb-4 mx-auto'>
              <h6 className='text-uppercase font-weight-bold text-gold'>
                <strong>Contact us</strong>
              </h6>
              {/* <hr className="white accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} /> */}
              <p className='mt-2'>
                <i className='fa fa-envelope mr-3' />{' '}
                <a className='white-text' href='mailto:info@ere.nz'>
                  info@ere.nz
                </a>
              </p>
              <p>
                <i className='fa fa-phone mr-3' />{' '}
                <a className='white-text' href='tel:0800373000'>
                  0800 373 000 (0800 ERE 000)
                </a>
              </p>
              <p style={{ display: 'flex' }}>
                <i className='fa fa-map-marker-alt mr-3 mt-1' />{' '}
                <span>
                  Christchurch office located upstairs in Merivale Mall
                  <br />
                  <br />
                  PO Box 5648
                  <br /> Papanui
                  <br /> Christchurch 8542
                </span>
              </p>
              <p>
                <i className='fa fa-map-marker-alt mr-3' /> Australia
              </p>
              <p className='pointer'>
                <div onClick={() => this.navigate(salesUrl)}>
                  <MDBNavLink to={salesUrl}>
                    <i className='fa fa-user mr-3' />
                    Contact Sales Team
                  </MDBNavLink>
                </div>
              </p>
            </MDBCol>
            <MDBCol md='3' className='mb-4 mx-auto'>
              <h6 className='text-uppercase font-weight-bold text-gold'>
                <strong>Property for sale</strong>
              </h6>
              <div onClick={() => this.navigate(listingsUrl)}>
                <MDBNavLink to={listingsUrl}>New Zealand listings</MDBNavLink>
              </div>
              <div onClick={() => this.navigate(listingsUrlAu)}>
                <MDBNavLink to={listingsUrlAu}>Australia listings</MDBNavLink>
              </div>
              {/* <div onClick={() => this.navigate(comingUrl)}>
                <MDBNavLink to={comingUrl}>Property Coming Soon</MDBNavLink>
              </div> */}
              <div onClick={() => this.navigate(soldUrl)}>
                <MDBNavLink to={soldUrl}>Sold listings</MDBNavLink>
              </div>
            </MDBCol>
            <MDBCol md='3' className='mb-4 mx-auto'>
              <h6 className='text-uppercase font-weight-bold text-gold'>
                <strong>Explore</strong>
              </h6>
              <div onClick={() => this.navigate(newsUrl)}>
                <MDBNavLink to={newsUrl}>Executive news</MDBNavLink>
              </div>
              <div onClick={() => this.navigate(neighbourhoodUrl)}>
                <MDBNavLink to={neighbourhoodUrl}>Neighbourhood Guide</MDBNavLink>
              </div>
              <div onClick={() => this.navigate(aboutUrl)}>
                <MDBNavLink to={aboutUrl}>Our story</MDBNavLink>
              </div>
              <div onClick={() => this.navigate(careersUrl)}>
                <MDBNavLink to={careersUrl}>Careers</MDBNavLink>
              </div>
              <div onClick={() => this.navigate(developerUrl)}>
                <MDBNavLink to={developerUrl}>Property developers</MDBNavLink>
              </div>
            </MDBCol>
            <MDBCol md='3' className='mb-4 mx-auto pl-lg-5 pl-0'>
              <h6 className='text-uppercase font-weight-bold text-gold'>
                <strong>Useful links</strong>
              </h6>
              {/* <hr className="white accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} /> */}
              {this.authCtx.state.loggedIn ? (
                <>
                  <div onClick={() => this.logout()}>
                    <div className='mb-2 pointer'>Logout</div>
                  </div>
                  <div>
                    <MDBNavLink className='mb-2' to='/account'>
                      My Account
                    </MDBNavLink>
                  </div>
                  <div>
                    <MDBNavLink className='mb-2' to='/watchlist'>
                      My Watchlist
                    </MDBNavLink>
                  </div>
                </>
              ) : (
                <>
                  <div onClick={() => this.toggleAuthModal(true, LOGIN_PAGE)}>
                    <div className='mb-2 pointer'>Login</div>
                  </div>
                  <div onClick={() => this.toggleAuthModal(true, REGISTER_PAGE)}>
                    <div className='mb-2 pointer'>Register</div>
                  </div>
                </>
              )}
              <div className='mb-3'>
                <a href='#!'>
                  <PrivacyPolicy />
                </a>
              </div>
              <MDBBtn outline color='white' onClick={() => this.navigate(contactUsUrl)}>
                <MDBNavLink to={contactUsUrl}>Contact</MDBNavLink>
              </MDBBtn>
            </MDBCol>
            <MDBCol md='6' className='mb-4 mt-lg-5 mt-4 m-lr-auto pr-lg-4 pr-0'>
              <h6 className='text-uppercase font-weight-bold text-gold'>
                <strong>Executive Real Estate</strong>
              </h6>
              {/* <hr className="white accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} /> */}
              <p>
                The Home of Executive Properties for Sale across New Zealand and Australia. <br />
                <br />
                People & Property Real Estate Limited trading as Executive Real Estate Limited Licensed under the REAA 2008.
                <br />
                All Rights Reserved.
              </p>
            </MDBCol>
            <MDBCol md='6' className='mb-4 mt-4 mx-auto pr-lg-5 pr-0 text-lg-right text-center'>
              <img
                src={require('../../assets/logos/ere_loader.png')}
                alt='logo'
                className='pr-lg-5 pr-0 pointer footer-logo'
                onClick={() => this.props.history.push('/')}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <hr className='grey accent-2 m-0 p-0 d-inline-block mx-auto' style={{ width: '100%', opacity: '0.2' }} />
        <div className='footer-copyright footer-padding font-text text-center'>
          <MDBContainer fluid>
            <br />
            <div className='white-text text-uppercase'>&copy; {new Date().getFullYear()} Copyright Executive Real Estate</div>
            <p className='text-uppercase mb-2 grey-text'>
              <a href='http://xodesoftware.com/' rel='noopener noreferrer' alt='Designed by xodesoftware.com' target='_blank'>
                Web design build by Xode
              </a>
            </p>
          </MDBContainer>
        </div>
      </MDBFooter>
    )
  }
}
FooterPage.contextType = AuthContext
export default withRouter(FooterPage)
