import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './style.css';

function CollapsePage(props) {

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className={'grey-text' + (expanded ? ' expand-content-expanded' : ' expand-content-hidden')}>{ReactHtmlParser(props.text)}</div>
      <div className="expand-more-btn" onClick={() => setExpanded(!expanded)}>{expanded ? 'Hide' : 'Read More'}</div>
    </>
  );
}

export default CollapsePage;