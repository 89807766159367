import React from 'react'
import { MDBAnimation, MDBCol, MDBRow, MDBIcon, MDBContainer, MDBBtn, MDBTypography, MDBBox } from 'mdbreact'
import Contact from '../ContactComponents/ContactUs'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { GA_ID } from '../../Constants'
import { STRAPI_URL, GET } from '../../AuthContext/AuthHandler'
import DataRequestor from '../../Data/DataRequestor'
import { Logger } from '../../Data/Logger'

class PartnersPage extends React.Component {
  logger = new Logger()

  constructor(props) {
    super(props)
    this.state = {
      requestor: new DataRequestor(),
      data: []
    }
  }

  componentDidMount() {
    this.fetchData()
    const ReactPixel = require('react-facebook-pixel');
    const options = {autoConfig: true, debug: false};
    ReactPixel.default.init('379566864000726', undefined, options);
    ReactPixel.default.fbq('track', 'PageView');
    ReactGA.initialize(GA_ID)
    ReactGA.pageview('/property-developers')

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  fetchData() {
    const url = `${STRAPI_URL}/developers?_sort=Name:ASC`
    this.state.requestor.fetch(this, url, GET)
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data
      })
      this.logger.log(`developers fetch success`)
      this.logger.log(data)
    }
  }

  fetchFailed(error) {
    this.logger.log(error)
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById('about-us')
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: 'smooth'
    })
  }
  render() {
    const bgImg = require('../../assets/img/blackmamba.png')
    return (
      <>
        <Helmet>
          {/*Primary meta tags */}
          <title>Partners | Executive Real Estate</title>
          <meta
            name='description'
            content='We are a group of multi-award winning property developers who formed the brand Executive Real Estate. - Property Sales Team'
          />
          <meta name='author' content='Partners | Executive Real Estate' />
          <link rel='canonical' href={window.location.href} />

          {/*Open Graph meta tags */}
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:title' content='Partners | Executive Real Estate' />
          <meta
            property='og:description'
            content='We are a group of multi-award winning property developers who formed the brand Executive Real Estate. - Property Sales Team'
          />
          <meta property='og:image' content='https://www.ere.nz/ere-home.png' />

          {/*Twitter meta tags */}
          <meta property='twitter:card' content='summary_large_image'></meta>
          <meta property='twitter:url' content={window.location.href}></meta>
          <meta property='twitter:title' content='Partners | Executive Real Estate' />
          <meta
            property='twitter:description'
            content='We are a group of multi-award winning property developers who formed the brand Executive Real Estate. - Property Sales Team'
          />
          <meta property='twitter:image' content='https://www.ere.nz/ere-home.png' />
        </Helmet>
        <div className='bg-img overflow-hidden' style={{ backgroundImage: 'url(' + bgImg + ')' }}>
          <div className='bg-salesteam page-header dark-filter clear-filter header-filter'>
            <div className='container'>
              <div style={{ height: '60vh' }} className='centered1'>
                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                  <div className='title'>
                    <MDBAnimation reveal type='slideInRight' className='fast'>
                      <h1 className='heading-h1-lg heading-h1-sm'>Our Property Developers</h1>
                    </MDBAnimation>
                    {/* <MDBAnimation reveal type="fadeIn" className="slow"> */}
                    <h3 className='heading-h2-lg heading-h2-sm'>
                      We work directly with developers in <br /> <b className='text-gold'>New Zealand and Australia</b>
                    </h3>
                    {/* </MDBAnimation> */}
                    {/* <div className='pt-4' onClick={() => this.scrollToGetStarted()}>
                      <MDBAnimation type='flipInX' className='slow'>
                        <MDBIcon icon='arrow-down' className='white-text pr-3' />
                      </MDBAnimation>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id='about-us' className='pt-5' style={{ backgroundColor: 'black' }}>
            <MDBContainer fluid className='mt-6' style={{ maxWidth: 1600 }}>
              {/* <div className="mt-4">
                                <h1 className="mb-4 mt-4 text-gold">Get in touch with one of our sales team today.</h1>
                            </div>
                            <hr className="white accent-2 pl-4 mb-4 mt-4 d-inline-block mx-auto" style={{ width: "100px", height: "2px" }} /> */}
              <div>
                <MDBRow className='mb-5'>
                  <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
                    {this.state.data.map((d, i) => (
                      <MDBCol
                        key={i}
                        sm='12'
                        md='6'
                        lg='3'
                        className='mt-5 mb-5 mx-auto'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        {d.Logo && 
                        <img
                          src={d.Logo.url}
                          className='rounded text-center size20'
                          alt={d.Name}
                          style={{ maxWidth: 200, width: '100%', objectFit: 'contain', minHeight: 200 }}
                        />}
                        <h5 className='font-weight-md mt-5'>{d.Name}</h5>
                      </MDBCol>
                        
                    ))}
                  </div>
                </MDBRow>
              </div>
            </MDBContainer>
            <div className='background-darker pb-5 pt-5 white-text'>
              <MDBContainer className='pt-2 text-center'>
                <MDBCol md='12' lg='12' xl='12'>
                  <div className='pb-3 h3-responsive text-gold mt-3'>Interested in joining us?</div>
                  <MDBCol md="9" className="mx-auto">
                    <div className='pb-4 h5-responsive mt-3'>
                      We welcome other builders and developers to join our group. Get in touch with us to find out more.
                    </div>
                    <MDBBtn outline color='primary' size='lg' className='mb-5' onClick={() => this.props.history.push('/contactus')}>
                      Join Us
                    </MDBBtn>
                  </MDBCol>
                </MDBCol>
              </MDBContainer>
            </div>
            <Contact />
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(PartnersPage)
