import React from "react";
import DeveloperCarousel, { CARD_STYLE_VERTICAL } from './DeveloperCarousel';

class OtherHomes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            developerId: props.developerId
        }
    }

    componentDidMount() {
        if (this.state.developerId) {
            let params = [];
            params.push(['developer_id=', `${this.state.developerId}`]);
            this.props.requestor.buildParams(params);
        }
    }

    render() {
        return this.state.developerId ? (
            <>
                <DeveloperCarousel currentSeletionId={this.props.currentSeletionId} title={"Other homes for sale by this developer"} reversed={this.props.reversed} cardStyle={CARD_STYLE_VERTICAL} authCtx={this.props.authCtx} skip={2} take={10} requestor={this.props.requestor} viewListing={this.props.toggleViewListing} />
            </>
        ) : null
    }
}

export default OtherHomes;
