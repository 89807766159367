import React from 'react'
import { MDBContainer, MDBCol, MDBBtn } from 'mdbreact'
import { withRouter } from "react-router";

function LandBanner(props) {
    const history = props.history;

    return (
        <>
            <div className="bg-gold pt-1"></div>
            <div className="bg-dark pt-3 pb-2 white-text">
                <MDBContainer className="pt-5">
                    <MDBCol md="10" lg="10" xl="10">
                        <h2 className="h2-responsive text-left mb-4">
                            Do you have land to sell? We are looking for land to purchase.
                         </h2>
                        <h5 className="h5-responsive text-left mb-5">
                            Get in touch with us to discuss selling.
                        <br /><br />
                            <MDBBtn className="font-weight-md" outline color="white" onClick={() => props.history.push('/property-sales-team')}>Get in Touch</MDBBtn>
                        </h5>
                    </MDBCol>
                </MDBContainer>

            </div>
        </>
    )
}

export default withRouter(LandBanner);

