import React from 'react';
import { MDBAnimation, MDBCol, MDBRow, MDBIcon, MDBContainer, MDBBtn, MDBTypography, MDBBox } from "mdbreact";
import Contact from '../ContactComponents/ContactUs'
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { GA_ID } from '../../Constants';


class SalesTeamPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/property-sales-team');

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("about-us");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }
    render() {

        const bgImg = require('../../assets/img/blackmamba.png');
        return (
            <>
                <Helmet>

                    {/*Primary meta tags */}
                    <title>Property Sales Team | Executive Real Estate</title>
                    <meta name="description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate. - Property Sales Team" />
                    <meta name="author" content="Property Sales Team | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Property Sales Team | Executive Real Estate" />
                    <meta property="og:description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate. - Property Sales Team" />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="twitter:title" content="Property Sales Team | Executive Real Estate" />
                    <meta property="twitter:description" content="We are a group of multi-award winning property developers who formed the brand Executive Real Estate. - Property Sales Team" />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />

                </Helmet>
                <div className="bg-img overflow-hidden" style={{ backgroundImage: 'url(' + bgImg + ')' }}>
                    <div className="bg-salesteam page-header dark-filter clear-filter header-filter">
                        <div className="container">
                            <div style={{ height: '60vh' }} className="centered1">
                                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                                    <div className="title">
                                        <MDBAnimation reveal type="slideInRight" className="fast">
                                            <h1 className="heading-h1-lg heading-h1-sm">Our Property Sales Consultants</h1>
                                        </MDBAnimation>
                                        {/* <MDBAnimation reveal type="fadeIn" className="slow"> */}
                                        <h3 className="heading-h2-lg heading-h2-sm">Meet the <b className="text-gold">sales team</b></h3>
                                        {/* </MDBAnimation> */}
                                        <div className="pt-4" onClick={() => this.scrollToGetStarted()}>
                                            <MDBAnimation type="flipInX" className="slow">
                                                <MDBIcon icon="arrow-down" className="white-text pr-3" />
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="about-us" className="pt-5" style={{ backgroundColor: 'black' }}>
                        <MDBContainer className="mt-6">
                            <div className="mt-4">
                                <h1 className="mb-4 mt-4 text-gold">Get in touch with one of our sales team today.</h1>
                            </div>
                            <hr className="white accent-2 pl-4 mb-4 mt-4 d-inline-block mx-auto" style={{ width: "100px", height: "2px" }} />
                            <div className="divider-margin">
                                <MDBRow>
                                    <MDBCol sm="12" md="4" lg="4" className="mt-5 mb-5 mx-auto">
                                        <img src={require('../../assets/img/bridget_new.png')} className="rounded text-center size20" alt="Bridget Perry" />
                                        <h5 className="name-color mt-4 font-weight-bold">Bridget Perry</h5>
                                        <h5 className="mb-4 divider-margin-small text-title">Sales Consultant</h5>
                                        <div className="text-center mt-3">
                                            <p>
                                                <a className="white-text" style={{ letterSpacing: '2px', fontSize: '15px' }} href="tel:+6421402071"><i className="fa fa-phone mr-3 white-text" />+64 21 4020 71</a>
                                            </p>
                                            <p>
                                                <a className="white-text" style={{ letterSpacing: '2px', fontSize: '15px' }} href="mailto:bridget@ere.nz"><i className="fa fa-envelope mr-3 white-text" />bridget@ere.nz</a>
                                            </p>
                                        </div>
                                        {/* <AboutUsExpand text={bridgetWriteup} /> */}
                                    </MDBCol>
                                    <MDBCol sm="12" md="4" lg="4" className="mt-5 mb-5 mx-auto">
                                        <img src={require('../../assets/img/fiona_new.png')} className="rounded text-center size20" alt="Fiona Williams" />
                                        <h5 className="name-color mt-4 font-weight-bold">Fiona Williams</h5>
                                        <h5 className="mb-4 divider-margin-small text-title">Sales Consultant</h5>
                                        <div className="text-center mt-3">
                                            <p>
                                                <a className="white-text" style={{ letterSpacing: '2px', fontSize: '15px' }} href="tel:+64272333804"><i className="fa fa-phone mr-3 white-text" />+64 27 233 3804</a>
                                            </p>
                                            <p>
                                                <a className="white-text" style={{ letterSpacing: '2px', fontSize: '15px' }} href="mailto:fiona@ere.nz"><i className="fa fa-envelope mr-3 white-text" />fiona@ere.nz</a>
                                            </p>
                                        </div>
                                        {/* <AboutUsExpand text={fionaWriteup} /> */}
                                    </MDBCol>
                                    <MDBCol sm="12" md="4" lg="4" className="mt-5 mb-5 mx-auto">
                                        <img src={require('../../assets/img/louise.png')} className="rounded text-center size20" alt="Louise Phillips" />
                                        <h5 className="name-color mt-4 font-weight-bold">Louise Phillips</h5>
                                        <h5 className="mb-4 divider-margin-small text-title">Sales Consultant with Bridget Perry</h5>
                                        <div className="text-center mt-3">
                                            <p>
                                                <a className="white-text" style={{ letterSpacing: '2px', fontSize: '15px' }} href="tel:+64272443540"><i className="fa fa-phone mr-3 white-text" />+64 27 244 3540</a>
                                            </p>
                                            <p>
                                                <a className="white-text" style={{ letterSpacing: '2px', fontSize: '15px' }} href="mailto:louise@ere.nz"><i className="fa fa-envelope mr-3 white-text" />louise@ere.nz</a>
                                            </p>
                                        </div>
                                        {/* <AboutUsExpand text={fionaWriteup} /> */}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <h3 className="text-gold pt-5 pb-3">Our board</h3>
                                    </MDBCol>
                                    <MDBCol sm="12" md="6" lg="6 " className="mt-5 mb-5 mx-auto">
                                        {/* <a href="https://www.silverstardevelopments.co.nz/" target="_blank" rel="noopener noreferrer" className="p-2 fa-lg">
                                            <img src={require('../../assets/img/tony.png')} className="rounded text-center size20" alt="Tony Anderson" />
                                        </a> */}
                                        <h5 className="name-color mt-4 font-weight-bold">Tony Anderson</h5>
                                        <a href="https://www.silverstardevelopments.co.nz/" target="_blank" rel="noopener noreferrer" alt="Silverstar Developments"><h6 className="mb-4 divider-margin-small white-text text-title">Director</h6></a>
                                        {/* <AboutUsExpand text={tonyWriteup} /> */}
                                        <ul className="list-unstyled mb-0">
                                            <a href="https://www.instagram.com/silverstar_developments/?hl=en" target="_blank" rel="noopener noreferrer" className="p-2 fa-lg">
                                                <MDBIcon fab icon="instagram" className="icon-color" />
                                            </a>
                                            <a href="https://www.facebook.com/silverstardevelopmentsltd/" target="_blank" rel="noopener noreferrer" className="p-2 fa-lg">
                                                <MDBIcon fab icon="facebook-f" className="icon-color" />
                                            </a>
                                            <a href="https://www.silverstardevelopments.co.nz/" target="_blank" rel="noopener noreferrer" className="p-2 fa-lg">
                                                <MDBIcon icon="external-link-alt" className="icon-color" />
                                            </a>
                                        </ul>
                                    </MDBCol>

                                </MDBRow>
                            </div>
                        </MDBContainer>
                        <div className="background-darker pb-5 white-text">
                            <MDBContainer>
                                <MDBAnimation reveal type="fadeIn" className="slow pt-5 mb-4 ml-5 mr-5">
                                    <MDBTypography blockquote>
                                        <MDBBox tag="p" mb={0}><i className="text-gold">There is no place like an Executive Home.</i></MDBBox>
                                    </MDBTypography>
                                </MDBAnimation>
                            </MDBContainer>
                        </div>
                        <Contact />
                    </div >
                </div>
            </>
        )
    }
}

export default withRouter(SalesTeamPage);
