import React from 'react';
import { MDBIcon, MDBContainer, MDBCol, MDBRow, MDBView, MDBAnimation, MDBBtn, MDBNavLink } from 'mdbreact';
import { withRouter } from 'react-router';
import DataRequestor, { ENTITY_BLOG_POSTS } from '../../Data/DataRequestor';
import { STRAPI_URL, GET } from '../../AuthContext/AuthHandler';
import { DateHelper } from '../../Data/DateHelper';
import { Logger } from '../../Data/Logger';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet'
import Embed from 'react-embed';
import './style.css';
import {
    EmailIcon,
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    // PinterestIcon,
    // PinterestShareButton,
} from "react-share";
import ReactGA from 'react-ga'


export class SelectedBlogPage extends React.Component {

    logger = new Logger();

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            params: null,
            data: null,
            isLoading: true,
            requestor: new DataRequestor()
        }
    }

    componentDidMount() {
        this.decodeUrl();
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(268085209);
        ReactGA.pageview('/post?id=' + this.props.id + '&executive-news=' + this.props.url);
    };

    decodeUrl() {
        let id = 0;
        let params = [];

        let qStr = window.location.search;
        qStr = qStr.replace('?', '');

        params = qStr.split('&');
        if (params && params.length > 0) {
            let pStr = params.find((q) => { return q.startsWith('id') });
            if (pStr && pStr.length > 0) {
                let pArr = pStr.split('=');
                if (pArr && pArr.length === 2) {
                    id = pArr[1];
                }
            }
        }

        this.setState({
            id: id,
            params: params
        });

        let url = STRAPI_URL + ENTITY_BLOG_POSTS + `/${id}`;
        this.state.requestor.fetch(this, url, GET);
    }

    fetchSuccess(data) {
        window.setTimeout(() => {
            this.setState({
                data: data,
                isLoading: false
            });
        }, 800);
        this.logger.log(`blog fetch success`);
        this.logger.log(data);
        console.log(data)
    }

    fetchFailed(error) {
        this.logger.log(error);
    }


    render() {
        const blog = this.state.data;
        const dateHelper = new DateHelper();
        // const regExOpen = new RegExp(/"&lt;iframe"/g);
        // const regExClose = new RegExp(/"/g);
        const blogBody = blog ? blog.body.split('&lt;iframe').join('<iframe').split('&gt;&lt;/iframe&gt;').join('></iframe>') : null;
        const shareUrl = blog ? 'https://www.ere.nz/post?id=' + blog.id + '&executive-news=' + blog.url : null;
        const blogTitle = blog ? blog.Title : null;
        // const blogSubtitle = blog ? blog.Subtitle : null;
        const coverImg = blog ? blog.coverphoto.url : null;
        // const authorImg = blog && blog.blog_author ? blog.blog_author.Photo.url : null;

        return (
            <>
                {
                    this.state.isLoading ? (
                        <div style={{ height: '100vh' }}>
                            {/* <LoaderComponent /> */}
                        </div>
                    ) : null
                }
                {
                    blog ? (
                        <>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>
                                    {blog.metatitle}
                                </title>
                                <description>
                                    {
                                        blog.Subtitle ? (
                                            <>
                                                {blog.Subtitle}
                                            </>
                                        ) : ''
                                    }
                                </description>
                                <link rel="canonical" href="https://www.ere.nz/" />
                            </Helmet>
                            {/* <ScrollToTop /> */}
                            <MDBView>
                                <div className="page-header header-filter clear-filter dark-filter d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${coverImg})`, backgroundSize: 'cover', backgroundPosition: 'center center', minHeight: '50vh' }}>
                                    <div className="header-padding container pt-lg-0 pt-5 d-flex justify-content-center align-items-center banner-height" >
                                        <MDBAnimation type="pulse" className="slow">
                                            <h6 className="mb-4  mt-0 mt-lg-5">
                                                {
                                                    blog.blog_category ? (
                                                        <div className=" gold-text h3-responsive">
                                                            <b className="font-weight-md">{blog.blog_category.Name}</b>
                                                        </div>
                                                    ) : null
                                                }
                                            </h6>
                                            <h1 className=" white-text h1-responsive border-white">
                                                {
                                                    blog.title ? (
                                                        <>
                                                            {blog.title}
                                                        </>
                                                    ) : null
                                                }
                                            </h1>
                                            {
                                                blog.publishdate ? (
                                                    <div className=" mt-4 h4-responsive text-gold">
                                                        <b>{dateHelper.getDateStr(blog.publishdate)}</b>
                                                    </div>
                                                ) : null
                                            }
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBView>
                            <div className="pt-5 pb-5 text-left text-justify">
                                <MDBContainer className="mb-2">
                                    <MDBRow>
                                        <MDBCol xs="8" md="9">
                                            {
                                                blog.subtitle ? (
                                                    <>
                                                        <div className="h2-responsive mt-4 mb-lg-5 mb-0 white-text blog-subtitle">
                                                            {blog.subtitle}
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                        </MDBCol>
                                        <MDBCol xs="4" md="3" className="text-center mt-lg-4 mt-0">
                                            <img src={require('../../assets/logos/peaks.png')} className="blog-peaks" />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="mt-3 mt-lg-0">
                                        <MDBCol md="3" className="text-white">
                                            <hr className="text-left gold-bg d-inline-block mx-auto mb-1" style={{ width: "90%", height: "1px", backgroundColor: '#cda177' }} /><br />
                                        </MDBCol>
                                        <MDBCol md="9">
                                            <div className="blog-body">
                                                {
                                                    blogBody ? (
                                                        <>
                                                            {ReactHtmlParser(blogBody, { transform: updateHtml })}
                                                        </>
                                                    ) : null

                                                }
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow className="pb-5">
                                        <MDBCol md="12">
                                            <div className="grey-text h4-responsive mb-2 mt-3 ">
                                                Share this {
                                                    blog.blog_category ? (
                                                        <>
                                                            {blog.blog_category.Name}
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="8">
                                            <FacebookShareButton
                                                url={shareUrl}
                                                quote={blogTitle}
                                                hashtag="#ExecutiveRealEstate"
                                                className="socialMediaButton"
                                            >
                                                <FacebookIcon size={36} round={true} />
                                            </FacebookShareButton>
                                            <EmailShareButton
                                                url={shareUrl}
                                                subject={blogTitle}
                                                className="socialMediaButton"
                                            >
                                                <EmailIcon size={36} round={true} />
                                            </EmailShareButton>
                                            {/* <LinkedinShareButton
                                                url={shareUrl}
                                                title={blogTitle}
                                                summary={blogSubtitle}
                                                source={'Xode Software'}
                                                className="socialMediaButton"
                                            >
                                                <LinkedinIcon size={36} round={true} />
                                            </LinkedinShareButton> */}
                                            {/* <PinterestShareButton
                                                url={shareUrl}
                                                media={coverImg}
                                                description={blogSubtitle}
                                                className="socialMediaButton"
                                            >
                                                <PinterestIcon size={36} round={true} /> */}
                                            {/* </PinterestShareButton> */}
                                        </MDBCol>
                                        <MDBCol MD="4">
                                            <div className="text-lg-right mt-lg-0 mt-5">
                                                <MDBBtn className="text-right" color="primary">
                                                    <MDBNavLink onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })} style={{ color: 'whitesmoke' }} to="/executive-news" className="gtm-event-button">
                                                        More news <MDBIcon icon="angle-right" className="ml-2" />
                                                    </MDBNavLink>
                                                </MDBBtn>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </div>
                        </>
                    ) : null
                }
            </>
        )
    }
}
export default withRouter(SelectedBlogPage);

function updateHtml(node) {
    if (node.type === 'tag' && node.name === 'oembed') {
        return <div className="blog-iframe-container"><Embed width={'100%'} height={'100%'} className="iframe" url={node.attribs.url} /></div>
    }

    if (node.type === 'tag' && node.name === 'iframe') {
        let src = '';

        if (node && node.attribs) {
            src = node.attribs.src;
            return <div className="blog-iframe-container"><iframe className="iframe" width="100%" height="100%" src={src} /></div>;
        }

        return null;
    }
}