import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBContainer } from 'mdbreact';

class ModalPage extends React.Component {
  state = {
    modal10: false,
    modal11: false
  }

  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  render() {
    return (
      <div>
        <div onClick={this.toggle(10)}>Privacy Policy</div>
        <MDBModal size="fluid" fullHeight position="bottom" isOpen={this.state.modal10} toggle={this.toggle(10)} className="white-text">
          <MDBModalBody className="text-left background-dark">
            <MDBContainer className="divider-margin">
              <h1 className="h1-responsive mt-4 mb-4 text-gold">Our Privacy Policy</h1>
              <p className="h5-responsive mb-5">We collect personal information from you, including information about your Name and Contact Information.
                We collect your personal information in order to communicate with you and to manage your account and watchlist listings.<br /><br />
                We keep your information safe by storing it securely in our cloud servers.
                <br /><br />
                You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected if you think it is wrong. If you’d like to ask for a copy of your information, or to have it corrected, please contact us at info@ere.nz.</p>
              <MDBBtn color="secondary" onClick={this.toggle(10)}>Close</MDBBtn>
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}

export default ModalPage;