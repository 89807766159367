import { MDBAnimation, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";
import { AuthContext } from '../../AuthContext/AuthContext';
import { AU, GA_ID, IS_LOADING, LOADED } from '../../Constants';
import ListingRequest, { SEARCH_CURRENT_LISTING_REQUEST_AUSTRALIA } from '../../Data/ListingRequest';
import { PAGE_LISTINGS } from '../../Data/NavParams';
import WatchlistRequest from '../../Data/WatchlistRequest';
// import SearchDisplayCount from './SearchDisplayCount';
import ListingCard from '../CarouselComponents/CardCarouselComponent/ListingCardComponent/ListingCard';
import Contact from '../ContactComponents/ContactUs';
import NewsletterRegisterContainer from '../NewsletterRegister/NewsletterFormContainer';
import NewsletterRegisterForm from '../NewsletterRegister/NewsletterRegisterForm';
import NoResult from '../NoResultComponent/NoResultComponent';
import Pagination from '../PaginationComponent/Index';
import SelectedListingPage from '../SelectedListingPage/SelectedListingPage';
import LandBanner from './LandBanner';
import SearchCity from './SearchCity';
import SearchPriceRange from './SearchPriceRange';
import SortBy, { SORT_HIGHEST_PRICE, SORT_LOWEST_PRICE, SORT_MOST_RECENT, SORT_OLDEST } from './SearchSort';
import SearchSuburb from './SearchSuburb';


class ListingsCurrent extends React.Component {

    authCtx = null;
    userSearchId = 0;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: IS_LOADING,
            error: true,
            selectedListingId: 0,
            showSelectedListing: false,
            lRequestor: new ListingRequest(SEARCH_CURRENT_LISTING_REQUEST_AUSTRALIA),
            wlRequestor: new WatchlistRequest(),
            search: '',
            city: '',
            suburb: '',
            sort: SORT_MOST_RECENT,
            displayCount: 5,
            pageNo: 1,
            pageCount: 0,
            minPrice: '',
            maxPrice: '',
            openHomesChecked: false
        }
        this.handleCheck = this.handleCheck.bind(this);
    }

    componentDidMount() {
        window.listingsComponent = this;
        this.checkPathParam();
        this.authCtx.addSubscriber(this);
        this.state.lRequestor.setAuth(this.authCtx);
        this.state.wlRequestor.setAuth(this.authCtx);
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/listings');

        this.scrollToTop();

        if (this.authCtx) {
            let p = this.authCtx.getParams(PAGE_LISTINGS);
            if (p && p.params) {
                this.setState({
                    search: p.params.search
                });
            }
        }

        this.userSearch(0);
    }
    getInitialState() {
        return this.state.openHomesChecked
    }

    handleCheck() {
        this.setState(state => ({
            openHomesChecked: !state.openHomesChecked
        }))
        this.userSearch();

    }

    checkPathParam = () => {
        let params = window.location.search;
        if (params && params.includes('listing')) {
            params = params.replace('?', '');
            let paramArr = params.split('&');
            let listingParam = paramArr.find(p => p.includes('listing'));
            paramArr = listingParam.split('=');
            if (paramArr && paramArr.length === 2) {
                this.setState({
                    selectedListingId: paramArr[1],
                    showSelectedListing: true
                })
            }
        }
    }

    scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentWillUnmount() {
        this.authCtx.removeSubscriber(this);
    }

    fetchSuccess = (data) => {
        if (!data) {
            this.setState({
                error: true
            });
        } else {
            this.setState({
                data: data
            });
        }
        this.updateLoading(LOADED);
    }

    fetchFailed = (error) => {
        this.setState({
            error: true
        });
        this.updateLoading(LOADED);
    }

    fetchCountSuccess = (data) => {
        if (!data) {
            this.setState({
                error: true
            });
            this.updateLoading(LOADED);
        } else {
            this.updatePagination(data);
        }
    }

    fetchCountFailed = (error) => {
        this.setState({
            error: true
        });
        this.updateLoading(LOADED);
    }

    updatePagination = (resultCount = this.state.resultCount) => {
        let pageCount = Math.ceil(resultCount / this.state.displayCount);
        this.setState({
            pageCount: pageCount > 0 ? pageCount : 1
        });
        this.updateLoading(LOADED);
    }

    updateLoading = (loadState) => {
        setTimeout(() => {
            this.setState({
                loading: loadState
            });
        }, 800);
    }

    browseToPage = (page) => {
        this.setState({
            pageNo: page
        });
        this.userSearch(0);
        this.scrollToTop();
    }

    citySelected = (city) => {
        this.setState({
            pageNo: this.state.city !== city ? 1 : this.state.pageNo,
            city: city
        });
        this.userSearch();
    }


    minPriceSelected = (minPrice) => {
        this.setState({
            pageNo: this.state.minPrice !== minPrice ? 1 : this.state.pageNo,
            minPrice: minPrice
        });
        this.userSearch();
    }
    maxPriceSelected = (maxPrice) => {
        this.setState({
            pageNo: this.state.maxPrice !== maxPrice ? 1 : this.state.pageNo,
            maxPrice: maxPrice
        });
        this.userSearch();
    }

    suburbSelected = (suburb) => {
        this.setState({
            pageNo: this.state.suburb !== suburb ? 1 : this.state.pageNo,
            suburb: suburb
        });
        this.userSearch();
    }


    sortSelected = (sort) => {
        this.setState({
            pageNo: this.state.sort !== sort ? 1 : this.state.sort,
            sort: sort
        });
        this.userSearch();
    }

    displayCountSelected = (count) => {
        this.setState({
            displayCount: count,
            pageNo: 1
        });
        this.updatePagination();
        this.userSearch();
    }

    getSort = () => {
        return this.state.sort;
    }

    searchChanged = (e, clear = false, search = null) => {
        if (clear && window.resetNavSearch) {
            window.resetNavSearch();
        }
        this.setState({
            pageNo: this.state.search !== e.target.value ? 1 : this.state.pageNo,
            // search: search ? search : e.target.value
            search: search ? search : e.target.value

        });

        this.userSearch();
    }

    reload = () => {

        this.userSearch(0);
    }

    userSearch = (timeout = 0) => {
        if (this.userSearchId > 0) {
            window.clearTimeout(this.userSearchId);
            this.userSearchId = 0;
        }


        this.userSearchId = window.setTimeout(() => {

            let params = [];
            if (this.state.search !== '')
                params.push(['_q=', this.state.search]);

            if (this.state.city !== '')
                params.push(['city.Name=', this.state.city]);

            if (this.state.suburb !== '')
                params.push(['suburb.Name=', this.state.suburb]);

            if (this.state.minPrice !== '')
                params.push(['price_min=', this.state.minPrice]);

            if (this.state.maxPrice !== '')
                params.push(['price_max=', this.state.maxPrice]);

            if (this.state.openHomesChecked === true)
                params.push(['has_open_homes=', 'true']);


            if (this.state.sort !== '') {
                if (this.state.sort === SORT_MOST_RECENT)
                    params.push(['_sort=', 'Go_Live_Date:DESC']);
                if (this.state.sort === SORT_OLDEST)
                    params.push(['_sort=', 'Go_Live_Date:ASC']);
                else if (this.state.sort === SORT_HIGHEST_PRICE)
                    params.push(['_sort=', 'PriceMax:DESC']);
                else if (this.state.sort === SORT_LOWEST_PRICE)
                    params.push(['_sort=', 'PriceMax:ASC']);
            }

            this.state.lRequestor.buildParams(params);

            this.setState({
                loading: IS_LOADING
            });

            let skip = ((this.state.pageNo - 1) * this.state.displayCount);
            let take = this.state.displayCount;
            this.state.lRequestor.fetch(this, skip, take);
        }, timeout);
    }

    updateListingData = (watchlistItem, watchListed) => {
        if (watchlistItem && watchlistItem.listing) {
            let listings = this.state.data.slice();
            let index = listings.findIndex(l => l.id === watchlistItem.listing.id);
            if (index !== -1) {
                listings[index].watchListed = watchListed;
                this.setState({
                    data: listings
                });
            }
        }
    }

    toggleViewListing = (e, id, status, show = false) => {
        if (e !== null)
            e.preventDefault();
        if (show) {
            //URL of the selected listing
            // console.log(`/listings?status=${status}&listing=${id}`);
            this.props.history.push(`/au/listings?status=${status}&listing=${id}`);
        } else {
            this.props.history.push('/au/listings');
        }
        this.setState({
            selectedListingId: id,
            showSelectedListing: show
        });
    }

    render() {
        this.authCtx = this.context.auth;

        // const bgImg = require('../../assets/img/blackmamba.png');

        const selectedListing = this.state.showSelectedListing ? (<SelectedListingPage id={this.state.selectedListingId} hideListing={this.toggleViewListing} country={AU} />) : null;

        const cards = this.state.data ? this.state.data.map((l, i) => {
            return (
                <div key={l.id} className="pb-4">
                    <ListingCard {...l} viewListing={this.toggleViewListing} updateListing={this.updateListingData} isVertical={false} />
                </div>
            )
        }) : null;

        return (
            <>
                <Helmet>
                    {/*Primary meta tags */}
                    <title>Latest Listings | Property for Sale | Executive Real Estate</title>
                    <meta name="description" content="Search our latest luxury property for sale at Executive Real Estate." />
                    <meta name="author" content="Latest Listings | Property for Sale | Executive Real Estate" />
                    <link rel="canonical" href={window.location.href} />

                    {/*Open Graph meta tags */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Latest Listings | Property for Sale | Executive Real Estate" />
                    <meta property="og:description" content="Search our latest luxury property for sale at Executive Real Estate." />
                    <meta property="og:image" content="https://www.ere.nz/ere-home.png" />

                    {/*Twitter meta tags */}
                    <meta property="twitter:card" content="summary_large_image"></meta>
                    <meta property="twitter:url" content={window.location.href}></meta>
                    <meta property="title:title" content="Latest Listings | Property for Sale | Executive Real Estate" />
                    <meta property="twitter:description" content="Search our latest luxury property for sale at Executive Real Estate." />
                    <meta property="twitter:image" content="https://www.ere.nz/ere-home.png" />

                </Helmet>
                {selectedListing}
                <div className="bg-img overflow-hidden">
                    <div className="bg-listings-au page-header dark-filter clear-filter header-filter">
                        <div className="container">
                            <div style={{ height: '60vh' }} className="centered1">
                                <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                                    <div className="title">
                                        <MDBAnimation reveal type="slideInDown" className="slow">
                                            <h1 className="heading-h1-lg heading-h1-sm">Find Your <b className="text-gold">Executive</b> Home</h1>
                                        </MDBAnimation>
                                        <MDBAnimation reveal type="slideInUp" className="slower">
                                            <h3 className="heading-h3-lg heading-h3-sm">Only the best in Australian luxury and lifestyle.</h3>
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MDBContainer className="min-page-height">
                        <MDBRow className="margin-top-20">
                            <MDBCol>
                                <h3 className="text-left h3-responsive pt-4">Residential Australian Property for Sale</h3>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="margin-top-20">
                            <MDBCol>
                                <MDBInput hint="Search" type="text" containerClass="mt-0" className="white-text" onChange={(e) => this.searchChanged(e, true)} value={this.state.search} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="text-left margin-bottom-40">
                                <SearchCity country={AU} selectedCity={this.state.city} citySelected={this.citySelected} />
                                <SearchSuburb country={AU} selectedSuburb={this.state.suburb} suburbSelected={this.suburbSelected} />
                                <SearchPriceRange selectMinPrice={this.state.minPrice}
                                    minPriceSelected={this.minPriceSelected}
                                    selectMaxPrice={this.state.maxPrice}
                                    maxPriceSelected={this.maxPriceSelected}
                                />

                                <SortBy selectedSort={this.state.sort} sortSelected={this.sortSelected} />
                                <div className="custom-control custom-checkbox custom-control-inline" style={{ marginLeft: "1rem" }}>
                                    <input type="checkbox" className="custom-control-input" id="defaultInline1" onClick={this.handleCheck} />
                                    <label className="custom-control-label" htmlFor="defaultInline1">Open Homes only</label>
                                </div>

                                {/* <SearchDisplayCount selectedDisplayCount={this.state.displayCount} displayCountSelected={this.displayCountSelected} /> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ height: '20px' }}>
                            <MDBCol>
                                {
                                    this.state.loading ?
                                        <div className="bar2"></div>
                                        : null
                                }
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol style={{ padding: '4px' }}>
                                {
                                    (this.state.data && this.state.data.length === 0) ?
                                        <NoResult text={`Looks like we don't have a match. Try search for something else.` } searchText={this.state.search} />
                                        : cards
                                }
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                {
                                    (!this.state.data || this.state.data.length === 0) ?
                                        null :
                                        <Pagination currentPage={this.state.pageNo} pageCount={this.state.pageCount} browseToPage={this.browseToPage} />
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <div id="subscribe">
                        <NewsletterRegisterContainer>
                            <NewsletterRegisterForm />
                        </NewsletterRegisterContainer>
                    </div>
                    <Contact />
                    <LandBanner />
                </div>
            </>
        )
    }
};

ListingsCurrent.contextType = AuthContext;
export default withRouter(ListingsCurrent);