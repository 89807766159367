import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import React, { useEffect, useState } from "react";
import { STRAPI_URL } from "../../AuthContext/AuthHandler";
import { AU, NZ } from "../../Constants";

const SearchCity = (props) => {
  const country = props.country ?? NZ
  const [cities, setCities] = useState([]);

  useEffect(() => {
    fetch(STRAPI_URL + '/cities?_sort=Name:ASC')
      .then(response => response.json())
      .then(data => { setCities(data) })
      .catch(error => { setCities([]) });
  }, []);

  const countryFilter = (city) => {
    if (country === NZ) {
      return city?.listings[0]?.country === null || city?.listings[0]?.country === 1
    } else if (country === AU) {
      return city?.listings[0]?.country === 2
    }
  }

  return (
    <MDBDropdown size="sm">
      <MDBDropdownToggle caret color="primary">
        {!props.selectedCity || props.selectedCity === '' ? 'Select City' : props.selectedCity}
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        <MDBDropdownItem className="dropdown-link" onClick={() => props.citySelected('')}>All</MDBDropdownItem>

        {
          cities.filter((c) => countryFilter(c)).map((c) => (<MDBDropdownItem key={c.id} className="dropdown-link" onClick={() => props.citySelected(c.Name)}>{c.Name}</MDBDropdownItem>))
        }
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}

export default SearchCity;