import { STRAPI_URL, GET, POST, PUT, PROMPT_LOGIN } from '../AuthContext/AuthHandler';

export const SETTINGS_ENTITY = '/usersettings';

export const GET_SETTINGS = 'get_settings';
export const GET_SETTINGS_REQUEST_QUERY = '/';

export const UPDATE_SETTINGS = 'update_settings';
export const UPDATE_SETTINGS_REQUEST_QUERY = '/';

export default class UserSettingRequest {

    query = '';
    type = '';

    setAuth(ctx) {
        this.authCtx = ctx;
    }

    async getHeaders(meth = GET, data = null) {

        let opts = {
            method: meth,
            headers: {}
        };

        await this.authCtx.getTokenRequest(PROMPT_LOGIN)
            .then((jwt) => {
                opts.headers = {
                    'authorization': 'Bearer ' + jwt
                };
            }).catch((err) => {
            })

        if (data !== null && (meth === PUT || meth === POST)) {
            opts.body = JSON.stringify(data);
        }

        return opts;
    }

    buildUrl(type) {
        if (type === GET_SETTINGS)
            this.query = GET_SETTINGS_REQUEST_QUERY;

        if (type === UPDATE_SETTINGS)
            this.query = UPDATE_SETTINGS_REQUEST_QUERY;
    }

    buildParams(params) {
        // append to end of urlPath
        for (let i = 0; i < params.length; i++) {
            let param = params[i];
            this.query += '&' + param[0] + param[1];
        }
    }

    async fetch(callbackObj, id) {
        this.buildUrl(GET_SETTINGS);
        this.buildParams({});
        let headers = await this.getHeaders(GET, null);

        let dataUrl = STRAPI_URL + SETTINGS_ENTITY + this.query + id;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => { callbackObj.fetchSuccess(data) })
            .catch(error => { callbackObj.fetchFailed(error) });
    }

    async update(callbackObj, data) {
        this.buildUrl(UPDATE_SETTINGS);
        this.buildParams({});
        let headers = await this.getHeaders(PUT, data);

        let dataUrl = STRAPI_URL + SETTINGS_ENTITY + this.query + data.id;

        fetch(dataUrl, headers).then(response => response.json())
            .then(data => { callbackObj.updateSuccess(data) })
            .catch(error => { callbackObj.updateError(error) });
    }
}