import React from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBView, MDBAnimation } from 'mdbreact';
import BlogCard from './BlogCard';
import DataRequestor, { ENTITY_BLOG_POSTS, ENTITY_BLOG_POSTS_COUNT } from '../../Data/DataRequestor';
import { STRAPI_URL, GET } from '../../AuthContext/AuthHandler';
import { GA_ID } from '../../Constants';
import { Logger } from '../../Data/Logger';
import { Helmet } from 'react-helmet';
import SearchCategory from './SearchCategory';
// import LoadMore from '../Buttons/LoadMoreButton'
import NewsletterRegisterContainer from '../NewsletterRegister/NewsletterFormContainer';
import NewsletterRegisterForm from '../NewsletterRegister/NewsletterRegisterForm';
import ReactGA from 'react-ga';

export const IS_LOADING = true;
export const LOADED = false;

class BlogPage extends React.Component {

    logger = new Logger();

    constructor(props) {
        super(props);
        this.state = {
            requestor: new DataRequestor(),
            pageCount: 0,
            data: [],
            category: ''
        }
    }

    componentDidMount() {
        this.fetchData(this.state.category);
        const ReactPixel = require('react-facebook-pixel');
        const options = {autoConfig: true, debug: false};
        ReactPixel.default.init('379566864000726', undefined, options);
        ReactPixel.default.fbq('track', 'PageView');
        ReactGA.initialize(GA_ID);
        ReactGA.pageview('/executive-news');
    }

    fetchData(category = '') {
        const dateStr = this.state.requestor.getDateStr();
        const categoryName = category.length > 0 ? `&blog_category.name=${category}` : '';

        let url = `${STRAPI_URL}${ENTITY_BLOG_POSTS}?publishdate_lte=${dateStr}&_sort=publishdate:DESC${categoryName}`;
        let countUrl = `${STRAPI_URL}${ENTITY_BLOG_POSTS_COUNT}`;
        this.state.requestor.fetchWithCount(this, url, countUrl, GET);
    }

    fetchSuccess(data) {
        if (data) {
            this.setState({
                data: data
            });
            this.logger.log(`blogs fetch success`);
            this.logger.log(data);
        }
    }

    fetchFailed(error) {
        this.logger.log(error);
    }

    fetchCountSuccess(data) {
        if (data) {
            this.setState({
                pageCount: data
            });
            this.logger.log(`blogs fetch count success | ${data}`);
        }
    }

    fetchCountFailed(error) {
        this.logger.log(error);
    }

    categorySelected = (category) => {
        this.setState({
            category: category
        });
        this.fetchData(category);
    }

    render() {
        const cards =
          this.state.data.length > 0
            ? this.state.data.map((l, i) => {
                return (
                  <MDBCol key={l.id} sm='12' md='6' lg='4' className='mb-5'>
                    <BlogCard key={l.id} {...l} />
                  </MDBCol>
                )
              })
            : null

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Executive News | Executive Real Estate</title>
                    <link rel="canonical" href="https://www.ere.nz/executive-news" />
                </Helmet>
                <div className="banner-blog-page page-header dark-filter clear-filter header-filter">
                    <div className="container">
                        <div style={{ height: '60vh' }} className="centered1">
                            <div style={{ maxWidth: '75%', marginLeft: 'auto', marginRight: 'auto', color: '#f4f4f4f4' }}>
                                <div className="title">
                                    <MDBAnimation reveal type="slideInDown" className="slow">
                                        <h1 className="heading-h1-lg heading-h1-sm"><b className="text-gold">Executive</b> News</h1>
                                    </MDBAnimation>
                                    <MDBAnimation reveal type="slideInUp" className="slower">
                                        <h3 className="heading-h3-lg heading-h3-sm">Hot off the press in property and development.</h3>
                                    </MDBAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <MDBContainer fluid id="blog" className="mb-5">
                        <MDBRow>
                            <MDBCol md="8">
                                <h2 className="ml-4 text-lg-left text-center white-text pt-lg-4 pt-0 pb-lg-0 pb-4 ml-lg-2">Latest news</h2>
                            </MDBCol>
                            <MDBCol md="4" className="text-center text-lg-right">
                                <SearchCategory selectedCategory={this.state.category} categorySelected={this.categorySelected} />
                            </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow className="pt-lg-4 mx-auto" style={{maxWidth: 1600}}>
                            {!this.state.data || this.state.data.length === 0 ? (
                                <div className="h3-responsive grey-text pt-5 pb-5 xode-text mx-auto">
                                    Hmm, we don't have any news under that category yet. We are working on some great content, it will be here soon.
                                </div>
                            ) : null
                            }
                            {cards}
                        </MDBRow>
                        <hr />
                    </MDBContainer>
                </div>
                <NewsletterRegisterContainer>
                    <NewsletterRegisterForm />
                </NewsletterRegisterContainer>
            </div >
        )
    }
}

export default BlogPage;