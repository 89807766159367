import React, { Component } from "react";
import { MDBCol, MDBRow, MDBIcon } from "mdbreact";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PremiumCarouselStyle.css';
import PremiumCarouselCard from "./PremiumCarouselCardComponent/PremiumCarouselCard";
import { IS_LOADING, LOADED, openHomeDateConverterMinimal } from '../../../Constants';
import { LISTING_STATUS_SOLD, LISTING_STATUS_CURRENT } from '../../WatchlistComponent/WatchlistPage';
// import { primaryColorBorder } from "../../../Constants";
import "./PremiumCarouselCardComponent/PremiumCarouselCard.css";
import WatchlistButton from '../../WatchlistComponent/WatchlistButton';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-WT6SLQB',
    dataLayer: {
        pageUrl:""
    }

}



export default class PremiumCarousel extends Component {

    constructor(props) {
        super(props);

        const sliderSettings = {
            infinite: true,
            speed: 1000,
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            focusOnSelect: false,
            autoplaySpeed: 8400,
            autoplay: false,
            pauseOnHover: true,
            // swipeToSlide: false,
            swipe: false,
            touchMove: false,
            beforeChange: (prev, next) => {
                this.setState({ currentSlide: next });
            },
            appendDots: dots => {
                return this.getCustomSliderDots(dots)
            }
        };

        this.state = {
            data: [],
            loading: IS_LOADING,
            error: false,
            isVertical: props.isVertical,
            sSettings: sliderSettings,
            currentSlide: 0,
            contentheight: 0,
            windowHeight: window.innerHeight
        };

        this.setCurrentSlide = this.setCurrentSlide.bind(this);
        window.addEventListener('resize', () => this.updateHeight());
    }

    componentDidMount() {
        TagManager.dataLayer(tagManagerArgs);
        this.updateHeight();
        this.props.authCtx.addSubscriber(this);
        this.reload();
    }

    componentWillUnmount() {
        this.props.authCtx.removeSubscriber(this);
        window.removeEventListener('resize', this.updateHeight);
    }

    getCustomSliderDots = (dots) => {
        const top = !this.state.isVertical ? (this.state.height - 35) : (this.state.height + 20);
        return (
            <div style={{ top: (top + 'px') }}>
                <ul style={{ padding: '0px' }}>
                    {dots}
                </ul>
            </div>
        )
    }

    reload = () => {
        this.props.requestor.fetch(this, this.props.skip, this.props.take);
    }

    fetchSuccess = (data) => {
        // console.log(data)
        this.setState({
            data: data,
            loading: LOADED
        });
    }

    fetchFailed = (error) => {
        this.setState({
            loading: LOADED,
            error: true
        });
    }

    updateHeight() {
        var isVertical = true;
        var nheight = 580;
        var cheight = 190;

        var diff = window.innerHeight * 0.3;
        var currDiff = Math.abs(window.innerHeight - this.state.windowHeight);
        if (currDiff >= diff) {
            this.setState({
                windowHeight: window.innerHeight
            })
        }

        if (window.innerWidth <= 400) {
            nheight = this.state.windowHeight - 315;
            cheight = 210;
        } else if (window.innerWidth <= 768) {
            nheight = this.state.windowHeight - 315;
            cheight = 220;
        } else {
            nheight = this.state.windowHeight - 325;
            cheight = 180;
        }

        // if (window.innerWidth < 377) {
        //     nheight = 350;
        //     cheight = 60;
        // } else if (window.innerWidth < 480) {
        //     nheight = 350;
        //     cheight = 85;
        // } else if (window.innerWidth < 600) {
        //     nheight = 400;
        //     cheight = 110;
        // } else if (window.innerWidth < 700) {
        //     nheight = 450;
        //     cheight = 220;
        // } else if (window.innerWidth < 800) {
        //     nheight = 500;
        //     cheight = 270;
        // } else if (window.innerWidth < 1090) {
        //     nheight = 550;
        //     cheight = 325;
        // }

        if (window.innerWidth < 1057) {
            isVertical = false;
        }

        this.setState({
            height: nheight,
            contentheight: cheight,
            sTwoIsVertical: isVertical,
            isVertical: isVertical
        });

        this.updateCarouselPadding();
    }

    

    updateCarouselPadding = () => {
        if (window.innerWidth <= 500) {
            this.setState({
                padding: 0
            })
        } else if (window.innerWidth <= 950) {
            this.setState({
                padding: 20
            })
        } else {
            this.setState({
                padding: 40
            })
        }
    }

    setCurrentSlide(index) {
        this.setState({
            selectedSlide: index
        });
    }

    formatLayout(image, info) {
        // if (this.state.isVertical === true) {
        // return this.alignVertically(image, info);
        // } else {
        return this.alignHorizontally(image, info);
        // }
    }

    alignVertically(image, info) {
        return (
            <MDBRow>
                <MDBCol sm="12" md="8" lg="8" className="image-col-h">
                    {image}
                </MDBCol>
                <MDBCol sm="12" md="4" lg="4" className="info-col-h">
                    {info}
                </MDBCol>
            </MDBRow>
        )
    }

    alignHorizontally(image, info) {
        return (
            <MDBRow>
                <MDBCol sm="12" md="12" lg="12" className="image-col-v">
                    {image}
                </MDBCol>
                <MDBCol sm="12" md="12" lg="12" className="info-col-v">
                    {info}
                </MDBCol>
            </MDBRow>
        )
    }

    buildSliderSlides(height) {
        if (this.state.data.length > 0) {
            return this.state.data.map((l, i) => {
                const bgImg = l.MainImage.url;
                const ribbonText = l.ribbon_text == null ? '' : l.ribbon_text.Name;
                const status = l.Display_as_Sold ? LISTING_STATUS_SOLD : LISTING_STATUS_CURRENT;
                const url = window.location.pathname + `?status=${status}&listing=${l.id}`;
                const watchlistBtn = (<WatchlistButton id={this.props.id} watchListed={this.props.watchListed} updateListing={this.props.updateListing} />);
                const openhomes = openHomeDateConverterMinimal(l.OpenHome);
                
             

                const imageEle = (
                    
                    <a href={url} onClick={(e) => {
                   
                        this.props.viewListing(e, l.id, status, true);  
                        

                    }}>
                        <div
                            className="main-slick-slide"
                            style={{
                                height: this.state.height + 'px',
                                backgroundImage: "url('" + bgImg + "')",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                position: "relative"
                            }}
                        >
                            {ribbonText !== '' && ribbonText.toLowerCase() !== 'none' ?
                                <div className={'ribbon ribbon-top-left'}>
                                    <span>{ribbonText}</span>
                                </div> : null}
                            {
                                openhomes && openhomes.length > 0 ?
                                    (
                                        <div className="text-left open-home-card pl-3"><MDBIcon icon="home" className="pb-1 white-text mr-lg-3 mr-2" />Next open home {openhomes[0]}</div>
                                    ) : null
                            }
                        </div>
                        {/* <div className={!this.state.isVertical ? "card-logo-pc-small" : "card-logo-pc"}>
                            {l.developer !== null ? <img src={l.developer.Logo.url} alt="developer logo" className={!this.state.isVertical ? "img-fluid dev-logo" : "img-fluid dev-logo-lg"} /> : null}
                        </div> */}
                    </a>
                );

                const infoEle = (
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <PremiumCarouselCard {...l} isVertical={false} height={this.state.contentheight} viewListing={this.props.viewListing} updateListing={this.updateListingData} />
                    </div>
                );

                return (
                    <div key={l.id}>
                        {this.formatLayout(imageEle, infoEle)}
                    </div>
                )
            });
        }
        return;
    }

    buildSlider(slides) {
        return (
            <>
                <Slider
                    {...this.state.sSettings}
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    prevArrow={<LeftNavButton padding={this.state.padding} />}
                    nextArrow={<RightNavButton padding={this.state.padding} />}
                >
                    {slides}
                </Slider>
            </>
        )
    }

    getImage = (image) => {
        if (image.formats && image.formats.medium && image.formats.medium.url) {
            return image.formats.medium.url;
        } else {
            return image.url;
        }
    }

    updateListingData = (watchlistItem, watchListed) => {
        if (watchlistItem && watchlistItem.listing) {
            let listings = this.state.data.slice();
            let index = listings.findIndex(l => l.id === watchlistItem.listing.id);
            if (index !== -1) {
                listings[index].watchListed = watchListed;
                this.setState({
                    data: listings
                });
                this.props.authCtx.updateSubscribers(this);
            }
        }
    }

    render() {
        if (!this.state.data || this.state.data.length === 0)
            return null;

        // build slider one
        const slides = this.buildSliderSlides(this.state.height);
        const slider = this.buildSlider(slides);
        const bgImg = require('../../../assets/img/blackmamba.png');

        const carousel = (
            <div className="pc-margin">
                <div id="premium-carousel-container" className="vertical-carousel-container bg-img">
                    {slider}
                </div>
            </div>
        );

        // return contructed carousel
        return carousel;
    }
}

function LeftNavButton(props) {
    return <button className="slick-prev" onClick={props.onClick} style={{ color: '#cda177', left: '-2px' }}><MDBIcon icon="caret-left" /></button>
}

function RightNavButton(props) {
    return <button className="slick-next" onClick={props.onClick} style={{ color: '#cda177', right: '-2px' }}><MDBIcon icon="caret-right" /></button>
}