// import ReactHtmlParser from 'react-html-parser';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdbreact'
import React from 'react'
import TagManager from 'react-gtm-module'
import { openHomeDateConverterMinimal } from '../../../../Constants'
import WatchlistButton from '../../../WatchlistComponent/WatchlistButton'
import { LISTING_STATUS_CURRENT, LISTING_STATUS_SOLD } from '../../../WatchlistComponent/WatchlistPage'
import './ListingCard.css'

const tagManagerArgs = {
  gtmId: 'GTM-WT6SLQB'
}

class ListingCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      displayVertical: props.isVertical
    }
    this.updateIsVertical = this.updateIsVertical.bind(this)
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs)
    this.updateIsVertical()
    window.addEventListener('resize', this.updateIsVertical)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsVertical)
  }

  updateIsVertical() {
    let dVert = false
    if (window.innerWidth < 992 || this.state.isVertical) {
      dVert = true
    }
    this.setState({ displayVertical: dVert })
  }

  getImage = (image) => {
    if (image.formats && image.formats.medium && image.formats.medium.url) {
      return image.formats.medium.url
    } else {
      return image.url
    }
  }

  getAskingPrice = () => {
    if (!this.state.askingPrice || this.state.askingPrice === 0 || this.state.askingPrice.startsWith('0')) return null

    if (this.state.saleType) {
      switch (this.state.saleType.PriceTypeCode) {
        case 'asking_price':
        case 'enquiries_over':
        case 'expressions_of_interest_over':
        case 'negotiable_from':
        case 'offers_over':
        case 'fixed_price':
        case 'buyer_budget_from':
        case 'buyer_budget_over':
        case 'buyer_interest_from':
        case 'buyer_enquiry_over':
          break
        default:
          return null
      }
    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(this.state.askingPrice)
  }

  render() {
    const ribbonText = this.state.ribbon_text == null ? '' : this.state.ribbon_text.Name
    const watchlistBtn = (
      <WatchlistButton id={this.props.id} watchListed={this.props.watchListed} updateListing={this.props.updateListing} />
    )
    const status = this.state.Display_as_Sold ? LISTING_STATUS_SOLD : LISTING_STATUS_CURRENT
    const url = window.location.pathname + `?status=${status}&listing=${this.props.id}`
    const openhomes = openHomeDateConverterMinimal(this.props.OpenHome)

    const completion =
      this.state && this.state.completionDate ? (
        <div className='lc-address-text mb-0 pb-0'>Completion {this.state.completionDate}</div>
      ) : null

    return (
      <div className={this.state.displayVertical ? 'listing-card-vertical' : 'listing-card-horizontal'}>
        {ribbonText && ribbonText !== '' && ribbonText.toLowerCase() !== 'none' ? (
          <div className={'ribbon ribbon-top-left' + (this.state.displayVertical ? '' : ' ribbon-horizontal')}>
            <span>{ribbonText}</span>
          </div>
        ) : null}

        <div className={this.state.displayVertical ? 'lc-vertical-container-img' : 'lc-horizontal-container-img'}>
          <a
            href={url}
            onClick={(e) => {
              this.props.viewListing(e, this.props.id, status, true)
            }}
            id='lat-img'
          >
            <div
              style={{
                height: '100%',
                backgroundImage: "url('" + this.getImage(this.state.MainImage) + "')",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
              }}
            >
              {openhomes && openhomes.length > 0 ? (
                <div className='text-left open-home-card pl-3'>
                  <MDBIcon icon='home' className='white-text mr-lg-3 mr-2 mb-1' />
                  Next open home {openhomes[0]}
                </div>
              ) : null}
              {/* <div className="card-logo">
                {this.state.developer !== null && this.state.developer.Logo.url ? <img src={this.state.developer.Logo.url} alt="developer logo" className="dev-logo" /> : null}
              </div> */}
            </div>
          </a>
        </div>
        <div className={this.state.displayVertical ? 'lc-vertical-container-body' : 'lc-horizontal-container-body'}>
          <div>
            <div className='lc-container'>
              <div className={this.state.isVertical ? 'lc-horizontal-container' : null}>
                <div className='lc-title'>{this.state.Title}</div>
              </div>
              <div className={this.state.isVertical ? 'lc-horizontal-container' : null}>
                <div className={this.state.displayVertical ? 'lc-body-home d-inline-block' : 'lc-body d-inline-block'}>
                  <div className='lc-address-text'>
                    {this.state.Address}, {this.state.suburb.Name}, {this.state.city.Name}
                  </div>
                  {this.state.developer !== null ? <div className='pc-saletype-text'>Developed by {this.state.developer.Name}</div> : null}
                  {/* {ReactHtmlParser(this.state.Blurb)} */}
                  {/* <hr className={this.state.isVertical ? "display-none" : "grey accent-2 mb-2 d-inline-block mx-auto"} style={{ width: "100%", opacity: "0.4" }} /> */}
                  <MDBRow>
                    <MDBCol sm='12'>
                      {this.state.Bedrooms > 0 ? (
                        // <MDBCol sm="12">
                        <span className='lc-ammenity'>
                          {this.state.Bedrooms} <MDBIcon className='lc-ammenity-icon mr-3' icon='bed' />
                        </span>
                      ) : (
                        /* </MDBCol> */
                        ''
                      )}
                      {this.state.Bathrooms > 0 ? (
                        // <MDBCol sm="6">
                        <span className='lc-ammenity'>
                          {this.state.Bathrooms} <MDBIcon className='lc-ammenity-icon mr-3' icon='bath' />
                        </span>
                      ) : (
                        // </MDBCol>
                        ''
                      )}
                      {this.state.Living > 0 ? (
                        // <MDBCol sm="6">
                        <span className='lc-ammenity'>
                          {this.state.Living} <MDBIcon className='lc-ammenity-icon mr-3' icon='couch' />
                        </span>
                      ) : (
                        // </MDBCol>
                        ''
                      )}
                      {this.state.Kitchen > 0 ? (
                        // <MDBCol sm="6">
                        <span className='lc-ammenity'>
                          {this.state.Kitchen} <MDBIcon className='lc-ammenity-icon mr-3' icon='utensils' />
                        </span>
                      ) : (
                        // </MDBCol>
                        ''
                      )}
                      {this.state.Office > 0 ? (
                        // <MDBCol sm="6">
                        <span className='lc-ammenity'>
                          {this.state.Office} <MDBIcon className='lc-ammenity-icon mr-3' icon='desktop' />
                        </span>
                      ) : (
                        // </MDBCol>
                        ''
                      )}
                      {this.state.Garage > 0 ? (
                        // <MDBCol sm="6">
                        <span className='lc-ammenity'>
                          {this.state.Garage} <MDBIcon className='lc-ammenity-icon mr-3' icon='car' />
                        </span>
                      ) : (
                        // </MDBCol>
                        ''
                      )}
                    </MDBCol>
                  </MDBRow>
                  <div className={this.state.displayVertical ? 'display-none' : 'lc-address-text pt-3 text-left'}>
                    {!this.getAskingPrice() ? (
                      this.state.saleType && this.state.saleType.Name ? (
                        <div className='lc-address-text'>{this.state.saleType.Name}</div>
                      ) : null
                    ) : (
                      <div className='lc-address-text'>
                        {this.state.saleType.Name} {this.getAskingPrice()}
                      </div>
                    )}
                    {completion}
                  </div>
                </div>
                <div className={!this.state.isVertical ? 'lc-ammenities-container pt-1' : 'lc-ammenities-container'}>
                  <MDBRow className={this.state.isVertical ? 'lc-btn-container' : ''}>
                    <MDBCol md='10' className='mx-auto'>
                      <MDBBtn
                        color='primary'
                        className='w-100'
                        href={url}
                        onClick={(e) => this.props.viewListing(e, this.props.id, status, true)}
                      >
                        View
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol md='2' className='mx-auto'>
                      {this.props.Display_as_Sold && !this.props.watchListed ? null : watchlistBtn}
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListingCard
