import {
  MDBCollapse, MDBDropdown,
  MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink
} from "mdbreact";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from '../../AuthContext/AuthContext';
import { LOGIN_PAGE, REGISTER_PAGE } from '../../AuthContext/AuthHandler';
import { PAGE_LISTINGS } from "../../Data/NavParams";
import './style.css';

const MAX_WIDTH = 1200;

class Navbar extends Component {

  authCtx = null;

  state = {
    isTop: true,
    isOpen: false,
    overrideNavTransparency: false,
    activeUrl: '/'
  };

  navTopStyle = {
    height: '90px',
    fontSize: '16px',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }

  navStyle = {
    height: '90px',
    backgroundColor: 'rgb(0,0,0)'
  }

  componentDidMount() {
    window.onscroll = () => {
      if (window.scrollY > 40) {
        this.setState({ isTop: false })
      } else {
        this.setState({ isTop: true })
      }
    }
  }

  toggleCollapse = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      // setTimeout(() => {
      //   this.setState({ overrideNavTransparency: false })
      // }, 650);
    } else {
      this.setState({ isOpen: true, overrideNavTransparency: true });
    }
  }

  toggleAuthModal = (show, page) => {
    if (window.innerWidth < MAX_WIDTH) {
      this.setState({ isOpen: false });
    }
    this.authCtx.toggleShow(show, page);
  }

  closeNav = () => {
    if (window.innerWidth < MAX_WIDTH && this.state.isOpen) {
      this.toggleCollapse();
    }
  }

  updateActiveUrl(url) {

    this.closeNav();

    this.setState({
      activeUrl: url
    });

    if (url.includes('#')) {
      let urlSplit = url.split('#');
      if (urlSplit.length > 1) {
      }
    }
  }

  search(search) {
    this.authCtx.setParams(PAGE_LISTINGS, {
      search: search
    })
  }

  logout = () => {
    if (window.innerWidth < MAX_WIDTH) {
      this.setState({ isOpen: false });
    }
    this.authCtx.logout();
  }

  render() {
    const navLogo = require('../../assets/logos/ere_logo_nav.png');
    const isDark = true;
    const isLight = false;
    const navClasses = (this.state.overrideNavTransparency && (!this.state.isTop || this.state.isOpen)) === true ? ' solid-bg-nav' : '';
    const currentPath = window.location.pathname;

    this.authCtx = this.context.auth;
    const user = this.authCtx.getUser();
    const clonedNavSearch = React.cloneElement(
      this.props.navSearch,
      { closeNav: this.closeNav }
    );

    return (
      <>
        <MDBNavbar style={this.state.isTop ? this.navTopStyle : this.navStyle}
          className={navClasses} fixed="top" dark={isDark} light={isLight} expand="xl" scrolling>
          <MDBNavbarBrand>
            <img src={navLogo} className="nav-logo" alt="Nav Logo" onClick={() => this.props.history.push('/')} />
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse2" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              {/* <MDBNavItem className="mr-lg-4 mr-0 ml-lg-2 ml-0">
                <div>{clonedNavSearch}</div>
              </MDBNavItem> */}
              {/* <MDBNavItem active={currentPath === '/'} onClick={() => this.updateActiveUrl('/')} className="ml-2">
                <MDBNavLink to="/">home</MDBNavLink>
              </MDBNavItem> */}
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span className="mr-2"><b className="ml-1 white-text">property for sale</b></span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem active={currentPath === '/listings'} onClick={() => this.updateActiveUrl('/listings')} className="mt-2 mb-2">
                    <MDBNavLink to="/listings" className="white-text">New Zealand listings</MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem active={currentPath === '/au/listings'} onClick={() => this.updateActiveUrl('/au/listings')} className="mt-2 mb-2">
                    <MDBNavLink to="/au/listings" className="white-text">Australian listings</MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem active={currentPath === '/sold'} onClick={() => this.updateActiveUrl('/sold')} className="mt-2 mb-2">
                    <MDBNavLink to="/sold" className="white-text">Sold listings</MDBNavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              {/* <MDBNavItem active={currentPath === '/comingsoon'} onClick={() => this.updateActiveUrl('/comingsoon')} className="ml-1">
                <MDBNavLink to="/comingsoon">Coming Soon</MDBNavLink>
              </MDBNavItem> */}
              <MDBNavItem active={currentPath === '/executive-news'} onClick={() => this.updateActiveUrl('/executive-news')} className="ml-1">
                <MDBNavLink to="/executive-news">news</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem active={currentPath === '/neighbourhoodguide'} onClick={() => this.updateActiveUrl('/neighbourhoodguide')} className="ml-1">
                <MDBNavLink to="/neighbourhoodguide">neighbourhood</MDBNavLink>
              </MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span className="mr-2"><b className="ml-1 white-text">about</b></span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem active={currentPath === '/our-story'} onClick={() => this.updateActiveUrl('/our-story')} className="mt-2 mb-2">
                    <MDBNavLink to="/our-story" className="white-text">our story</MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem active={currentPath === '/property-sales-team'} onClick={() => this.updateActiveUrl('/property-sales-team')} className="mt-2 mb-2">
                    <MDBNavLink to="/property-sales-team" className="white-text">sales team</MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem active={currentPath === '/careers'} onClick={() => this.updateActiveUrl('/careers')} className="mt-2 mb-2">
                    <MDBNavLink to="/careers" className="white-text">careers</MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem active={currentPath === '/partners'} onClick={() => this.updateActiveUrl('/partners')} className="mt-2 mb-2">
                    <MDBNavLink to="/partners" className="white-text">property developers</MDBNavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBNavItem active={currentPath === '/contactus'} onClick={() => this.updateActiveUrl('/contactus')} className="ml-1">
                <MDBNavLink to="/contactus">contact us</MDBNavLink>
              </MDBNavItem>
              {
                this.authCtx.state.loggedIn ?
                  <MDBNavItem active={currentPath === '/watchlist'} onClick={() => this.updateActiveUrl('/watchlist')} className="ml-1">
                    <MDBNavLink to="/watchlist"><b className="text-gold">my wishlist</b></MDBNavLink>
                  </MDBNavItem>
                  : null
              }
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/executive_realestate/" className="waves-effect waves-light" to="#!">
                  <MDBIcon fab className="white-text nav-icon" size="1x" icon="instagram" />
                </a>
              </MDBNavItem>
              <MDBNavItem>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/executive.real.estate1" className="waves-effect waves-light" to="#!">
                  <MDBIcon fab className="white-text nav-icon" size="1x" icon="facebook" />
                </a>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    {user ?
                      <div className="logged-in-user-text">{'Hello ' + user.username}</div>
                      : null
                    }
                    <MDBIcon className="pl-2" icon="user" />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="dropdown-default">
                    {
                      this.authCtx.state.loggedIn ?
                        <>
                          <MDBDropdownItem onClick={() => this.logout()}>
                            <div className="dropdown-link">Logout</div>
                          </MDBDropdownItem>
                          <MDBDropdownItem onClick={() => this.updateActiveUrl('/account')}>
                            <MDBNavLink className="dropdown-link" to="/account">My Account</MDBNavLink>
                          </MDBDropdownItem>
                          <MDBDropdownItem onClick={() => this.updateActiveUrl('/watchlist')}>
                            <MDBNavLink className="dropdown-link" to="/watchlist">My Wishlist</MDBNavLink>
                          </MDBDropdownItem>
                        </>
                        :
                        <>
                          <MDBDropdownItem onClick={() => this.toggleAuthModal(true, LOGIN_PAGE)}>
                            <div className="dropdown-link">Login</div>
                          </MDBDropdownItem>
                          <MDBDropdownItem onClick={() => this.toggleAuthModal(true, REGISTER_PAGE)}>
                            <div className="dropdown-link">Register</div>
                          </MDBDropdownItem>
                        </>
                    }
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </>
    );
  }
}
Navbar.contextType = AuthContext;
export default withRouter(Navbar);